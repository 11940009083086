import React, { Component } from "react";
import axios from "axios";
import config from "../config/config";

const headers = {
  "Content-Type": "application/json",
};
 
export default class Metadata extends Component {
        constructor(props) {
          super(props);
          this.state = {
            metadata: null,
            isLoading: true,
          };
        }
      
        componentDidMount() {
          // Access the 'id' from the URL params
          const { match } = this.props;
          const { id } = match.params;
          const { contract } = match.params;
      
          // Make an API call using the 'id'
          this.fetchMetadata(id,contract);
        }
      
        fetchMetadata = async (id,contract) => {
          try {
            let dataa ={"tokenId":id,
        "contract":contract}
            // Replace this with your actual API endpoint
            await axios({
                method: "post",
                url: `${config.apiUrl}metadataByTokenId`,
                data: dataa,
              }).then(async (response) => {
      console.log(response)
      if(response.data.success == true) {
        // alert('4')
        
        await axios({
          method: "post",
          url: `${config.ipfsUrl}/metadataByTokenId`,
          data: dataa,
        }).then(async (response) => {
          this.setState({
            metadata: response.data,
            isLoading: false,
          });
        })
           
          }
        })
          } catch (error) {
            console.error('Error fetching metadata:', error);
            this.setState({ isLoading: false });
          }
        };
      
        render() {
          const { metadata, isLoading } = this.state;
      
          if (isLoading) {
            return <div>Loading...</div>;
          }
      
          if (!metadata) {
            return <div>Error: Metadata not found</div>;
          }
      
          // Render your component using the fetched metadata
          return (
            <div>
              <h1>{metadata}</h1>
              {/* <p>{metadata.description}</p> */}
              {/* Add more JSX to display other metadata properties */}
            </div>
          );
        }
      }
      