import React, { Component , Fragment} from 'react';
 import axios from 'axios';
import config from '../config/config'
// import Header from './directives/header'
// import Footer from './directives/footer'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Header2 from '../directives/header2';
import InnerLeftSidebar from '../directives/innerLeftSidebar';
import Cookies from 'js-cookie'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import $ from 'jquery'; 
import ReactDatatable from '@ashvin27/react-datatable';

const headers = {
    'Content-Type': 'application/json'
 };

 export default class whishlist extends Component {


    constructor(props){
        super(props)
        this.state = {
            getWishList:[],
            falseMsg:''
        }
      this.loginData = (!Cookies.get('artfungibleUserloginSuccess'))?[]:JSON.parse(Cookies.get('artfungibleUserloginSuccess'))
      if(!this.loginData.data){
        window.location.href=`${config.baseUrl}`
     }

     this.columns = [
        {
            key: "image",
            text: "Product image",
            sortable: true,
            cell: record => { 
                return (
                    <Fragment>
                        {record.file_type == 'image'?
                            <img src={`${config.imageUrl}/${record.image}`} className="profile-img" alt="avatar"/>
                        :
                            <video src={`${config.imageUrl}/${record.image}`} className="profile-img" alt="avatar"/>
                        }                      
                    </Fragment>
                );
            }                
        },
        {
            key: "name",
            text: "Product Name",
            sortable: true
        },
        {
            key: "price",
            text: "Product Price",
            sortable: true,
            cell: record => { 
                return (
                    <Fragment>
                         {parseFloat(record?.price).toFixed(6)} ETH (${parseFloat(this.state.ethtousd * record?.price).toFixed(2)})
                                            
                    </Fragment>
                );
            }                
        },
        {
            key: "action",
            text: "Action",
            sortable: true,
            cell: record => { 
                return (
                    <Fragment>
                    <ul className="table-controls">
                        <li>
                            <a href="javascript:void(0);" onClick={this.wishListDelete.bind(this,record.wishlist_id)} className="bs-tooltip" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash p-1 br-6 mb-1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>             
                    </Fragment>
                );
            }                
        },                                
    ];

    this.config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false
        }
    }     

    }


    componentDidMount(){
        this.getEthToUsd()
        this.getWishListAPI()
    }

    async getEthToUsd() {
        await axios({
           method: 'get',
           url: `https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT`,
        }).then(response => {
           this.setState({
              ethtousd: response.data.price,
              usdtoeth: 1 / response.data.price
           })
        })
     }

    //====================================== Marketplace get  =============================
    
    async getWishListAPI() {
        await axios({
           method: 'post',
           url: `${config.apiUrl}/listWishlist`,
          //  headers: { "Authorization": this.loginData.message },
           data: {'user_id':this.loginData.data.id}
        }).then(response => {
           if (response.data.success === true) {
              this.setState({
                 getWishList: response.data?.data
              })
           }
        }).catch(err => {
          if (err.request) { } if (err.response) {
            this.setState({
              getWishList: [],
              falseMsg:err.response.data.msg
           })
              // toast.error(err.response.data.msg, {
              //     position: toast.POSITION.TOP_CENTER
              // });
          }
      });
     }

        //==========================================  Delete Post  ================================

   async wishListDelete(id) {

    confirmAlert({
       title: 'Confirm to submit',
       message: 'Are you sure to delete this.',
       buttons: [
          {
             label: 'Yes',
             onClick: () =>
                axios({
                   method: 'post',
                   url: `${config.apiUrl}/removeWishlist`,
                   headers: { "Authorization": this.loginData.message },
                   data: { 'wishlist_id': id,'user_id':this.loginData.data?.id }
                }).then((res) => {
                   this.getWishListAPI()
                   toast.success(res.data.msg, {
                      position: toast.POSITION.TOP_CENTER
                   });
                }).catch((error) => {
                })
          },
          {
             label: 'No',
          }
       ]
    });

 }

 removeCls(){
    $('.main-container').removeClass('sbar-open');
 }

    render() {
        // const { first_name,last_name,birth,contact_number} = this.state;
        return (
             <>
                <div className="header-container fixed-top" onClick={this.removeCls.bind(this, 1)}>
                    <Header2/>
         </div>
    {/* <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  --> */}
    {/* remove class --sbar-open-- */}
    <div className="main-container sidebar-closed whishlist" id="container" onClick={this.removeCls.bind(this, 1)}>

        <div className="overlay"></div>
        <div className="cs-overlay"></div>
        <div className="search-overlay"></div>
        <InnerLeftSidebar/>
        {/* <!--  END SIDEBAR  -->

        <!--  BEGIN CONTENT AREA  --> */}
        <div id="content" className="main-content">
            <div className="layout-px-spacing">
            <ToastContainer />
                

                <div className="row layout-spacing">

                    {/* <!-- Content --> */}
                    

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
                       <div className="page-header">
                    <div className="page-title">
                        <h3>WishList</h3>
                    </div>
                </div>
                        <div className="layout-spacing">
                     <div className="statbox widget box box-shadow">
                            <div className="widget-content widget-content-area">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.getWishList}
                                    columns={this.columns}
                                    onSort={this.onSort} /> 
                            </div>
                        </div>
                  </div>

                  </div>  

                    </div>

                </div>
            </div>
        </div>
        {/* <!--  END CONTENT AREA  --> */}

    






             </>
)
}
 }