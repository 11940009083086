import React, { Component } from "react";
import Header from "../directives/header1";
import Footer1 from "../directives/footer1";
import config from "../config/config";
import axios from "axios";

export default class whyArtFungible extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dynamicData: [],
    };
  }

  componentDidMount() {
    this.getWhyArtFungibleAPI();
  }

  async getWhyArtFungibleAPI() {
    await axios({
      method: "get",
      url: `${config.apiUrl}getWhyArtFungible`,
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          dynamicData: response.data.response,
        });
      }
    });
  }

  render() {
    return (
      <div>
        <Header />

        <section className="page mt-5">
          <div className="container">
            <div className="columns">
              <div className="column is-12">
                <div className="content">
                  <h1>Why Art Fungible ?</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.dynamicData[0]?.why_art_fungible,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer1 />
      </div>
    );
  }
}
