import React, { Component, Fragment } from 'react';
import InnerHeader from '../directives/header1'
import Footer1 from '../directives/footer1'
import axios from 'axios';
import config from '../config/config'
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Web3 from 'web3';
import Swal from 'sweetalert2'
import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon, EmailIcon, EmailShareButton } from 'react-share';
import InstagramEmbed from 'react-instagram-embed';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import ReactDatatable from '@ashvin27/react-datatable';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player'
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment'
import { getCurrentProvider } from '../components/provider'
import { Dialog, Classes } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
import BarLoader from 'react-bar-loader'
import { Player } from 'video-react';
import ContentShimmer from 'react-content-shimmer'
import { CodeShimmer } from 'react-content-shimmer'
import ModalImage from "react-modal-image";
import LightboxSlider from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const MAX_LENGTH = 80;

export default class marketplaceDetail extends Component {

   constructor(props) {
      super(props)
      const { match: { params } } = this.props;
      this.id = params.id
      this.state = {
         getDetailMarket: '',
         getDetailMarketMutiple: [],
         ConnectWalletAddress: '',
         buyBtnText: 'Buy',
         placeBidBtnText: 'Place Bid',
         minimumBidErr: 1,
         userbidAmount: 0,
         getProductsbidsData: [],
         checkbox: 0,
         timerStart: false,
         errorMsg: 0,
         getPreviewBidData: '',
         isDialogOpen: false,
         isResponse: false,
         auctionEnded: false,
         isOpen1: false,
         photoIndex: 0,
         finalArray: []

      }
      this.loginData = (!Cookies.get('artfungibleUserloginSuccess')) ? [] : JSON.parse(Cookies.get('artfungibleUserloginSuccess'))
      this.buyItemAPI = this.buyItemAPI.bind(this);
      this.onChange = this.onChange.bind(this);
      this.handleChange1 = this.handleChange1.bind(this);
      this.handleChange2 = this.handleChange2.bind(this);
      Cookies.remove('productDetailsId');

      this.columns = [
         // {
         //    key: "image",
         //    text: "Product image",
         //    sortable: true,
         //    cell: record => {
         //       return (
         //          <Fragment>
         //             <a target="_blank" href={`${config.imageUrl + record.image}`}>
         //                {record.file_type == 'image' ?
         //                   <img src={`${config.imageUrl}/${record.image}`} className="product-img" alt="avatar" />
         //                   :
         //                   <img src="img/videologo.png" className="product-img" alt="avatar" />
         //                }
         //             </a>
         //          </Fragment>
         //       );
         //    }
         // },
         {
            key: "nft_activity_type",
            text: "Event",
            cell: record => {
               return (
                  <Fragment>
                     {record.nft_activity_type}
                  </Fragment>
               );
            }
         },
         {
            key: "amount",
            text: "Price",
            sortable: true,
            cell: record => {
               return (
                  <Fragment>
                     {record.amount} ETH
                     {/* {parseFloat(this.state.UsdtoEth * record.price).toFixed(6)} ETH (${record.price}) */}
                  </Fragment>
               );
            }
         },
         {
            key: "from_name",
            text: "From",
            cell: record => {
               return (
                  <Fragment>
                     {record.from_user === 0 ? 'Admin'
                        : record.nft_activity_type_id === 4 ? record.from_name
                           : record.to_name}
                  </Fragment>
               );
            }
         },
         {
            key: "to_name",
            text: "To",
            cell: record => {
               return (
                  <Fragment>
                     {record.to_user === 0 && record.from_user === 0 ? '-' :
                        record.from_user === 0 && record.nft_activity_type_id !== 4 ? 'Admin'
                           : record.nft_activity_type_id === 4 ? record.to_name : record.from_name}
                  </Fragment>
               );
            }
         },
         {
            key: "datetime",
            text: "Date",
            cell: record => {
               return (
                  <Fragment>
                     {record.datetime}
                  </Fragment>
               );
            }
         },

      ];

      this.config = {
         page_size: 10,
         length_menu: [10, 20, 50],
         show_filter: true,
         show_pagination: true,
         pagination: 'advance',
         button: {
            excel: false,
            print: false
         }
      }
      setInterval(() => {
         if (this.state.getDetailMarket.sell_type == 2 && ((new Date(this.state.getDetailMarket.expiry_date) < new Date()) == true) && !this.state.auctionEnded) {
            // this.detailMarketPlaceAPI()
            this.setState({
               auctionEnded: true
            })
            return false
         }

      }, 1000);
   }

   componentDidMount() {
      this.detailMarketPlaceAPI()
      this.getAdminWalletDetails()
      this.getEthToUsd()
      this.getProductsbids()
   }

   onChange(e) {
      var maxBid = parseFloat(this.state.getDetailMarket?.maxbid ? this.state.getDetailMarket?.maxbid : this.state.getDetailMarket?.price)

      if (parseFloat(e.target.value).toFixed(6) < parseFloat(maxBid).toFixed(6)) {
         this.setState({
            'minimumBidErr': 1
         })

      } else {
         this.setState({
            'minimumBidErr': 0
         })
      }

      this.setState({
         [e.target.name]: e.target.value
      })
   }

   handleChange1() {
      let check = this.state.checkbox;
      if (check == 0) {
         this.setState({
            checkbox: 1,
            errorMsg: 0
         })
      }
      else {
         this.setState({
            checkbox: 0,
            errorMsg: 1
         })
      }
   };


   handleChange2() {
      let check = this.state.checkbox;
      if (check == 0) {
         this.setState({
            errorMsg: 1
         })
      }
      else {
         this.setState({
            errorMsg: 0
         })
      }
   };

   getTimeOfStartDate(dateTime) {
      var date = new Date(dateTime); // some mock date
      var milliseconds = date.getTime();
      return milliseconds;
   }

   CountdownTimer({ days, hours, minutes, seconds, completed }) {
      if (completed) {
         // Render a completed state
         return "Starting";
      } else {
         // Render a countdowns
         var dayPrint = (days > 0) ? days + 'd' : '';
         return <span>{dayPrint} {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s</span>;
      }
   };

   CountdownTimer1({ days, hours, minutes, seconds, completed }) {
      if (completed) {
         // Render a completed state
         return "Starting";
      } else {
         // Render a countdowns
         var dayPrint = (days < 0) ? days + 'd' : '';
         return <span>{dayPrint} {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s</span>;
      }
   };



   //====================================== Marketplace detail  =============================

   async detailMarketPlaceAPI() {
      this.setState({
         isResponse: true
      })
      await axios({
         method: 'post',
         url: `${config.apiUrl}/listSingleItem`,
         //  headers: { "Authorization": this.loginData.message },
         data: { "item_id": this.id, "user_id": this.loginData.data?.id }
      }).then(response => {
         if (response.data.success === true) {
            let time = response.data.response.expiry_date;
            time = new Date(time);
            time = time.getTime();
            let cc = new Date();
            cc = cc.getTime();
            var diff = Math.round(parseInt(time) / 1000) - (parseInt(cc) / 1000);

            if (diff <= (24 * 3600)) {
               this.setState({
                  timerStart: true
               })
            }
            this.setState({
               getDetailMarket: response.data?.response,
               getDetailMarketMutiple: JSON.parse(response.data?.response?.multiimage),
               isResponse: false

            })
            for (let i = 0; i < this.state.getDetailMarketMutiple.length; i++) {
               const element = 'https://artfungible.mypinata.cloud/ipfs/' + this.state.getDetailMarketMutiple[i].name;
               this.state.getDetailMarketMutiple[i].name = element
            }

            var finalArray = this.state.getDetailMarketMutiple.map(function (obj) {
               return obj.name;
            });

            this.setState({
               finalArray: finalArray
            })

         }
      })
   }

   async addToWishList(id) {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/addWishlist`,
         //  headers: { "Authorization": this.loginData.message },
         data: { "item_id": this.id, "user_id": this.loginData.data?.id }
      }).then(response => {
         if (response.data.success === true) {

            toast.success(response.data.msg, {
               position: toast.POSITION.TOP_CENTER
            });
         }
         this.detailMarketPlaceAPI()
      })
   }

   async connectMetasmask() {

      if (window.ethereum) {

         var isMetaMask = await window.ethereum.isMetaMask;
         if (!isMetaMask) {
            toast.error(`Please install Metamask wallet to use this App!`);
            return;
         }
         const providers = await getCurrentProvider();
         const web3 = new Web3(providers);
         const accounts = await providers.request({ method: 'eth_requestAccounts' });
         toast.success(`MetaMask connected Successfully!`, {
            position: toast.POSITION.TOP_CENTER
         });
         this.setState({
            ConnectWalletAddress: accounts
         })
         localStorage.setItem('ConnectWalletAddress', accounts)

      }
      else {
         Swal.fire({
            icon: 'error',
            title: 'Oops',
            text: 'Please install MetaMask to use this dApp!'
         })
      }

   }








   // async buyItem(item) {

   //    if (window.ethereum) {

   //       var isMetaMask = await window.ethereum.isMetaMask;
   //       if (!isMetaMask) {
   //          toast.error(`Please install Metamask wallet to use this App!`);
   //          return;
   //       }
   //       const providers = await getCurrentProvider();
   //       const web3 = new Web3(providers);
   //       const accounts = await providers.request({ method: 'eth_requestAccounts' });

   //       this.setState({
   //          ConnectWalletAddress: accounts
   //       })
   //       localStorage.setItem('ConnectWalletAddress', accounts)

   //    }

   //    var sell_type = item.sell_type
   //    if (sell_type == 1) {
   //       var btnText = "Buy"
   //       var itemPrice = item.price;
   //    } else {
   //       var btnText = "Place Bid"
   //       var itemPrice = this.state.bidAmount;
   //    }

   //    this.setState({
   //       buyBtnText: 'Processing....',
   //       placeBidBtnText: 'Processing....',

   //    })
   //    if (window.ethereum) {

   //       const providers = await getCurrentProvider();
   //       const web3 = new Web3(providers);

   //       var currentNetwork = web3.currentProvider.chainId;

   //       if (currentNetwork == '0x1') {

   //       }

   //       if (currentNetwork !== '0x1') {

   //          Swal.fire({
   //             icon: 'error',
   //             title: 'Oops',
   //             text: 'Please Select Ethereum Network'
   //          })
   //          this.setState({
   //             buyBtnText: btnText,
   //             placeBidBtnText: btnText
   //          })
   //          return false;
   //       }
   //       var chainId = '0x1';


   //       let livePriceAPI = await axios({ url: 'https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH' });
   //       var usdInEth = livePriceAPI.data.ETH
   //       var usdvalue = (parseFloat(usdInEth) * parseFloat(itemPrice)).toFixed(6);
   //       try {

   //          this.setState({
   //             isDialogOpen: true
   //          })

   //          var trx_amount = usdvalue * (10 ** 18);
   //          var to_address = config.contractAdd;
   //          var from_address = await localStorage.getItem('ConnectWalletAddress');
   //          var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);
   //          var currentBal = parseFloat(getBalace).toFixed(6)

   //          if (currentBal < usdvalue) {
   //             Swal.fire({
   //                icon: 'error',
   //                title: 'Oops',
   //                text: 'insufficient funds for this transaction!'
   //             })

   //             this.setState({
   //                buyBtnText: btnText,
   //                placeBidBtnText: btnText
   //             })
   //             return false;
   //          }
   //          const contractAddress = to_address
   //          web3.eth.defaultAccount = from_address;
   //          const contract = await new web3.eth.Contract(config.abi, contractAddress);
   //          var tx_builder = ''
   //          if (item.sell_type == 1) {
   //             tx_builder = await contract.methods.ItemPurchase(item.token_id);
   //          } else {
   //             tx_builder = await contract.methods.placeBid(item.token_id);
   //          }

   //          let encoded_tx = tx_builder.encodeABI();

   //          let gasPrice = await web3.eth.getGasPrice();

   //          gasPrice = parseInt(gasPrice) + 200000000;


   //          let gasLimit = await web3.eth.estimateGas({
   //             gasPrice: web3.utils.toHex(gasPrice),
   //             to: to_address,
   //             from: from_address,
   //             value: web3.utils.toHex(parseInt(trx_amount)),
   //             chainId: chainId,
   //             data: encoded_tx
   //          });

   //          const txData = await web3.eth.sendTransaction({
   //             gasPrice: web3.utils.toHex(gasPrice),
   //             gas: web3.utils.toHex(gasLimit),
   //             to: to_address,
   //             from: from_address,
   //             value: web3.utils.toHex(parseInt(trx_amount)),
   //             chainId: chainId,
   //             data: encoded_tx
   //          });

   //          item.from_address = from_address;
   //          item.trx_amount = usdvalue;
   //          item.trx_currency = 'ETH';
   //          item.txHash = txData.transactionHash;
   //          item.etherPrice = usdvalue;
   //          item.usdPrice = itemPrice

   //          this.buyItemAPI(item);

   //       } catch (error) {
   //          console.log(error)
   //          Swal.fire({
   //             icon: 'error',
   //             title: 'Oops',

   //          })
   //          this.setState({
   //             buyBtnText: btnText,
   //             isDialogOpen: false,
   //             placeBidBtnText: btnText
   //          })
   //          return false;
   //       }

   //    } else {

   //       Swal.fire({
   //          icon: 'error',
   //          title: 'Oops',
   //          text: 'Please Connect to MetaMask.'
   //       })
   //       this.setState({
   //          buyBtnText: btnText,
   //          isDialogOpen: false,
   //          placeBidBtnText: btnText
   //       })
   //       return false;
   //    }

   // }



   async buyItem() {

      if (window.ethereum) {

         let tokenId = this.state.getDetailMarket.token_id;
         let tokenPrice = this.state.getDetailMarket.sell_type == 1 ? this.state.getDetailMarket.price : this.state.bidAmount;
         let coin = this.state.getDetailMarket.blockchainType;

         if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            localStorage.setItem('ConnectWalletAddress', accounts[0])
            let web3 = new Web3(window.ethereum);
            let currentNetwork = web3.currentProvider.chainId;
            // let eth_mainnet = 0x1;
            // let eth_testnet = 0x4;

            // let matic_mainnet = 137;
            // let matic_testnet = 80001;

            if (currentNetwork !== '0x1') {
               toast.error('Please Select Ethereum Network');
               this.setState({
                  spinLoader: '0',
                  isDialogOpen: false
               })
               return false;
            }
            try {
               tokenPrice = parseInt((parseFloat(tokenPrice)) * 10 ** 18);
               let from_address = accounts[0];
               var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);
               var currentBal = parseFloat(getBalace).toFixed(6)
               if (currentBal < this.state.getDetailMarket.price) {
                  toast.error(`Insufficient fund for transfer`);
                  this.setState({
                     spinLoader: '0',
                     isDialogOpen: false
                  })
                  return false;
               }

               this.setState({
                  spinLoader: 1,
                  isDialogOpen: true
               })
               await this.trnasferNFT(tokenId, coin, tokenPrice);
            } catch (error) {
               toast.error('Something went wrong please try again2.');
               this.setState({
                  spinLoader: 0,
                  isDialogOpen: false
               })
               return false;
            }
         } else {
            toast.error('Please Connect to MetaMask.');
            this.setState({
               spinLoader: '0',
               isDialogOpen: false
            })
            return false;
         }
      } else {
         toast.error('Please Connect to MetaMask.');
         this.setState({
            spinLoader: '0',
            isDialogOpen: false
         })
         return false;
      }
   }

   async trnasferNFT(tokenId, coin, tokenPrice) {
      if (window.ethereum) {
         const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
         let web3 = new Web3(window.ethereum);
         var chainId = web3.currentProvider.chainId;
         try {

            let contractAddress = `${config.marketplaceContract}`
            let from_address = accounts[0];

            const contract = await new web3.eth.Contract(config.abiMarketplace, contractAddress);
            let tx_builder = '';
            let cryptoAmount = tokenPrice;
            let itemPrice = 0;

            itemPrice = tokenPrice / 10 ** 18;
            if (this.state.getDetailMarket.sell_type == 1) {
               tx_builder = await contract.methods.ItemPurchase(tokenId.toString());
            } else {
               tx_builder = await contract.methods.placeBid(tokenId.toString());
            }

            let encoded_tx = tx_builder.encodeABI();
            let gasPrice = await web3.eth.getGasPrice();
            gasPrice = await parseInt(gasPrice) + parseInt(10000000000);
            let gasLimit = await web3.eth.estimateGas({
               gasPrice: web3.utils.toHex(gasPrice),
               to: contractAddress,
               from: from_address,
               chainId: chainId,
               value: web3.utils.toHex(cryptoAmount),
               data: encoded_tx
            });

            const txData = await web3.currentProvider.request({
               method: 'eth_sendTransaction',
               params: [{
                  gasPrice: web3.utils.toHex(gasPrice),
                  gas: web3.utils.toHex(gasLimit),
                  to: contractAddress,
                  from: from_address,
                  chainId: chainId,
                  value: web3.utils.toHex(cryptoAmount),
                  data: encoded_tx
               }],
            });

            // const txData = await web3.eth.sendTransaction({
            //    gasPrice: web3.utils.toHex(gasPrice),
            //    gas: web3.utils.toHex(gasLimit),
            //    to: contractAddress,
            //    from: from_address,
            //    chainId: chainId,
            //    value: web3.utils.toHex(cryptoAmount),
            //    data: encoded_tx
            // });

            if (txData) {
               var paymentArr = {
                  txHash: txData,
                  itemPrice: itemPrice,
                  from_address: contractAddress,
                  to_address: from_address,
                  paymentType: coin
               }

               this.buyItemAPI(paymentArr)
            } else {
               toast.error('Something went wrong please try again3.');
               this.setState({
                  spinLoader: '0',
                  isDialogOpen: false
               })
               return false;
            }

         } catch (err) {
            if (err.message.toString().split('insufficient funds')[1]) {
               toast.error('Transaction reverted : ' + err.message)
            } else {
               if (err.toString().split('execution reverted:')[1]) {
                  toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
               } else {
                  toast.error(err.message);
               }
            }
            this.setState({
               spinLoader: '0',
               isDialogOpen: false
            })
            return false;
         }

      } else {
         toast.error('Please Connect to MetaMask.');
         this.setState({
            spinLoader: '0',
            isDialogOpen: false
         })
         return false;
      }
   }


   async pleaselogin() {
      await Swal.fire({
         icon: 'error',
         title: 'Oops',
         text: "Please Login to your Account"
      })
      Cookies.set('productDetailsId', this.id);
      window.location.href = `${config.baseUrl}` + "login"
   }


   async buyItemAPI(item) {
      if (item.sell_type == 1) {
         Cookies.set('sell_type', "price");
      } else {
         Cookies.set('sell_type', "auction");
      }

      await axios({
         method: 'post',
         url: `${config.apiUrl}/itemBuyPending`,
         data: {
            "item_id": this.state.getDetailMarket.id,
            "sell_type": this.state.getDetailMarket.sell_type,
            "user_id": this.loginData.data?.id,
            "owner_id": this.state.getDetailMarket.owner_id,
            "user_name": this.loginData.data?.first_name + ' ' + this.loginData.data?.last_name,
            "price": this.state.getDetailMarket.sell_type == 1 ? this.state.getDetailMarket.price : this.state.bidAmount,
            "cryptoPrice": this.state.getDetailMarket.sell_type == 1 ? this.state.getDetailMarket.price : this.state.bidAmount,
            "buyerAddress": item.to_address,
            "tokenId": this.state.getDetailMarket.token_id,
            "trx_amount": this.state.getDetailMarket.sell_type == 1 ? this.state.getDetailMarket.price : this.state.bidAmount,
            "trx_currency": this.state.getDetailMarket.trx_currency,
            "trx_hash": item.txHash,
            "itemName": this.state.getDetailMarket.name,
            "itemimage": this.state.getDetailMarket.image,
            "trx_type": "Crypto"
         }
      }).then(async response => {
         this.setState({
            isDialogOpen: false,
         })
         if (response.data.success === true) {

            await Swal.fire({
               icon: 'success',
               title: 'Congratulations',
               text: response.data.msg
            })

            window.location.href = `${config.baseUrl}` + "transaction"

         } else {
            Swal.fire({
               icon: 'error',
               title: 'Oops',
               text: "Please Connect to MetaMask."
            })
         }
         this.detailMarketPlaceAPI();
         this.setState({
            buyBtnText: 'buy',
            isDialogOpen: false,

         })
      }).catch(err => {

         Swal.fire({
            icon: 'error',
            title: 'Oops',
            text: "Please Connect to MetaMask."
         })
         this.setState({
            buyBtnText: 'buy',
            isDialogOpen: false,

         })
      });
   }

   async getAdminWalletDetails() {
      await axios({
         method: 'get',
         url: `${config.apiUrl}/getWalletDetails`,
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               AdminWallet: response.data?.response
            })

         }
      })
   }

   async getEthToUsd() {
      await axios({
         method: 'get',
         url: `https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT`,
      }).then(response => {
         this.setState({
            ethtousd: response.data.price,
            usdtoeth: 1 / response.data.price
         })
      })
   }


   async getProductsbids() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/getProductsbidsAPI`,
         data: { "item_id": this.id }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               getProductsbidsData: response.data?.data
            })
         }
      })
   }

   // async getPreviewBidAPI() {
   //    await axios({
   //       method: 'post',
   //       url: `${config.apiUrl}/getPreviewBid`,
   //       data: { "item_id": this.id,"user_id": this.loginData.data?.id }
   //    }).then(response => {
   //       if (response.data.success === true) {
   //          this.setState({
   //             getPreviewBidData: response.data?.data
   //          })
   //       }
   //    })
   // }



   async redirectlogin() {
      Cookies.set('productDetailsId', this.id);
      window.location.href = `${config.baseUrl}login`
   }


   multiImageOnModal() {
      this.setState({
         isOpen1: true
      })
   }

   render() {
      const { photoIndex, isOpen1 } = this.state;

      return (

         <>
            <div className="body_bg">
               <InnerHeader />
               {this.state.isOpen1 && (
                  <LightboxSlider
                     mainSrc={this.state.finalArray[this.state.photoIndex]}
                     nextSrc={this.state.finalArray[(this.state.photoIndex + 1) % this.state.finalArray.length]}
                     prevSrc={this.state.finalArray[(this.state.photoIndex + this.state.finalArray.length - 1) % this.state.finalArray.length]}
                     onCloseRequest={() => this.setState({ isOpen1: false })}
                     onMovePrevRequest={() =>
                        this.setState({
                           photoIndex: (this.state.photoIndex + this.state.finalArray.length - 1) % this.state.finalArray.length,
                        })
                     }
                     onMoveNextRequest={() =>
                        this.setState({
                           photoIndex: (this.state.photoIndex + 1) % this.state.finalArray.length,
                        })
                     }
                  />
               )}
               <section className="product_section">
                  <br />
                  <div className="item-details-container">
                     <ToastContainer />

                     <div className="container">
                        <div className="item-details-content">

                           <div className="row" style={{ alignItems: 'unset' }}>
                              {this.state.isResponse == true ?
                                 <div className='col-sm-12 mb-3'>
                                    <CodeShimmer style={{ marginTop: "1rem", background: 'rgb(241 241 241)' }} rounded={"20px"} size={{ height: 550, width: 500 }} />
                                 </div>
                                 : <>
                                    <div className=" col-md-10 col-xl-4">
                                       <div className="item-dp">
                                          <div className="cardRarity rare">
                                             <div className="rarityNew" title="Rare"></div>
                                             <span className="color" title="Rarity: Color">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="50" viewBox="0 0 8 50">
                                                   <path id="Path_315" data-name="Path 315" d="M0,0,8-10V40L0,30Z" transform="translate(0 10)" fill="#d9d9d9"></path>
                                                </svg>
                                             </span>
                                          </div>
                                          {this.state.getDetailMarketMutiple.length === 1 ?

                                             <a className="img-holder" title="View">
                                                {this.state.getDetailMarket?.file_type == 'image' ?
                                                   <ModalImage
                                                      small={`${config.imageUrl}${this.state.getDetailMarket?.image}`}
                                                      large={`${config.imageUrl}${this.state.getDetailMarket?.image}`}
                                                      medium={`${config.imageUrl}${this.state.getDetailMarket?.image}`}
                                                      width="100%" className='nftdetailimage'
                                                      showRotate
                                                      imageBackgroundColor
                                                   />


                                                   :
                                                   <Player className="" src={`${config.imageUrl}${this.state.getDetailMarket?.image}`} />

                                                }
                                             </a> :

                                             <Carousel autoPlay="true">
                                              
                                                   {this.state.getDetailMarketMutiple.map(item => (
                                                        <div style={{ cursor: 'pointer' }} onClick={this.multiImageOnModal.bind(this)}>
                                                      {item.type === 'image' ?
                                                         <img style={{ 'height': "387px" }} className="MuiCardMedia-root jss400 MuiCardMedia-media" src={`${item.name}`} alt="" />

                                                         :
                                                         item.type === 'video' ?
                                                            <ReactPlayer
                                                               url={`${item.name}`}
                                                               volume='1'
                                                               muted
                                                               width='100%'
                                                               playing={true}
                                                            />
                                                            : ''}
                                                </div>
                                                   ))}
                                             </Carousel>

                                          }


                                       </div>
                                       <div className="links">
                                          <button className="custom-btn light lg addWishlist" type="submit" >
                                             {this.state.getDetailMarket?.is_wishlist === '0' ?
                                                <span className="txt" onClick={this.addToWishList.bind(this, this.id)}>Add to wishlist</span>
                                                :
                                                <span className="txt" >Already in Wishlist</span>


                                             }
                                          </button>


                                          <div className="itemMintedInfo num">#<span>3</span> of <span>100</span> Minted Items</div>
                                          <a href="#detail" className="link" data-toggle="modal" data-target="#tradingHistoryModal">View Trading History</a>

                                          <a href="#" rel="noopener noreferrer" target="_blank" className="-blockchainView link" style={{ marginTop: "10px" }}><span className="txt">Blockchain View</span></a>

                                       </div>
                                    </div>
                                    <div className="col-md-8 col-xl-8 col-lg-7 item-info">
                                       <div className="top">
                                          <div className="row">
                                             <div className="col-sm-6"> <h1 className=" name">{this.state.getDetailMarket?.name}</h1></div>

                                             {this.state.getDetailMarket.sell_type === 1 || this.state.getDetailMarket.start_date === null ? '' :
                                                new Date(this.state.getDetailMarket.start_date) > new Date() ?
                                                   <>
                                                      <div className="market-label">
                                                         <i className="fa fa-clock" aria-hidden="true" />&nbsp;&nbsp;Sale starts after

                                                         {(this.state.getDetailMarket.start_date && this.state.timerStart) ?
                                                            <Countdown
                                                               date={this.getTimeOfStartDate(this.state.getDetailMarket.start_date)}
                                                               renderer={this.CountdownTimer1}
                                                            />
                                                            :
                                                            <>
                                                               <span className="days" style={{ paddingLeft: '5px' }}>{moment(this.state.getDetailMarket.start_date).diff(moment(), 'days')} day </span>
                                                            </>
                                                         }&nbsp;
                                                      </div>
                                                   </>
                                                   :
                                                   <div className="col-sm-6">
                                                      {this.state.getDetailMarket.sell_type === 1 || this.state.getDetailMarket.expiry_date === null ? '' :
                                                         this.state.getDetailMarket.sell_type == 2 && (this.state.getDetailMarket.expiry_date != null || this.state.getDetailMarket.expiry_date != '' || this.state.getDetailMarket.expiry_date != undefined || this.state.getDetailMarket.expiry_date != '0000-00-00 00:00:00') && ((new Date(this.state.getDetailMarket.expiry_date) < new Date())) == false ?
                                                            <>
                                                               {this.state.getDetailMarket.is_on_sale === 0 ? '' :
                                                                  this.state.getDetailMarket.sell_type === 1 || this.state.getDetailMarket.expiry_date === null ? '' :

                                                                     <div className="market-label">
                                                                        <i className="fa fa-clock" aria-hidden="true" />&nbsp;&nbsp;Sale ends in &nbsp;
                                                                        {(this.state.getDetailMarket.expiry_date && this.state.timerStart) ?
                                                                           <Countdown
                                                                              date={this.getTimeOfStartDate(this.state.getDetailMarket.expiry_date)}
                                                                              renderer={this.CountdownTimer}
                                                                           /> :
                                                                           <>
                                                                              {/* <span className="days" style={{ paddingLeft: '5px' }}>{moment(this.state.getDetailMarket.expiry_date).diff(moment(), 'days')} day </span> */}
                                                                              <Countdown
                                                                                 date={this.getTimeOfStartDate(this.state.getDetailMarket.expiry_date)}
                                                                                 renderer={this.CountdownTimer}
                                                                              />
                                                                           </>
                                                                        }&nbsp;
                                                                     </div>}
                                                            </>
                                                            :
                                                            this.state.auctionEnded || this.state.getDetailMarket.sell_type == 2 && ((new Date(this.state.getDetailMarket.expiry_date) < new Date()) == true) ?
                                                               <span style={{ color: "red", fontWeight: '250px' }}>Sale Ended</span> : ''
                                                      }
                                                   </div>}


                                          </div>
                                       </div>

                                       <div className="bottom">
                                          <ul>
                                             <li className="owner"><label className="market-label">Owner:</label><span>{this.state.getDetailMarket?.owner}</span></li>

                                             {/* <li><label className="market-label">Rarity:</label><span>{this.state.getDetailMarket?.rarityname}</span></li> */}
                                             <li><label className="market-label">Creator:</label>
                                                <a href={this.state.getDetailMarket?.creator_link} target="_blank" style={{ margin: '0px' }}>

                                                   <span>{this.state.getDetailMarket?.creator}</span>
                                                </a>
                                             </li>

                                             <li><label className="market-label">Category:</label><span>{this.state.getDetailMarket?.category_name}</span></li>

                                             <li><label className="market-label">Token ID:</label><span>{this.state.getDetailMarket?.token_id}</span></li>
                                             <li>
                                                <span>
                                                   <div className="clr"></div>
                                                   <div className="shareItem">Share on Social Media: <br />
                                                      <FacebookShareButton
                                                         url={`${config.imageUrl}${this.state.getDetailMarket?.image}`}
                                                         quote={`${this.state.getDetailMarket?.name}`}
                                                         className="Demo__some-network__share-button mt-2">
                                                         <FacebookIcon
                                                            size={32}
                                                            round />
                                                      </FacebookShareButton>  &nbsp;

                                                      <TwitterShareButton
                                                         url={`${config.imageUrl}${this.state.getDetailMarket?.image}`}
                                                         quote={`${this.state.getDetailMarket?.name}`}
                                                         className="Demo__some-network__share-button mt-2">
                                                         <TwitterIcon
                                                            size={32}
                                                            round />
                                                      </TwitterShareButton>    &nbsp;

                                                      

                                                      

                                                      <div>
                                                         <a href={`${config.blockchinUrl}${this.state.getDetailMarket?.token_hash}`} rel="noopener noreferrer" target="_blank" className="link  custom-btn gradient lg" style={{ display: 'inline-block', marginTop: '30px' }}>
                                                            <span style={{ fontWeight: 'bold', fontSize: '15px' }} className="txt">Blockchain View</span></a>

                                                         <div className="bottom">
                                                            <div className="price" id={this.state.getDetailMarket?.is_sold === 0 ? "price_dollar1" : "price_dollar"}>
                                                               <div>
                                                                  {this.state.getDetailMarket?.sell_type == 1 ?
                                                                     <> <span>
                                                                        {parseFloat(this.state.getDetailMarket?.price).toFixed(6)} ETH (${parseFloat(this.state.ethtousd * this.state.getDetailMarket?.price).toFixed(2)})

                                                                     </span> </>
                                                                     :
                                                                     <>
                                                                        <span>
                                                                           {this.state.getDetailMarket?.maxbid ? parseFloat(this.state.getDetailMarket?.maxbid).toFixed(6) : parseFloat(this.state.getDetailMarket?.price).toFixed(6)} ETH
                                                                           $
                                                                           {this.state.getDetailMarket?.maxbid ?
                                                                              (parseFloat(this.state.ethtousd * this.state.getDetailMarket?.maxbid).toFixed(2))
                                                                              :
                                                                              (parseFloat(this.state.ethtousd * this.state.getDetailMarket?.price).toFixed(2))
                                                                           }


                                                                        </span>
                                                                     </>
                                                                  }
                                                               </div>
                                                               {/* {this.state.getDetailMarket?.metadata ?
                                                                  <a href={`${config.imageUrl}` + this.state.getDetailMarket?.metadata} target="_blank" className="btn_wishlist"><span className="txt_metadata">View Metadata</span></a> : ''

                                                               } */}

                                                               {this.state.getDetailMarket?.metadata ?
                                                                  <a href={`https://api.artfungible.io/api/nft/metadata/${this.state.getDetailMarket?.token_id}`} target="_blank" className="btn_wishlist"><span className="txt_metadata">View Metadata</span></a> : ''

                                                               }
                                                            </div>

                                                         </div>
                                                      </div>




                                                      <div>
                                                         {!Cookies.get('artfungibleUserloginSuccess') ?
                                                            <a href={`${config.baseUrl}login`} className="btn_wishlist"><span className="txt">Add to wishlist</span></a>
                                                            :
                                                            this.state.getDetailMarket?.is_wishlist === 0 ?
                                                               <a href="#javascript" onClick={this.addToWishList.bind(this, this.id)} className="btn_wishlist"><span className="txt">Add to wishlist</span></a>
                                                               :
                                                               <a href="#javascript" style={{ cursor: 'not-allowed' }} className="btn_wishlist"><span className="txt">Already in Wishlist</span></a>
                                                         }

                                                      </div>
                                                      {new Date(this.state.getDetailMarket.start_date) > new Date() ? '' :

                                                         <div>
                                                            {/* <span style={{color:'red'}}>Sold</span> */}
                                                            {(this.state.getDetailMarket?.sell_type == 1) ?
                                                               this.state.getDetailMarket?.owner_id === this.loginData?.data?.id || !this.loginData?.data?.id ?
                                                                  this.state.getDetailMarket?.is_sold === 0 ?
                                                                     <a style={{ margin: '0px' }} className="btn_buynow" onClick={this.pleaselogin.bind(this)}><span>Buy Now
                                                                     </span></a> : <span style={{ color: 'red' }}>Sold</span> :
                                                                  this.state.getDetailMarket?.is_sold === 0 ?
                                                                     this.state.getDetailMarket?.buyTrxPending == 0 ? <a style={{ margin: '0px' }} href="#" data-toggle="modal" data-target="#myModal" className="btn_buynow"><span>Buy Now
                                                                     </span></a> : '' : <span style={{ color: 'red' }}>Sold</span> :
                                                               new Date(this.state.getDetailMarket.expiry_date) > new Date() ?
                                                                  <>
                                                                     {(this.state.getDetailMarket?.is_sold === 0) ?
                                                                        ((this.state.buyBtnText).toUpperCase() === 'BUY') ?
                                                                           (this.loginData.data) ?
                                                                              (this.state.getDetailMarket?.sell_type == 1) ?
                                                                                 this.state.getDetailMarket?.buyTrxPending == 0 ? <a href="#" data-toggle="modal" data-target="#myModal" className="btn_buynow"><span>Buy Now
                                                                                 </span></a> : ''
                                                                                 :
                                                                                 this.state.getDetailMarket?.owner_id === this.loginData?.data?.id ? <a style={{ cursor: 'not-allowed' }} className="btn_buynow"  >
                                                                                    <span>Place Bid</span>
                                                                                 </a> :
                                                                                    <a href="#" data-toggle="modal" data-target="#placeBid" className="btn_buynow"  >
                                                                                       <span >Place Bid</span>
                                                                                    </a>

                                                                              :
                                                                              <a href="#javascript" onClick={this.pleaselogin.bind(this)} style={{ marginLeft: '0px' }} className="btn_buynow"><span>
                                                                                 {this.state.getDetailMarket?.sell_type == 1 ? 'Buy Now' : 'Place Bid'}
                                                                              </span></a>
                                                                           :
                                                                           <a href="#javascript" style={{ marginLeft: '0px', cursor: 'not-allowed' }} className="btn_buynow"><span>{this.state.buyBtnText}</span></a>
                                                                        :
                                                                        <div>Not available for sell</div>
                                                                     }</>
                                                                  : ''}
                                                         </div>}

                                                   </div>
                                                </span>
                                             </li>
                                             <li><label className="market-label">Type:</label><span>{this.state.getDetailMarket?.sell_type == 1 ? 'Price' : 'Auction'}</span></li>



                                             <li style={{ opacity: '0' }}><label className="market-label">Type:</label><span>{this.state.getDetailMarket?.sell_type == 1 ? 'Price' : 'Auction'}</span></li>

                                          </ul>


                                       </div>
                                    </div>
                                    <div className=" col-md-4 col-xl-4 col-lg-5 item-callToActions" style={{ display: 'unset' }}>
                                       <div className="top">
                                       </div>
                                    </div>

                                    <div className="col-sm-12">

                                       <div className="txt pl-1 pr-1" style={{}}>
                                          <label className="market-label">Description:</label>
                                          <p>{this.state.getDetailMarket?.description}</p>
                                       </div>

                                       {/* {this.state.getDetailMarket?.sell_type == 1 ? '' : */}
                                       <div className="whishlist" style={{}}>
                                          <div className=" pl-1 pr-1 layout-spacing">
                                             <div className="widget-content widget-content-area br-6">
                                                <ReactDatatable
                                                   config={this.config}
                                                   records={this.state.getProductsbidsData}
                                                   columns={this.columns}
                                                   onSort={this.onSort} />
                                             </div>
                                          </div>
                                       </div>
                                       {/* } */}
                                    </div>
                                 </>}
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <div className="modal fade" id="imagePreview" role="dialog">
                  <div className="modal-dialog" >

                     <div className="modal-content" id="modelHeight">
                        <div className="modal-header">
                           <button type="button" className="close" data-dismiss="modal" style={{ color: "#000", marginTop: 'auto' }}>&times;</button>
                           <h4 className="modal-title"></h4>
                        </div>
                        <div className="modal-body">
                           <center>
                              {this.state.getDetailMarket?.file_type == 'image' ?
                                 <img className="nftPopupImage" src={`${config.imageUrl}${this.state.getDetailMarket?.image}`} alt="" />
                                 :
                                 <video className="nftPopupImage" alt="View" src={`${config.imageUrl}${this.state.getDetailMarket?.image}`} elevation="0" preload="auto" loop="" playsinline=""></video>
                              }
                           </center>
                        </div>
                     </div>
                  </div>
               </div>

               <div id="myModal" className="modal fade cart-modal" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false" style={{ display: "none" }}>
                  <div className="modal-dialog modal-lg">
                     <div className="modal-content">
                        <div className="modal-header pt-0 pb-0" style={{ borderBottom: "1px solid transparent" }}><button type="button" className="close" data-dismiss="modal" style={{ color: "#000", marginTop: 'auto' }}>×</button></div>
                        <div className="modal-body">
                           <div className="row">
                              <div className="col-md-6  " style={{ borderRight: "1px solid rgb(224, 224, 224)" }}>
                                 <div className="row p-3">
                                    <div className="col-sm-12 mb-2 pl-0 pr-0">
                                       <h4 className="">{this.state.getDetailMarket?.name}</h4>
                                    </div>
                                    <div className="col-md-6 mb-2 pl-0 pr-0"><span className="h8 weak">

                                       {this.state.getDetailMarket?.description?.length > MAX_LENGTH ?
                                          (
                                             <span className="collab-storename">
                                                {`${this.state.getDetailMarket?.description.substring(0, MAX_LENGTH)}...`}
                                             </span>
                                          ) :
                                          <span className="collab-storename">{this.state.getDetailMarket?.description}</span>
                                       }
                                    </span></div>

                                    <div className="col-md-6 text-right mb-2"><span className="h weak">Owner: {this.state.getDetailMarket?.owner}</span></div>
                                    <div className="col-12 pl-0 pr-0">
                                       <div className="text-center mt-3">
                                          {this.state.getDetailMarket?.file_type == 'image' ?
                                             <img style={{ width: "100%", height: '300px' }} src={`${config.imageUrl}${this.state.getDetailMarket?.image}`} alt="View" />
                                             :
                                             <video style={{ width: "100%" }} src={`${config.imageUrl}${this.state.getDetailMarket?.image}`} elevation="0" preload="auto" loop="" playsinline=""></video>
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="row">
                                    <div className="col-12">
                                       <div className="heading-wrapper d-md-block mb-4">
                                          <h4 className="strong payment-method-options ">Offer Price</h4>
                                       </div>
                                       <div className="mt-3 ">
                                          <div className="card-header pb-0 tab-card-header pl-0 pr-0">
                                             <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                                                <li className="nav-item">
                                                   <a className="nav-link active" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">
                                                      {this.state.getDetailMarket?.sell_type == 1 ?
                                                         <> <span>
                                                            {parseFloat(this.state.getDetailMarket?.price).toFixed(6)}</span> </>
                                                         : ''}
                                                      ETH</a>
                                                </li>
                                             </ul>
                                          </div>
                                          <div className="tab-content" id="myTabContent">
                                             <div className="tab-pane fade show active p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
                                                <div className="col-12  pl-0 pr-0">
                                                   <div className="mt-2">You need to first approve artfungible for access to MetaMask to complete your purchase.</div>
                                                </div>
                                                <div className="form-group form-check">
                                                   <input type="checkbox" id="checkbox" name="checkbox" value={this.state.checkbox} onChange={this.handleChange1} className="form-check-input" style={{ top: '-2px' }} />
                                                   <label className="form-check-label" for="exampleCheck1">I agree with  <a href=' https://terms.artfungible.io' target="_blank">
                                                      Terms and Conditions</a> and &nbsp;<a href=' https://privacy.artfungible.io' target="_blank">Privacy Policy.</a></label>
                                                </div>
                                                {this.state.errorMsg === 1 ?
                                                   <div className="" style={{ color: 'red' }}>Please check this box if you want to proceed.</div> : ''
                                                }
                                                <div className="mt-4">
                                                   <div className="col-12  pl-0 pr-0">
                                                      <div className="my-3 text-center">
                                                         {this.state.checkbox == 1 ?
                                                            <a onClick={this.buyItem.bind(this, this.state.getDetailMarket)} href="#javascript" className="btn btn-primary size-1 col-sm-12" ><span>{this.state.buyBtnText}</span></a>
                                                            :
                                                            <a href="#javascript" onClick={this.handleChange2} className="btn btn-primary size-1 col-sm-12" ><span>{this.state.buyBtnText} </span></a>

                                                         }

                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div id="placeBid" className="modal fade cart-modal" role="dialog" data-backdrop="static" data-keyboard="false" aria-hidden="true" style={{ display: "none" }}>
                  <div className="modal-dialog modal-lg">
                     <div className="modal-content">
                        <div className="modal-header pt-0 pb-0" style={{ borderBottom: "1px solid transparent" }}><button type="button" className="close" data-dismiss="modal" style={{ color: "#000", marginTop: 'auto' }}>×</button></div>
                        <div className="modal-body">
                           <div className="row">
                              <div className="col-md-6  " style={{ borderRight: "1px solid rgb(224, 224, 224)" }}>
                                 <div className="row p-3">
                                    <div className="col-sm-12 mb-2 pl-0 pr-0">
                                       <h4 className="">{this.state.getDetailMarket?.name}</h4>
                                    </div>
                                    <div className="col-md-6 mb-2 pl-0 pr-0"><span className="h8 weak"> {this.state.getDetailMarket?.description?.length > MAX_LENGTH ?
                                       (
                                          <span className="collab-storename">
                                             {`${this.state.getDetailMarket?.description.substring(0, MAX_LENGTH)}...`}
                                          </span>
                                       ) :
                                       <span className="collab-storename">{this.state.getDetailMarket?.description}</span>
                                    }</span></div>
                                    <div className="col-md-6 text-right mb-2"><span className="h weak">Owner: {this.state.getDetailMarket?.owner}</span></div>
                                    <div className="col-12 pl-0 pr-0">
                                       <div className="text-center mt-3">
                                          {this.state.getDetailMarket?.file_type == 'image' ?
                                             <img style={{ width: "100%", height: '300px' }} src={`${config.imageUrl}${this.state.getDetailMarket?.image}`} alt="View" />
                                             :
                                             <video alt="View" style={{ width: "100%" }} src={`${config.imageUrl}${this.state.getDetailMarket?.image}`} elevation="0" preload="auto" loop="" playsinline=""></video>
                                          }

                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="row">
                                    <div className="col-12">
                                       <div className="heading-wrapper d-md-block mb-4">
                                          <h4 className="strong payment-method-options ">Offer Price</h4>
                                       </div>

                                       <div className="tab-content" id="myTabContent">
                                          <div className="tab-pane fade show active p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
                                             <div className="col-12  pl-0 pr-0">
                                                <div className="mt-2">
                                                   <span style={{ color: (this.state.minimumBidErr == 1) ? 'red' : 'black' }}>You offer at least bid amount:
                                                      {this.state.getDetailMarket?.maxbid ? parseFloat(this.state.getDetailMarket?.maxbid).toFixed(6) : parseFloat(this.state.getDetailMarket?.price).toFixed(6)} ETH
                                                   </span>
                                                   <input type="number" name="bidAmount" className="form-control" placeholder="Enter bid amount" onKeyPress={(event) => {
                                                      if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                                         event.preventDefault();
                                                      }
                                                   }} value={this.state.bidAmount} onChange={this.onChange} />
                                                </div>
                                             </div>
                                             <br />
                                             {/* id="exampleCheck1" */}
                                             <div className="form-group form-check">
                                                <input type="checkbox" id="checkbox" name="checkbox" value={this.state.checkbox} onChange={this.handleChange1} className="form-check-input" style={{ top: '-2px' }} />
                                                <label className="form-check-label" for="exampleCheck1">I agree with  <a href="https://terms.artfungible.io" target="_blank">
                                                   Terms and Conditions</a> and &nbsp;<a href="https://privacy.artfungible.io" target="_blank">Privacy Policy.</a></label>
                                             </div>
                                             {this.state.errorMsg === 1 ?
                                                <div className="" style={{ color: 'red' }}>Please check this box if you want to proceed.</div> : ''
                                             }



                                             <div className="mt-4">
                                                <div className="col-12  pl-0 pr-0">
                                                   <div className="my-3 text-center">

                                                      <>
                                                         {this.state.minimumBidErr == 0 && this.state.checkbox == 1 ?
                                                            <a onClick={this.buyItem.bind(this, this.state.getDetailMarket)} href="#javascript" className="btn btn-primary size-1 col-sm-12" ><span>{this.state.placeBidBtnText}</span></a>
                                                            :
                                                            <a href="#javascript" onClick={this.handleChange2} className="btn btn-primary size-1 col-sm-12" ><span>{this.state.placeBidBtnText} </span></a>

                                                         }</>

                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>


               <Dialog
                  title="Warning"
                  icon="warning-sign"
                  className='transaction-process'
                  style={{
                     color: 'red',
                     zIndex: '1111'
                  }}
                  isOpen={this.state.isDialogOpen}
                  isCloseButtonShown={false}
               >
                  <div className="text-center">
                     <BarLoader color="#e84747" height="2" />
                     <br />
                     <h4 style={{ color: '#000' }}>Transaction under process...</h4>
                     <p style={{ color: 'red' }}>
                        Please do not refresh page or close tab.
                     </p>
                     <div>
                     </div>
                  </div>
               </Dialog>

               <Footer1 />
            </div>
         </>
      )
   }
}
