import React, { Component } from 'react';
// import Header1 from './directives/header1'
// import Footer1 from './directives/footer1'
// import header1 from '../directives/header1';
import Footer1 from '../directives/footer1';
import Header1 from '../directives/header1';
import axios from 'axios';
// import config from '../config/config'
import config from '../config/config';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
// import Countdown from 'react-countdown';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import FlipNumbers from 'react-flip-numbers';
import FlipCountdown from '@rumess/react-flip-countdown';
import Countdown, { zeroPad } from 'react-countdown';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Fade, Slide, Zoom, Bounce, Flip } from 'react-reveal';

import Slider from "react-slick";
import { Player } from 'video-react';
import ReactAudioPlayer from 'react-audio-player';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';


const MAX_LENGTH = 10;

export default class seasky extends Component {

    render() {

        return (
            <>


                <div className="body_bg">

                    <Header1 />

                    {/*---------------------- Collection Section Start -------------------- */}

                    <section className='collection pt-lg-5 pt-3'>
                        <div className='container-fluid px-lg-5'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <Zoom>
                                        <div className='mb-lg-0 mb-5'>
                                            <img src="mercafor_dashboard/assets/img/Jollykoh.webp" className="collec_jollykoh img-fluid" alt="subscribeimg" />
                                        </div>
                                    </Zoom>
                                </div>
                                <div className='col-lg-8 col-md-6 col-12 d-flex align-items-center pl-lg-5'>
                                    <Slide right>
                                        <div className='substext pl-lg-5'>
                                            <h5>Jollykoh</h5>
                                            <div className='viewart'>
                                                <span><img src="mercafor_dashboard/assets/img/globe.svg" className="" alt="globe" /></span><h6>View Art here</h6>
                                            </div>
                                            <p>Our primary memberships are all sold out, but we still offer plenty of secondary memberships through OpenSea. Our primary memberships are all sold out, but we still offer plenty of secondary memberships through OpenSea. Our primary memberships are all sold out, but we still offer plenty of secondary memberships through OpenSea. Our primary memberships are all sold out, but we still offer plenty of secondary memberships through OpenSea. Our primary memberships are all sold out, but we still offer plenty of secondary memberships through OpenSea.</p>
                                        </div>
                                    </Slide>
                                </div>

                            </div>
                        </div>
                    </section>

                    {/*---------------------- Collection Section End -------------------- */}

                    {/*---------------------- Item Activity Section Start -------------------- */}

                    <section className='itemactivity pt-lg-5 pt-3'>
                        <div className='container-fluid px-lg-5'>
                            <div className='row'>
                                <div className='col-12 text-center mb-4'>
                                    <h2 className='tophead'>Seasky</h2>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12 col-12'>
                                    <Slide left>
                                        <div className="seasktext">
                                            <p>Lorem ipsum dolor sit amet consectetur. Quisque purus vulputate tortor lacus ultrices consequat. Sed maecenas vitae turpis maecenas lacus lectus. Urna donec urna pharetra porttitor pharetra consequat posuere nunc. Nisl eget massa suspendisse morbi nisi. Enim cursus aliquam arcu faucibus ultricies. Felis dolor semper nascetur neque morbi. Scelerisque integer montes nunc fermentum urna vulputate. Elementum et interdum maecenas at lorem eu fermentum id consequat. Volutpat sit cursus lorem nisl ut libero pellentesque proin sem. Nulla augue convallis pellentesque potenti habitasse nulla ut donec. Quis duis aliquet aliquam amet malesuada viverra. Convallis porttitor pulvinar amet aenean integer ut faucibus. Leo lectus nullam quis tempus. Enim pellentesque nec eu arcu aliquam cursus aliquet purus. Nulla condimentum pulvinar vulputate in orci.</p>
                                            <p>Lorem ipsum dolor sit amet consectetur. Quisque purus vulputate tortor lacus ultrices consequat. Sed maecenas vitae turpis maecenas lacus lectus. Urna donec urna pharetra porttitor pharetra consequat posuere nunc. Nisl eget massa suspendisse morbi nisi. Enim cursus aliquam arcu faucibus ultricies. Felis dolor semper nascetur neque morbi. Scelerisque integer montes nunc fermentum urna vulputate. Elementum et interdum maecenas at lorem eu fermentum id consequat. Volutpat sit cursus lorem nisl ut libero pellentesque proin sem. Nulla augue convallis pellentesque potenti habitasse nulla ut donec. Quis duis aliquet aliquam amet malesuada viverra. Convallis porttitor pulvinar amet aenean integer ut faucibus. Leo lectus nullam quis tempus. Enim pellentesque nec eu arcu aliquam cursus aliquet purus. Nulla condimentum pulvinar vulputate in orci.</p>
                                            <p>Lorem ipsum dolor sit amet consectetur. Quisque purus vulputate tortor lacus ultrices consequat. Sed maecenas vitae turpis maecenas lacus lectus. Urna donec urna pharetra porttitor pharetra consequat posuere nunc. Nisl eget massa suspendisse morbi nisi. Enim cursus aliquam arcu faucibus ultricies. Felis dolor semper nascetur neque morbi. Scelerisque integer montes nunc fermentum urna vulputate. Elementum et interdum maecenas at lorem eu fermentum id consequat. Volutpat sit cursus lorem nisl ut libero pellentesque proin sem. Nulla augue convallis pellentesque potenti habitasse nulla ut donec. Quis duis aliquet aliquam amet malesuada viverra. Convallis porttitor pulvinar amet aenean integer ut faucibus. Leo lectus nullam quis tempus. Enim pellentesque nec eu arcu aliquam cursus aliquet purus. Nulla condimentum pulvinar vulputate in orci.</p>
                                        </div>
                                    </Slide>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12 text-center'>
                                            <Flip right>
                                                <div className="card feature">
                                                    <img className="seaskyimg img-fluid" src="mercafor_dashboard/assets/img/seaskyboat.webp" alt="seaskyboat" />
                                                    <div className="card-body">
                                                        <div className=''>
                                                            <a href='#' className='custombtn listsale'>Seasky #30</a>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <div className='genesistext pb-4'>
                                                                    <h4 className="">Seasky</h4>
                                                                    <p className='my-3'>Remaining: 20 / 50</p>
                                                                    <h6 className="">0.15 ETH</h6>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <div><button type="button" class="btn btn-buy-primary w-100 mt-3">Mint</button></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Flip>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-12 text-center'>
                                            <Flip right>
                                                <div className="card feature">
                                                    <img className="seaskyim img-fluid" src="mercafor_dashboard/assets/img/genesis4.webp" alt="genesis4" />
                                                    <div className="card-body">
                                                        <div className=''>
                                                            <a href='#' className='custombtn listsale'>Seasky #30</a>
                                                        </div>
                                                        <div className=''>
                                                            <a href='#' className='custombtn soldout'>Sold out</a>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <div className='genesistext pb-4'>
                                                                    <h4 className="">Seasky</h4>
                                                                    <p className='my-3'>Remaining: 20 / 50</p>
                                                                    <h6 className="">0.15 ETH</h6>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <div><button type="button" class="btn btn-buy-primary w-100 mt-3">Find in market palce</button></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Flip>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section >

                    {/*---------------------- Item Activity Section End -------------------- */}





                    {/*---------------------- Seasky Collection Section Start -------------------- */}

                    <section className='seasky_collection py-5'>
                        <div className='container-fluid px-lg-5'>
                            <div className='row'>
                                <div className='col-12 text-center mb-4'>
                                    <h2 className='tophead'>More Collections</h2>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                    <div className="card feature">
                                        <img className="seaskyim img-fluidgcard" src="mercafor_dashboard/assets/img/seasky8.webp" alt="seasky8" />
                                        <div className="card-body">
                                            <div className='text-center pt-4'>
                                                <a href='#' className='custombtn'>Mint</a>
                                            </div>
                                            <div className=''>
                                                <a href='#' className='custombtn soldout2'>Seasky #1</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                    <div className="card feature">
                                        <img className="seaskyimgcard" src="mercafor_dashboard/assets/img/genesis2.webp" alt="genesis2" />
                                        <div className="card-body">
                                            <div className='text-center pt-4'>
                                                <a href='#' className='custombtn'>Mint</a>
                                            </div>
                                            <div className=''>
                                                <a href='#' className='custombtn soldout2'>Seasky #2</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                    <div className="card feature">
                                        <img className="seaskyimgcard" src="mercafor_dashboard/assets/img/genesis3.webp" alt="genesis3" />
                                        <div className="card-body">
                                            <div className='text-center pt-4'>
                                                <a href='#' className='custombtn'>Mint</a>
                                            </div>
                                            <div className=''>
                                                <a href='#' className='custombtn soldout2'>Seasky #3</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-12 mb-3'>
                                    <div className="card feature">
                                        <img className="seaskyimgcard" src="mercafor_dashboard/assets/img/genesis4.webp" alt="genesis4" />
                                        <div className="card-body">
                                            <div className='text-center pt-4'>
                                                <a href='#' className='custombtn'>Mint</a>
                                            </div>
                                            <div className=''>
                                                <a href='#' className='custombtn soldout2'>Seasky #4</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className='row'>
                                <div className="col-lg-12 col-md-12 col-12">
                                    <div className="offer-right">
                                        <div className="owl-nav">
                                            <div className="customPrevBtn">
                                                <span>
                                                    {/* <i className="fa fa-chevron-left icoffer1" aria-hidden="true" /> */}
                                                    <img src="mercafor_dashboard/assets/img/arrowwhite.svg" alt="arrowwhite" />
                                                </span>
                                            </div>
                                            <div className="customNextBtn">
                                                <span>
                                                    {/* <i className="fa fa-chevron-right icoffer2" aria-hidden="true" /> */}
                                                    <img src="mercafor_dashboard/assets/img/arrowwhite.svg" alt="arrowwhite" />

                                                </span>
                                            </div>
                                        </div>
                                        <div className="slide-content">
                                            <div className="seasky-slide owl-carousel owl-theme">
                                                <Slide left>
                                                    <div className="item">
                                                        <div className="card feature">
                                                            <div className='cardblock'>
                                                                <img className="seaskyimgcard img-fluid" src="mercafor_dashboard/assets/img/seasky8.webp" alt="seasky8" />
                                                            </div>
                                                            <div className="card-body">
                                                                <div className='text-center pt-4'>
                                                                    <a href='#' className='custombtn'>Mint</a>
                                                                </div>
                                                                <div className=''>
                                                                    <a href='#' className='custombtn soldout2'>Seasky #1</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slide>
                                                <Slide top>
                                                    <div className="item">
                                                        <div className="card feature">
                                                            <div className='cardblock'>
                                                                <img className="seaskyimgcard img-fluid" src="mercafor_dashboard/assets/img/genesis2.webp" alt="genesis2" />
                                                            </div>
                                                            <div className="card-body">
                                                                <div className='text-center pt-4'>
                                                                    <a href='#' className='custombtn'>Mint</a>
                                                                </div>
                                                                <div className=''>
                                                                    <a href='#' className='custombtn soldout2'>Seasky #2</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slide>
                                                <Slide bottom>
                                                    <div className="item">
                                                        <div className="card feature">
                                                            <div className='cardblock'>
                                                                <img className="seaskyimgcard img-fluid" src="mercafor_dashboard/assets/img/genesis3.webp" alt="genesis3" />
                                                            </div>
                                                            <div className="card-body">
                                                                <div className='text-center pt-4'>
                                                                    <a href='#' className='custombtn'>Mint</a>
                                                                </div>
                                                                <div className=''>
                                                                    <a href='#' className='custombtn soldout2'>Seasky #3</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slide>
                                                <Slide right>
                                                    <div className="item">
                                                        <div className="card feature">
                                                            <div className='cardblock'>
                                                                <img className="seaskyimgcard img-fluid" src="mercafor_dashboard/assets/img/genesis4.webp" alt="genesis4" />
                                                            </div>
                                                            <div className="card-body">
                                                                <div className='text-center pt-4'>
                                                                    <a href='#' className='custombtn'>Mint</a>
                                                                </div>
                                                                <div className=''>
                                                                    <a href='#' className='custombtn soldout2'>Seasky #4</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slide>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    {/*---------------------- Seasky Collection Section End -------------------- */}

                    {/*---------------------- Feature Section Start -------------------- */}

                    {/* <section className="feature_collection pt-5">
                        <div className="container-fluid px-lg-5">
                            <div className='row'>
                                <div className='col-12 text-center mb-4'>
                                    <h2 className='tophead'>Featured Collection</h2>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-lg-12 col-md-12 col-12">
                                    <div className="offer-right">
                                        <div className="owl-nav">
                                            <div className="customPrevBtn">
                                                <span>
                                                    <img src="mercafor_dashboard/assets/img/arrowwhite.svg" alt="arrowwhite" />
                                                </span>
                                            </div>
                                            <div className="customNextBtn">
                                                <span>
                                                    <img src="mercafor_dashboard/assets/img/arrowwhite.svg" alt="arrowwhite" />

                                                </span>
                                            </div>
                                        </div>
                                        <div className="slide-content">
                                            <div className="offer-slide owl-carousel owl-theme">
                                                <div className="item">
                                                    <div className="card feature">
                                                        <img className="card-img-top" src="mercafor_dashboard/assets/img/Jollykoh1.webp" alt="Jollykoh1" />
                                                        <div className="card-body">
                                                            <p className="card-text">
                                                                Star
                                                            </p>
                                                            <h5 className="card-title">Jollykoh</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="card feature">
                                                        <img className="card-img-top" src="mercafor_dashboard/assets/img/Jollykoh.webp" alt="Jollykoh1" />
                                                        <div className="card-body">
                                                            <p className="card-text">
                                                                Star
                                                            </p>
                                                            <h5 className="card-title">Jollykoh</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="card feature">
                                                        <img className="card-img-top" src="mercafor_dashboard/assets/img/Jollykoh3.webp" alt="Jollykoh1" />
                                                        <div className="card-body">
                                                            <p className="card-text">
                                                                Star
                                                            </p>
                                                            <h5 className="card-title">Jollykoh</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="card feature">
                                                        <img className="card-img-top" src="mercafor_dashboard/assets/img/Jollykoh4.webp" alt="Jollykoh1" />
                                                        <div className="card-body">
                                                            <p className="card-text">
                                                                Star
                                                            </p>
                                                            <h5 className="card-title">Jollykoh</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    {/*---------------------- Feature Section End -------------------- */}



                    < Footer1 />
                </div >
            </>
        )
    }
}