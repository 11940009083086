import React, { Component, Fragment } from 'react';
import axios from 'axios';
import config from '../config/config'
import Cookies from 'js-cookie'
import Header2 from '../directives/header2'
import $ from 'jquery';
import InnerLeftSidebar from '../directives/innerLeftSidebar'
import ReactDatatable from '@ashvin27/react-datatable';
const headers = {
    'Content-Type': 'application/json'
};

export default class transaction extends Component {

    constructor(props) {
        super(props)
        this.loginData = (!Cookies.get('artfungibleUserloginSuccess')) ? [] : JSON.parse(Cookies.get('artfungibleUserloginSuccess'));
        if (!this.loginData.data) {
            window.location.href = `${config.baseUrl}`
        }
        this.state = {
            listOrderData: [],
            ConnectWalletAddress: ''
        }

        this.columns = [
            {
                key: "image",
                text: "Product image",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            <a href={`${config.imageUrl}/${record.image}`} target="_blank">
                                {record.file_type == 'image' ?
                                    <img src={`${config.imageUrl}/${record.image}`} className="profile-img" alt="avatar" />
                                    :
                                    <video src={`${config.imageUrl}/${record.image}`} className="profile-img" alt="avatar" />
                                }
                            </a>
                        </Fragment>
                    );
                }
            },
            {
                key: "name",
                text: "Product Name",
                sortable: true
            },
            {
                key: "price",
                text: "Price",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            {record.crypto_amount} ETH (${record.bid_price})
                        </Fragment>
                    );
                }
            },
            {
                key: "token_id",
                text: "Product ID",
                sortable: true
            },
            {
                key: "status",
                text: "Status",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            {(record.refund_hash || record.status == 2) ? 'Completed' : 'Pending'}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            &nbsp;
                            <a href={`${config.blockchinUrl + record.amounTrxHash}`} rel="noopener noreferrer" target="_blank" className="link">
                                <button className="btn btn-success">Blockchain View</button>
                            </a>
                        </Fragment>
                    );
                }
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false
            }
        }

    }
    componentDidMount() {
        this.gerRefundManagementAPI()
    }

    async gerRefundManagementAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}/gerUserRefundManagement`,
            data: { "user_id": this.loginData.data.id }
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    listOrderData: response.data?.response
                })
            }
        })
    }

    removeCls() {
        $('.main-container').removeClass('sbar-open');
    }

    render() {
        return (
            <>
                <div className="header-container fixed-top" onClick={this.removeCls.bind(this, 1)}>
                    <Header2 />
                </div>
                <div className="main-container sidebar-closed whishlist" id="container" onClick={this.removeCls.bind(this, 1)}>

                    <div className="overlay"></div>
                    <div className="cs-overlay"></div>
                    <div className="search-overlay"></div>

                    <InnerLeftSidebar />
                    <div id="content" className="main-content">
                        <div className="layout-px-spacing">
                            <div className="row layout-spacing">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
                                    <div className="page-header">
                                        <div className="page-title">
                                            <h3>Refund Management</h3>
                                        </div>
                                    </div>
                                    <div className="row mb-4 mt-3">
                                        <div className="col-sm-12 col-12">
                                            <div className="tab-content" id="v-pills-tabContent">
                                                <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                    <div className="row" id="cancel-row">
                                                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                                            <div className="widget-content widget-content-area br-6">
                                                                <ReactDatatable
                                                                    config={this.config}
                                                                    records={this.state.listOrderData}
                                                                    columns={this.columns}
                                                                    onSort={this.onSort} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}