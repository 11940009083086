import React, { Component } from 'react';
import Header1 from '../directives/header1'
import Footer1 from '../directives/footer1'
import axios from 'axios';
import config from '../config/config'
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import FlipNumbers from 'react-flip-numbers';
import FlipCountdown from '@rumess/react-flip-countdown';



const responsive = {
   0: { items: 1 },
   568: { items: 2 },
   1024: { items: 3 },
};

const items = [
   <div className="item" data-value="1"><img src="img/1_slideimage.jpg"/></div>,
   <div className="item" data-value="2"><img src="img/2_slideimage.gif"/></div>,
   <div className="item" data-value="3"><img src="img/3_slideimage.jpeg"/></div>,
   <div className="item" data-value="4"><img src="img/4_slideimage.jpg"/></div>,
   <div className="item" data-value="5"><img src="img/5_slideimage.gif"/></div>,

   
   
];
export default class art_fungible extends Component {


   constructor(props) {
      super(props)
      this.state = {
         orderby: '1',
         getListMarket: [],
         getListMarketBuddha: [],
         trending: '1',
         topselling: '0',
         sold: '0',
         category_id: '0',
         falseMsg: '',
         falseMsg1: ''


      }

      this.loginData = (!Cookies.get('artfungibleUserloginSuccess')) ? [] : JSON.parse(Cookies.get('artfungibleUserloginSuccess'))

   }

   componentDidMount() {
      Cookies.remove('categoryid')
      this.getMarketPlaceAPI()
      this.getMarketPlaceBudhaAPI()
   }


   //====================================== Marketplace get  =============================

   async getMarketPlaceAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/listitem`,
         //  headers: { "Authorization": this.loginData.message },
         data: { 'trending': this.state.trending, 'topselling': this.state.topselling, 'sold': this.state.sold, 'orderby': this.state.orderby, 'category_id': this.state.category_id }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               getListMarket: response.data?.response
            })

         }
      }).catch(err => {
         if (err.request) { } if (err.response) {
            this.setState({
               getListMarket: [],
               falseMsg: err.response.data.msg
            })
            // toast.error(err.response.data.msg, {
            //     position: toast.POSITION.TOP_CENTER
            // });
         }
      });
   }

   //====================================== For budha Marketplace get  =============================

   async getMarketPlaceBudhaAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/listitem`,
         //  headers: { "Authorization": this.loginData.message },
         data: { 'trending': '0', 'topselling': this.state.topselling, 'sold': this.state.sold, 'orderby': this.state.orderby, 'category_id': '3' }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               getListMarketBuddha: response.data?.response
            })

         }
      })
         .catch(err => {
            if (err.request) { } if (err.response) {
               this.setState({
                  getListMarketBuddha: [],
                  falseMsg1: err.response.data.msg
               })
               // toast.error(err.response.data.msg, {
               //     position: toast.POSITION.TOP_CENTER
               // });
            }
         });
   }

   marketplace(categoryid) {
      Cookies.set('categoryid', categoryid);
      window.location.href = `marketplace`
   }

   render() {

      return (
         <>


<div className="body_bg">
<Header1 />
{console.log(items)}

<AliceCarousel 
       autoPlay
       autoPlayInterval={4000}
       animationDuration={4000}
       animationType="fadeout"
       infinite
        touchTracking={false}
        
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy="alternate"
    />

{/* <br/> */}
{/* <Carousel className="slider-banner" autoPlay showArrows={true} style={{height : "100px"}}>
 <div >
 <video autoPlay width="100%" height="auto" >
      <source src="https://media.niftygateway.com/video/upload/v1623680098/Max/ClaytonMcCracken/NFT_HarmonicOpposites_Banner_v2_xghspb.mp4" type="video/mp4" />
   </video>
 </div>
 <div>
 <video autoPlay width="100%" height="auto" >
      <source src="https://media.niftygateway.com/video/upload/v1623680098/Max/ClaytonMcCracken/NFT_HarmonicOpposites_Banner_v2_xghspb.mp4" type="video/mp4" />
   </video>
 </div>
 <div>
 <video autoPlay width="100%" height="auto" >
      <source src="https://media.niftygateway.com/video/upload/v1623680098/Max/ClaytonMcCracken/NFT_HarmonicOpposites_Banner_v2_xghspb.mp4" type="video/mp4" />
   </video>
 </div>
</Carousel> */}
{/* <section className="banner">
<div className="head-bg">
      <div className="head-bg-img"></div>
      <div className="head-bg-content">
         <h1>ART Fungible  is the premier marketplace for Nifties,<br/> which are digital items you can truly own</h1>
         
      </div>	
   </div>
</section> */}

<section className="Recent-activity-section pb-5 ">
      <div className="container">
         <div className="row">
            <div className="col-sm-3"><h2>Live auction</h2></div>
            <div className="col-sm-9">
            <FlipCountdown
             hideYear
             hideMonth
             hideDay
 endAt={'2022-12-12 01:26:58'} // Date/Time
/>
            {/* <FlipNumbers height={12} width={12} color="red" background="white" play perspective={100} numbers="12345" /> */}
            {/* <Countdown date={Date.now() + 10000} /> */}
               {/* <section id="timer">


<div class=" countdown-wrapper text-center ">
    
    <div class="card-block">
       <div id="countdown">
           <div class="well">
               <span id="hour" class="timer bg-gold"></span>
               <span id="min" class="timer bg-gold"></span>
               <span id="sec" class="timer bg-gold"></span>
           </div>
       </div>
    </div>
   

</div>

</section> */}

</div>
         </div>
         
         <div className="row">
         
            <div className="col-sm-6">
               <div className="recent-card">
                  <div className="recent-list">
                      
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media" src="https://assets.foundation.app/9U/Ts/Qmda4mmvNA2R5MmHLD1SaCMGLZpfw4tYaTrRwpRvZM9UTs/nft_preview.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >Why would I care I'm just a cat? #234/911</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Recently Sold</p>
                  <p className="bold-text">$2300.00</p>
                  <p>16 minutes ago</p>
                  </div>
               </div>
            </div>
            
            <div className="col-sm-6">
               <div className="recent-card">
                  <div className="recent-list">
                      
                     <img  src="img/nft.jpg" elevation="0" preload="auto" loop="" playsinline="" />
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >Unleashed #22/290</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Recently Sold</p>
                  <p className="bold-text">$80.00</p>
                  <p>28 minutes ago</p>
               </div>
            </div>
         </div>
            
            
            {/* <div className="col-sm-2"></div>
            <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                      
                  <img  src="img/nft2.jpg"/>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >FACES</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Last Sold</p>
                  <p className="bold-text">$448.00</p>
                  <p>4126 Sold</p>
               </div>
            </div>
            </div>
            <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                      
                  <img  src="img/nft3.jpg"/>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >FACES</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Last Sold</p>
                  <p className="bold-text">$448.00</p>
                  <p>4126 Sold</p>
               </div>
            </div>
            </div>
            
            <div className="col-sm-2"></div> */}
            
            {/* <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                      
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media" src="img/nft_preview1.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >FACES</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Last Sold</p>
                  <p className="bold-text">$448.00</p>
                  <p>4126 Sold</p>
               </div>
            </div>
            </div> */}
            {/* <div className="col-sm-4">
        
         <div className="recent-card">
            <div className="recent-list">
               
               <video className="MuiCardMedia-root jss400 MuiCardMedia-media" src="img/nft_preview2.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
            </div>
            <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
            <div className="heading">
               <p >FACES</p>
            </div>
            </div>
            <div className="bg-black text-center text-white">
            <p >Last Sold</p>
            <p className="bold-text">$448.00</p>
            <p>4126 Sold</p>
         </div>
      </div>
      </div>
      <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                      
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media" src="img/nft_preview3.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >FACES</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Last Sold</p>
                  <p className="bold-text">$448.00</p>
                  <p>4126 Sold</p>
                  
               </div>
            </div>
            </div>
            <div className="col-sm-4">
         <div className="recent-card">
            <div className="recent-list">
               
               <video className="MuiCardMedia-root jss400 MuiCardMedia-media" src="img/nft_preview4.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
            </div>
            <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
            <div className="heading">
               <p >FACES</p>
            </div>
            </div>
            <div className="bg-black text-center text-white">
            <p >Last Sold</p>
            <p className="bold-text">$448.00</p>
            <p>4126 Sold</p>
            
         
      </div>
      </div>
         
         </div>
     */}
      </div>
      </div>
</section>

<section className="newsletter ">
<div className="container">
   <div className="row">
      <div className="col-md-2">
         
      </div>
      <div className="col-md-8 text-center newsletter-form">
         <h3>Stay tuned for upcoming events and put the email 👀</h3>
         <p>Subscribe to our ultra-exclusive drop list and be the first to know about upcoming Artfungible drops.</p>
         <br/>
         <form action="./" className="subscribe-form">
            <div className="row">
               <div className="col-sm-2"></div>
               <div className="col-sm-8">
                  <div className="row">
                  <div className="col-sm-9">
                           <input type="text" placeholder="Enter your email address" className="form-control" required="" />
                     </div>
                     <div className="col-sm-3">
                            <button type="submit" className="btn btn-primary submit-block">Subscribe</button>
                     </div>
                  </div>
               </div>
               <div className="col-sm-2"></div>
               
            </div>
         </form>
      </div>
      <div className="col-md-2">
         
      </div>
      
   </div>
</div>


</section>
<section className="Recent-activity-section bg-light-gray mt-4">
      <div className="container">
         <h2>Recent Highlight</h2>
         <div className="row">
            <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                      
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media"  src="img/nft_preview2.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >Why would I care I'm just a cat? #234/911</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Recently Sold</p>
                  <p className="bold-text">$2300.00</p>
                  <p>16 minutes ago</p>
               </div>
            </div>
         </div>
            <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                      
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media"  src="img/nft_preview3.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >Unleashed #22/290</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Recently Sold</p>
                  <p className="bold-text">$80.00</p>
                  <p>28 minutes ago</p>
               </div>
            </div>
         </div>
         <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                      
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media" src="img/nft_preview4.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >Why would I care I'm just a cat? #234/911</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Recently Sold</p>
                  <p className="bold-text">$2300.00</p>
                  <p>16 minutes ago</p>
               </div>
            </div>
         </div>
            <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                     
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media" src="https://media.niftygateway.com/video/upload/q_auto:good,w_500/v1621013384/A/Monstercat2/Unleashed_qj5xdq.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >Unleashed #22/290</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Recently Sold</p>
                  <p className="bold-text">$80.00</p>
                  <p>28 minutes ago</p>
               </div>
            </div>
         </div>
            
         <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                      
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media"  src="img/nft_preview2.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >Why would I care I'm just a cat? #234/911</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Recently Sold</p>
                  <p className="bold-text">$2300.00</p>
                  <p>16 minutes ago</p>
               </div>
            </div>
         </div>
            <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                      
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media"  src="img/nft_preview3.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >Unleashed #22/290</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Recently Sold</p>
                  <p className="bold-text">$80.00</p>
                  <p>28 minutes ago</p>
               </div>
            </div>
         </div>
         {/* <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                      
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media" src="img/nft_preview4.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >Why would I care I'm just a cat? #234/911</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Recently Sold</p>
                  <p className="bold-text">$2300.00</p>
                  <p>16 minutes ago</p>
               </div>
            </div>
         </div>
            <div className="col-sm-4">
               <div className="recent-card">
                  <div className="recent-list">
                     
                     <video className="MuiCardMedia-root jss400 MuiCardMedia-media" src="https://media.niftygateway.com/video/upload/q_auto:good,w_500/v1621013384/A/Monstercat2/Unleashed_qj5xdq.mp4" elevation="0" preload="auto" loop="" playsinline=""></video>
                  </div>
                  <div className="bg-light-grey text-center" style={{paddingBottom: '12px'}}>
                  <div className="heading">
                     <p >Unleashed #22/290</p>
                  </div>
                  </div>
                  <div className="bg-black text-center text-white">
                  <p >Recently Sold</p>
                  <p className="bold-text">$80.00</p>
                  <p>28 minutes ago</p>
               </div>
            </div>
         </div>
         */}
         </div>
      </div>
</section>

<Footer1 />
</div>

      

         </>
      )
   }
}