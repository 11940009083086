import React, { Component, Fragment } from 'react';
import InnerHeader from '../directives/header1'
import Footer1 from '../directives/footer1'
import axios from 'axios';
import config from '../config/config'
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ReactDatatable from '@ashvin27/react-datatable';

export default class stats extends Component {

   constructor(props) {
      super(props)
      this.state = {
         getProductsbidsData: []
      }
      this.loginData = (!Cookies.get('artfungibleUserloginSuccess')) ? [] : JSON.parse(Cookies.get('artfungibleUserloginSuccess'))
      this.onChange = this.onChange.bind(this);


      this.columns = [
         {
            key: "image",
            text: "Product image",
            sortable: true,
            cell: record => {
               return (
                  <Fragment>
                    <a target="_blank" href={`${config.imageUrl+record.image}`}>
                     {record.file_type == 'image'?
                        <img src={`${config.imageUrl}${record.image}`} className="product-img" alt="avatar" />
                      :
                        <img src="img/videologo.png" className="product-img" alt="avatar" />
                      }
                      </a>
                  </Fragment>
               );
            }
         },
         {
            key: "name",
            text: "Name",
            cell: record => {
                return (
                   <Fragment>
                        <a target="_blank" href={`${config.baseUrl}marketplaceDetail/`+record.id}>
                            {record.name}
                        </a>
                   </Fragment>
                );
             }            
         },
         {
            key: "owner",
            text: "Owner",
            cell: record => {
                return (
                   <Fragment>
                        
                            {record.owner}
                   </Fragment>
                );
             }            
         },
         {
            
            text: "Price",
            cell: record => {
               return (
                  <Fragment>
                      {parseFloat(record?.price).toFixed(6)} ETH (${parseFloat(this.state.ethtousd * record?.price).toFixed(2)})
                  </Fragment>
               );
            }
         },
         {
            key: "sell_type",
            text: "Sale Type",
            cell: record => {
                return (
                   <Fragment>
                      {record.sell_type == 1? "Price" : "Auction" }
                   </Fragment>
                );
             }            
         },

      ];

      this.config = {
         page_size: 10,
         length_menu: [10, 20, 50],
         show_filter: true,
         show_pagination: true,
         pagination: 'advance',
         button: {
            excel: false,
            print: false
         }
      }
   }

   componentDidMount() {
      this.getStatsDataAPI()
      this.getEthToUsd()
   }

   onChange(e) {
      this.setState({
         [e.target.name]: e.target.value
      })
   }

   async getEthToUsd() {
      await axios({
         method: 'get',
         url: `https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT`,
      }).then(response => {
         this.setState({
            ethtousd: response.data.price,
            usdtoeth: 1 / response.data.price
         })
      })
   }


   async getStatsDataAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/getStatsData`,
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               getProductsbidsData: response.data?.data
            })
         }
      })
   }

   render() {

      return (

         <>
            <div className="body_bg">
               <InnerHeader />

               <section className="product_section">
                  <br />
                  <div className="item-details-container">
                     <ToastContainer />

                     <div className="container-fluid">
                        <div className="item-details-content">
                           <div className="row" style={{ alignItems: 'unset' }}>
                          
                              <div className="col-sm-12">

                                 {this.state.getDetailMarket?.sell_type == 1 ? '' :
                                    <div className="whishlist" style={{ marginLeft: '50px' }}>
                                       <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                          <div className="widget-content widget-content-area br-6">
                                             <ReactDatatable
                                                config={this.config}
                                                records={this.state.getProductsbidsData}
                                                columns={this.columns}
                                                onSort={this.onSort} />
                                          </div>
                                       </div>
                                    </div>}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <Footer1 />
            </div>
         </>
      )
   }
}
