import React, { Component } from 'react';
import axios from 'axios';
import config from './config/config'
import Header2 from './directives/header2'
import Footer from './directives/footer'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Calendar from 'react-calendar';
import Cookies from 'js-cookie'
import InnerLeftSidebar from './directives/innerLeftSidebar'
import $ from 'jquery';

const headers = {
   'Content-Type': 'application/json'
};

const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");


export default class userprofile extends Component {

   constructor(props) {
      super(props);
      this.loginData = (!Cookies.get('artfungibleUserloginSuccess')) ? [] : JSON.parse(Cookies.get('artfungibleUserloginSuccess'))
      if (!this.loginData.data) {
         window.location.href = `${config.baseUrl}`
      }
      this.state = {
         id: '',
         first_name: '',
         last_name: '',
         user_name: '',
         phone: '',
         dob: '',
         countrylistData: [],
         getUser: '',
         email: this.loginData.data.user_email,
         old_password: '',
         new_password: '',
         confirm_password: '',
         image_file: null,
         image_preview: '',
         getProfilePicAPIData: '',
         aashow: '',
         address: '',
         house_number: '',
         city: '',
         country_id: '',
         eyeold: true,
         eyenew: true,
         eyeconfirm: true,
         profile_pic: '',
         passwordMsg: '',
            password2Valid: false
      };
      const { match: { params } } = this.props;
      this.token = params.token
      this.onChange = this.handleChange.bind(this);
      this.onChange = this.handleChangePassword.bind(this);
      this.submitForm = this.submitForm.bind(this)
      this.deactivateAccount = this.deactivateAccount.bind(this)
   }


   componentDidMount() {
      this.countryList()
      this.getuserProfileAPI()
      this.getProfilePicAPI()
   }


   handleChangePassword = e => {
      this.setState({
         [e.target.name]: e.target.value
      })

      if (e.target.name === 'new_password') {

         if (strongRegex.test(e.target.value)) {
             this.setState({
                 passwordMsg: ''
             })

         } else if (mediumRegex.test(e.target.value)) {
         }
         else if (e.target.value === '') {
             this.setState({
                 passwordMsg: 'New Password is required'
             })

         }
         else {
             console.log(e.target.value);
             this.setState({
                 passwordMsg: 'Password length must contain 8 and at least 1 lowercase, 1 special character , 1 uppercase, 1 Digit'
             })
         }
     }
     else if (e.target.name === 'confirm_password') {
         if (this.state.new_password !== e.target.value) {
             this.setState({
                 password2Valid: true
             })
         }
         else {
             this.setState({
                 password2Valid: false
             })
         }
     }
   }

   handleChange = event => {

      event.preventDefault()
      let value = event.target.value;

      this.setState(prevState => ({
         getUser: { ...prevState.getUser, [event.target.name]: value }
      }))
   }

   async getuserProfileAPI() {

      await axios({
         method: 'post',
         url: `${config.apiUrl}/getuserprofile`,
         data: { "email": this.loginData.data.user_email }
      })
         .then(result => {
            if (result.data.success === true) {
               this.setState({
                  getUser: result.data.response
               })


            }

            else if (result.data.success === false) {
               toast.error(result.data.msg, {
                  position: toast.POSITION.TOP_CENTER
               });

            }

         }).catch(err => {


         });
   }

   async countryList() {
      await axios({
         method: 'get',
         url: `${config.apiUrl}/getCountries`,
         headers: { "Authorization": this.loginData.message },
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               countrylistData: response.data?.response
            })
         }
      })
   }

   handleSubmit = event => {
      event.preventDefault();
      const { email, old_password, new_password, confirm_password } = this.state;
      const data = this.state

      axios.post(`${config.apiUrl}/changepassword`, { email: email, currentPassword: old_password, password: new_password, password2: confirm_password }, { headers })
         .then(result => {

            if (result.data.success === true) {
               toast.success(result.data.msg, {
                  position: toast.POSITION.TOP_CENTER
               });
               setTimeout(() => {
                  window.location.reload()
               }, 1000);
            }
            else if (result.data.success === false) {
               toast.error(result.data.msg, {
                  position: toast.POSITION.TOP_CENTER
               });


            }
         }).catch(err => {
            toast.error(err.response.data?.msg, {
               position: toast.POSITION.TOP_CENTER
            })
         })
   }


   //==========================  get profile pic  ================================


   async getProfilePicAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/getProfilePic`,
         headers: { "Authorization": this.loginData.message },
         data: { "email": this.state.email }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               getProfilePicAPIData: response.data?.response
            })

         }
      })
   }

   //================================================  Update information API integrate  =============
   handleImagePreview = (e) => {
      let image_as_base64 = URL.createObjectURL(e.target.files[0])
      let image_as_files = e.target.files[0];


      this.setState({
         image_preview: image_as_base64,
         image_file: image_as_files,
      })


   }

   async submitForm(e) {
      e.preventDefault()
      const formData = new FormData();
      formData.append('profile_pic', this.state.image_file);
      formData.append('old_profile_pic', this.state.getProfilePicAPIData?.profile_pic);
      formData.append('email', this.state.getUser?.email);
      formData.append('user_name', this.state.getUser?.user_name);
      formData.append('first_name', this.state.getUser?.first_name);
      formData.append('last_name', this.state.getUser?.last_name === null ? '' : this.state.getUser?.last_name);
      formData.append('address', this.state.getUser?.address);
      formData.append('house_number', this.state.getUser?.house_number);
      formData.append('city', this.state.getUser?.city);
      formData.append('country_id', this.state.getUser?.country_id);
      formData.append('phone', this.state.getUser?.phone);
      await axios({
         method: 'post',
         url: `${config.apiUrl}/updateUserprofilepic`,
         data: formData
      })
         .then(result => {
            console.log('result', result);
            if (result.data.success === true) {

               toast.success(result.data.msg, {
                  position: toast.POSITION.TOP_CENTER
               });
               this.getUserProfilePicAPI()
            }

            else if (result.data.success === false) {
               toast.error(result.data.msg, {
                  position: toast.POSITION.TOP_CENTER
               });
            }

         }).catch(err => {
            console.log(err);

         });
   }

   async deactivateAccount() {
      axios({
         method: 'post',
         url: `${config.apiUrl}/deactivate`,
         // headers: { "Authorization": this.loginData.message },
         data: { 'email': this.loginData.data.user_email }
      })
         .then(result => {
            if (result.data.success === true) {
               toast.success(result.data.msg, {
                  position: toast.POSITION.TOP_CENTER,
               })
               setTimeout(() => {

                  Cookies.remove('artfungibleUserloginSuccess')
                  window.location.href = `${config.baseUrl}`

               }, 3500);
            }

         })
   }

   loading() {
      window.location.reload()
   }

   removeCls() {
      $('.main-container').removeClass('sbar-open');
   }


   eyeClick(id) {
      if (id === 1) {
         this.setState({
            eyeold: false
         })
      }
      else if (id === 2) {
         this.setState({
            eyeold: true
         })
      }
      else if (id === 3) {
         this.setState({
            eyenew: false
         })
      }
      else if (id === 4) {
         this.setState({
            eyenew: true
         })
      }
      else if (id === 5) {
         this.setState({
            eyeconfirm: false
         })
      }
      else if (id === 6) {
         this.setState({
            eyeconfirm: true
         })
      }

   }

   // pencilClick(){
   //    this.setState({

   //    })
   // }

   render() {
      return (
         <>

            <div className="header-container fixed-top" onClick={this.removeCls.bind(this, 1)}>
               <Header2 />

            </div>
            {/* remove class --sbar-open-- */}
            <div className="main-container  sidebar-closed" id="container" onClick={this.removeCls.bind(this, 1)}>
               <div className="overlay"></div>
               <div className="cs-overlay"></div>
               <div className="search-overlay"></div>

               <InnerLeftSidebar />

               {/* <!--  END SIDEBAR  -->
         <!--  BEGIN CONTENT AREA  --> */}
               <div id="content" className="main-content" >
                  <div className="layout-px-spacing">
                     <div className="row layout-spacing pt-5 pl-2">
                        <div className="col-sm-1 col-lg-1">

                        </div>
                        {/* <!-- Content --> */}
                        <div className="col-md-10 col-lg-10 widget widget-content-area p-4">

                           <div className="user-details section ">
                              <h2 className="section-title">User Details</h2>
                              <div className="section-content">
                                 <div className="row">
                                    <div className="col-lg-12">
                                       <div className="row">
                                          <div className="col-12 col-lg-2">
                                             <div className="form-group BlockchainID">
                                                {/* <img src="mercafor_dashboard/assets/img/gas-mask.png" className="img-fluid mr-2 browser_img" style={{height:'100px',width:'100px'}} className="img-fluid mr-2 browser_img"/> */}
                                                {this.state.image_preview === '' ?
                                                   <img src={(!this.state.getProfilePicAPIData?.profile_pic || this.state.getProfilePicAPIData?.profile_pic === 'null') ? "mercafor_dashboard/assets/img/gas-mask.png" : `${config.imageUrl1}/${this.state.getProfilePicAPIData?.profile_pic}`} style={{ objectFit: 'cover', height: '100px', width: '100px' }} className="img-fluid mr-2 browser_img" />
                                                   : <img src={this.state.image_preview} className="img-fluid mr-2 browser_img" style={{ height: '100px', width: '100px', objectFit: 'covor' }} />
                                                }

                                                <a href="#javascript" className="btn btn-primary btn-browser">

                                                   <i class="fas fa-images"></i>

                                                   <input type="file" name="profile_pic" onChange={this.handleImagePreview} style={{ background: 'red', position: 'absolute', opacity: '0', width: '36px', top: '4px', left: '-1px' }} />
                                                </a>
                                             </div>
                                          </div>

                                          <div className="col-12 col-lg-4">
                                             <div className="form-group">
                                                <label for="">User Name</label>
                                                {/* <div className="field-txt" style={{cursor:'pointer'}} onClick={this.pencilClick.bind(this)}>{this.state.getUser.email}&nbsp;
                                                <i class="fa fa-pencil" aria-hidden="true"></i>

                                                </div> */}
                                                <input type="text" className="form-control input-lg" placeholder="User Name"
                                                   name="user_name" onChange={this.handleChange} value={this.state.getUser.user_name} />
                                             </div>
                                          </div>


                                          <div className="col-12 col-lg-4">
                                             <div className="form-group emailAddressField">
                                                <label for="">Email Address</label>
                                                <div className="field-txt">{this.state.getUser.email}</div>
                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <form >
                              <ToastContainer />
                              {/* <div className="personal-info section mt-4">
                                 <h2 className="section-title">Personal Info</h2>
                                 <div className="section-content">
                                    <div className="row">
                                       <div className="col-md-3 col-lg-3">
                                             <div className="form-group">
                                                <label for="">User Name</label>
                                                <input type="text" className="form-control input-lg" placeholder="User Name"
                                                   name="user_name" onChange={this.handleChange} value={this.state.getUser.user_name} />
                                             </div>
                                       </div>
                                       <div className="col-md-12 col-lg-12 mt-2">
                                            <div className="form-group emailAddressField">
                                                <label for="">Email Address</label>
                                                <div className="field-txt">{this.state.getUser.email}</div>
                                             </div>
                                       </div>
                                    </div>
                                 </div>
                              </div> */}
                              <div className="personal-info section mt-4">
                                 <h2 className="section-title">Shipping Address (optional, may be used for physical artwork delivery)</h2>
                                 <div className="section-content">
                                    <div className="row">
                                       <div className="col-md-12 col-lg-6">
                                          <div className="form-group"><label for="">Name (First and Last name)</label>
                                             <input type="text" className="form-control input-lg" placeholder="First Name" name="first_name" onChange={this.handleChange} value={this.state.getUser.first_name} /></div>
                                       </div>


                                       <div className="col-md-12 col-lg-6">
                                          <div className="form-group">
                                             <label for="datepicker">Street and House No.</label>
                                             <input type="text" className="form-control input-lg " value={this.state.getUser.house_number ? this.state.getUser.house_number : ''} onChange={this.handleChange} name="house_number" placeholder="14" /></div>

                                       </div>

                                       <div className="col-md-12 col-lg-6">
                                          <div className="form-group"><label for="">City</label><input className="form-control input-lg" placeholder="City" name="city" value={this.state.getUser?.city} onChange={this.handleChange} type="text" /></div>
                                       </div>
                                       <div className="col-md-12 col-lg-6">
                                          <div className="form-group"><label for="">Country</label>
                                             <select name="country_id" onChange={this.handleChange} value={this.state.getUser.country_id} class="form-control input-lg" style={{ display: "block!important", marginTop: '10px' }}>
                                                <option selected="selected" value="">Select Country</option>
                                                {this.state.countrylistData.map(item => (
                                                   <option value={item.id}>{item.name}</option>
                                                ))}
                                             </select>
                                          </div>
                                       </div>

                                       <div className="col-md-12 col-lg-6">
                                          <div className="form-group"><label for="">Phone Number</label><input className="form-control input-lg" placeholder="Enter Contact Number" name="phone" type="number" onChange={this.handleChange} value={this.state.getUser.phone} /></div>
                                       </div>

                                       <div className="col-md-12 col-lg-6">
                                          <div className="form-group">
                                             <label for="" className="mb-3">Address Line</label>
                                             <textarea onChange={this.handleChange} name="address" className="form-control input-lg" placeholder="address" value={this.state.getUser.address === 'null' ? '' : this.state.getUser.address}></textarea></div>
                                       </div>

                                       <div className="col-md-12 col-lg-6">

                                       </div>
                                       <div className="col-md-12 col-lg-6">
                                       </div>
                                       <div className="col-lg-3"></div>
                                       <div className="col-lg-6">
                                          <button type="submit" onClick={this.submitForm} className="btn btn-primary gradient md"><span>Update Info</span></button>
                                       </div>
                                       <div className="col-lg-10">

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </form>
                           <div className="bottomLinksOption clr">
                              <div>
                                 <div className="form-group resetPassword last-child"><a href="#reset-password" className="link" data-toggle="modal" data-target="#reset-password-modal" style={{display:'block'}}>Change Password</a></div>
                                 <div className="modal fade" id="reset-password-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                    <div className="modal-dialog" role="document">
                                       <div className="modal-content">
                                          <div className="modal-body">
                                             <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style={{ color: '#000', marginTop: '0px' }}>×</span></button>
                                             <h5 className="modal-title">Reset Your Password</h5>
                                             <p></p>
                                             <form onSubmit={this.handleSubmit}>
                                                <div className="input_row"><span className="reveal_pass">Old Password</span><input autoComplete='off' className="form-control input-lg" type={this.state.eyeold === true ? "password" : 'text'} placeholder="Old Password" name="old_password" id="password" onChange={this.handleChangePassword} value={this.state.old_password} />
                                                   {this.state.eyeold === true ?
                                                      <i class="eyeold far fa-eye" onClick={this.eyeClick.bind(this, 1)}></i> :
                                                      <i class="eyeold fas fa-eye-slash" onClick={this.eyeClick.bind(this, 2)}></i>
                                                   }
                                                </div>
                                                <div className="input_row"><span className="reveal_pass">New Password</span><input autoComplete='off' className="form-control input-lg" minLength="6" type={this.state.eyenew === true ? "password" : 'text'} placeholder="New Password" name="new_password" id="new_password" onChange={this.handleChangePassword} value={this.state.new_password} />
                                                   {this.state.eyenew === true ?
                                                      <i class="eyenew far fa-eye" onClick={this.eyeClick.bind(this, 3)}></i> :
                                                      <i class="eyenew fas fa-eye-slash" onClick={this.eyeClick.bind(this, 4)}></i>
                                                   }
                                                </div>
                                                <div style={{ color: 'red' }}>{this.state.passwordMsg ? this.state.passwordMsg : ''}</div>
                                                <div className="input_row"><span className="reveal_pass">Confirm New Password</span><input autoComplete='off' className="form-control input-lg" minLength="6" type={this.state.eyeconfirm === true ? "password" : 'text'} placeholder="Confirm New Password" id="confirm_password" name="confirm_password" onChange={this.handleChangePassword} value={this.state.confirm_password} />
                                                   {this.state.eyeconfirm === true ?
                                                      <i class="eyeconfirm far fa-eye" onClick={this.eyeClick.bind(this, 5)}></i> :
                                                      <i class="eyeconfirm fas fa-eye-slash" onClick={this.eyeClick.bind(this, 6)}></i>
                                                   }

                                                </div>
                                                <div style={{ color: 'red' }}>{this.state.password2Valid === true ? 'Password not match' : ''}</div>
                                                <div className="form-group last-child">

                                                   <button type="submit" className="btn mt-3 btn-primary custom-btn gradient md"
                                                      disabled={!this.state.old_password || !this.state.new_password || !this.state.confirm_password || this.state.passwordMsg || this.state.password2Valid} style={{ width: "100%" }}><span>Update Password</span></button>

                                                </div>
                                             </form>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-1 col-lg-1">

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      )
   }
}
