import React, { Component } from 'react';
import InnerHeader from '../directives/header1'
import Footer1 from '../directives/footer1'
import axios from 'axios';
import config from '../config/config'
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2'
// const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; // for local server
//  const TEST_SITE_KEY = "6LcbmakaAAAAAD7pW2H9OZVULdhO2M_G8YutYckO"   // for live server
// const TEST_SITE_KEY = "6LdQ8Q8cAAAAAA6VNcDP4fD7YawEYqz48lgdOKjH"   // for live server
const TEST_SITE_KEY = "6LdWKYEeAAAAAIGrwdzXvpPCYvAuJot8kpzJHVBY"   // for Org server


const headers ={
    'Content-Type' : 'application/json'
}

export default class Help_center extends Component {

  constructor(props){
    super(props)

    this.state={
        captcha_code:'',
        name : '',
        email : '',
        subject : '',
        comment : ''
    };    

    this.onChange = this.onChange.bind(this);
    this.saveContactForm = this.saveContactForm.bind(this);
  }  

  onChange(e) {
    this.setState({
        [e.target.name]: e.target.value
    })

    if(e.target.name == 'name' && e.target.value != ''){
       this.setState({ nameError : '' })
    }

    if(e.target.name == 'email' && e.target.value != ''){
      this.setState({ emailError : '' })
   }
   
   if(e.target.name == 'subject' && e.target.value != ''){
      this.setState({ subjectError : '' })
   }

   if(e.target.name == 'comment' && e.target.value != ''){
      this.setState({ commentsError : '' })
   }
   
}   

handleChange1 = value => {
    console.log("Captcha value:", value);
    this.setState({
        captcha_code:'1',
        captchaError : ''
    })
  };

componentDidMount() {

}

validate = () => {
   let nameError = "" 
   let emailError = '';
   let subjectError = '';
   let commentsError = '';
   let captchaError = '';
   if(this.state.name === ''){
      nameError = "Name field is required!"
   }
   if(this.state.email === ''){
      emailError = "Email field is required!"
   }
   if(this.state.subject === ''){
       subjectError = "Subject field is required!"
    }
    if(this.state.comment === ''){
       commentsError = "Comment field is required!"
    }

    if(this.state.captcha_code === ''){
      captchaError = "Captcha is required!"
   }    
    
  
   if(nameError || emailError || subjectError || commentsError || captchaError ){
      this.setState({
         nameError,emailError,subjectError,commentsError, captchaError
      })
      return false
   }
   return true
}

async saveContactForm(e) {
    e.preventDefault()

    const isValid = this.validate()
    if(!isValid){
    }else{
      this.setState(this.state)
      const data = this.state
      axios.post(`${config.apiUrl}/saveHelpCenterForm`, data, { headers })
         .then(response => {
          if (response.data.success === true) {
               Swal.fire({
                  icon: 'success',
                  title: '',
                  text: 'Request submitted successfully.!'
               })
               setTimeout(() => {
                     window.location.reload()
               }, 2000);
          }
          else if (response.data.success === false) {
               Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: response.data.msg
               })
           } 
         })
         .catch(err => {
          this.setState({
              buyOrdersList: []
          })
      })
    }
}  

    render(){

      return (

          <>
           <div className="body_bg">
           <InnerHeader />
  
           <section className="terms_condition contactUsPage">
           <ToastContainer />
            <div className="container">
               <div className="row">
                  <div className="col-sm-2 col-md-2 col-lg-2 "></div>
                  <div className="col-sm-8 col-md-8 col-lg-8 ">
                     <h1 className="section-title  ">Help Center</h1>
                     <p>In case you face any difficulty or inconvenience within the Artfungible platform, kindly fill in the form below, and we shall contact you shortly.</p>
                     <form onSubmit={this.saveContactForm}>
                        <div className="row">
                           <div className="col-md-6 col-lg-6">
                              <div className="form-group"><label for="">Name :</label>
                              <input type="text" onChange={this.onChange}  className="form-control input-lg" placeholder="Enter Name" name="name" />
                              <span className="error-asterick" > {this.state.nameError}</span>
                              </div>
                           </div>
                           <div className="col-md-6 col-lg-6">
                              <div className="form-group"><label for="">Email Address :</label><input type="email" onChange={this.onChange} className="form-control input-lg" placeholder="Enter Email Address" name="email"/>
                              <span className="error-asterick" > {this.state.emailError}</span>
                              </div>
                           </div>

                           <div className="col-md-12 col-lg-12">
                              <div className="form-group"><label for="">Subject :</label><input type="text" onChange={this.onChange} className="form-control input-lg" placeholder="Subject" name="subject" />
                              <span className="error-asterick" > {this.state.subjectError}</span>
                              </div>
                           </div>

                           <div className="col-md-12 col-lg-12">
                              <div className="form-group"><label for="">Comments :</label>
                              <textarea style={{background: "white"}} name="comment" id="comments" className="form-control" rows="5" onChange={this.onChange} cols="50" placeholder="Type here ..."></textarea>
                              <span className="error-asterick" > {this.state.commentsError}</span>
                              </div>
                           </div>
                           <div className="col-md-12 col-lg-12">
                              <div>
                                 <div>
                                    <div style={{width: "304px", height: "78px"}}>
                                       <div>
                                       <ReCAPTCHA
                                            style={{ display: "inline-block" }}
                                            theme="dark"
                                            ref={this._reCaptchaRef}
                                            sitekey={TEST_SITE_KEY}
                                            onChange={this.handleChange1}
                                            asyncScriptOnLoad={this.asyncScriptOnLoad}
                                        />
                                         <span className="error-asterick" > {this.state.captchaError}</span>
                                           </div>
                                       <textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response" style={{width: "250px", height: "40px", border: "1px solid rgb(193, 193, 193)", margin: "10px 25px; padding: 0px",resize: "none", display: "none"}}></textarea>
                                    </div>
                                    <iframe style={{display: "none"}}></iframe>
                                 </div>
                              </div>
                              <div className="clr formBottom">
                                 <div className="formText float-left">* All the field are necessary to fill in.</div>
                                 <button type="submit" disabled="" className="custom-btn gradient lg float-right" style={{padding: "0px 47px"}}><span> Submit </span></button>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
                   <div className="col-sm-2 col-md-2 col-lg-2 "></div>
               </div>
            </div>
         </section>

          <Footer1/>
         </div>
          </>
 )
 }
}
