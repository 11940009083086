import React, { Component } from 'react';
import config from '../config/config'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Web3 from 'web3';
import { getCurrentProvider } from '../components/provider'

const headers = {
   'Content-Type': 'application/json'
};

export default class header2 extends Component {

   constructor(props) {
      super(props)
      this.loginData = (!Cookies.get('artfungibleUserloginSuccess')) ? [] : JSON.parse(Cookies.get('artfungibleUserloginSuccess'))

      if (!this.loginData.data) {
         window.location.href = `${config.baseUrl}`
      }

      this.state = {
         getUser: '',
         getProfilePicAPIData: '',
         email: this.loginData.data.user_email,
         ConnectWalletAddress: ''
      }




   }
   componentDidMount() {
      const { ethereum } = window;
      if (ethereum) {

         ethereum.on('accountsChanged', (add) =>
            this.changeAccount(add)
         );
         ethereum.on('networkChanged', (chainId) =>
            window.location.reload()
         );
      }


      var timeCount = 0
      setInterval(() => {
         if (timeCount > 1800) {
            Cookies.remove('artfungibleUserloginSuccess')
            window.location.href = config.baseUrl
         }
         timeCount++;
      }, 1000);

      this.getuserProfileAPI()
      this.getProfilePicAPI()



      this.setState({
         ConnectWalletAddress: localStorage.getItem('ConnectWalletAddress')
      })



   }
   changeAccount(add) {
      localStorage.setItem("ConnectWalletAddress", add);
      window.location.reload()
   }
   async connectMetasmask() {
      if (window.ethereum) {

         var isMetaMask = await window.ethereum.isMetaMask;
         if (!isMetaMask) {
            toast.error(`Please install Metamask wallet to use this App!`);
            return;
         }
         const providers = await getCurrentProvider();
         const web3 = new Web3(providers);
         const accounts = await providers.request({ method: 'eth_requestAccounts' });
         toast.success(`MetaMask connected Successfully!`, {
            position: toast.POSITION.TOP_CENTER
         });
         this.setState({
            ConnectWalletAddress: accounts
         })
         localStorage.setItem('ConnectWalletAddress', accounts)

      }
      else {
         toast.error(`Please install MetaMask to use this dApp!`, {
            position: toast.POSITION.TOP_CENTER
         });
      }
   }

   async getuserProfileAPI() {

      await axios({
         method: 'post',
         url: `${config.apiUrl}/getuserprofile`,
         data: { "email": this.loginData.data.user_email }
      })
         .then(result => {
            if (result.data.success === true) {
               this.setState({
                  getUser: result.data.response
               })


            }

            else if (result.data.success === false) {
               toast.error(result.data.msg, {
                  position: toast.POSITION.TOP_CENTER
               });

            }

         }).catch(err => {


         });
   }

   //==========================  get profile pic  ================================


   async getProfilePicAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/getProfilePic`,
         headers: { "Authorization": this.loginData.message },
         data: { "email": this.state.email }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               getProfilePicAPIData: response.data?.response
            })
         }
      })
   }


   logout() {
      Cookies.remove('artfungibleUserloginSuccess')
      localStorage.setItem('ConnectWalletAddress', '')
      setTimeout(() => {
         window.location.reload()
      });
   }
   render() {
      return (

         <>

            <header className="header navbar navbar-expand-sm expand-header innerHeader">
               <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-menu">
                     <line x1="3" y1="12" x2="21" y2="12"></line>
                     <line x1="3" y1="6" x2="21" y2="6"></line>
                     <line x1="3" y1="18" x2="21" y2="18"></line>
                  </svg>
               </a>
               <ul className="navbar-item flex-row ml-auto ">
                  <li className="nav-item align-self-center menu-spc " style={{ display: 'inherit' }}>
                     <a href={`${config.baseUrl}newin`}>New in</a>
                  </li>
                  <li className="nav-item align-self-center menu-spc ">
                     <a href={`${config.baseUrl}marketplace`}> Marketplace</a>
                  </li>
                  <li className="nav-item align-self-center menu-spc ">
                     <a href=
                        {`${config.baseUrl}blog`}
                     // {`${config.baseUrl}stats`}
                     >Blog</a>
                  </li>
                  <li className="nav-item align-self-center menu-spc ">
                     <a href={`${config.baseUrl}about`}> About</a>
                  </li>

               </ul>

               <ul className="navbar-item flex-row ">

                  <li className="nav-item dropdown message-dropdown ">
                     {(this.state.ConnectWalletAddress) ?
                        <a className="nav-link" id="messageDropdown" target="_blank" href={`${config.blockchinUrl}address/${this.state.ConnectWalletAddress}`}><button className="btn btn-outline-primary btn-rounded text-white ">{this.state.ConnectWalletAddress.toString().substring(0, 4) + '...' + this.state.ConnectWalletAddress.toString().substr(this.state.ConnectWalletAddress.length - 4)}</button></a>
                        :
                        <a onClick={this.connectMetasmask.bind(this)} className="nav-link" id="messageDropdown" href="#javascript" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><button className="btn btn-outline-primary btn-rounded  " href="#javascript"> Connect Wallet</button></a>
                     }
                  </li>

                  <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
                     <a href="javascript:void(0);" className="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {/* <img src="mercafor_dashboard/assets/img/gas-mask.png" className="img-fluid btn-rounded mr-2 innerProfileHeader"  width="40px" style={{marginTop:'25px'}}/> */}
                        <img src={(!this.state.getProfilePicAPIData.profile_pic || this.state.getProfilePicAPIData.profile_pic === 'undefined' || this.state.getProfilePicAPIData.profile_pic === 'null') ? "mercafor_dashboard/assets/img/gas-mask.png" : `${config.imageUrl1}/${this.state.getProfilePicAPIData.profile_pic}`} className="img-fluid btn-rounded mr-2 innerProfileHeader" width="40px" style={{ marginTop: '25px', objectFit: 'cover' }} />
                     </a>
                     <div className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown" style={{ marginTop: '30px' }}>

                        <div className="dropdown-item padding-profile">
                           <Link onClick={this.logout.bind(this)} to={`${config.baseUrl}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-log-out">
                                 <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                 <polyline points="16 17 21 12 16 7"></polyline>
                                 <line x1="21" y1="12" x2="9" y2="12"></line>
                              </svg>
                              <span >Log Out</span>
                           </Link>
                        </div>
                     </div>
                  </li>
               </ul>
            </header>
         </>
      )
   }
}