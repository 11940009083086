import React, { Component } from 'react';
import InnerHeader from '../directives/header1'
import Footer1 from '../directives/footer1'
import axios from 'axios';
import config from '../config/config'
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import { Player } from 'video-react';
import ReactAudioPlayer from 'react-audio-player';
const MAX_LENGTH = 10;

export default class drop extends Component {

    constructor(props) {
        super(props)
        this.category = (!Cookies.get('categoryid')) ? '' : Cookies.get('categoryid')
        this.nft_type = (!Cookies.get('nftType')) ? '' : Cookies.get('nftType')



        this.state = {
            getDropListData: [],
            getAuctionNftListData: [],

        }



    }




    componentDidMount() {
        this.getDropAPI()
        this.getEthToUsd()
        this.getAuctionNftListAPI()

    }



    async getEthToUsd() {
        await axios({
            method: 'get',
            url: `https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH&Apikey=7573b3a4107a89271d7276893d1cfd63850910a324ec5136b287a0226cc906b7`,

        }).then(response => {
            this.setState({
                'UsdtoEth': response.data.ETH
            })
        })
    }


    async getAuctionNftListAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}/listitem`,
            data: { 'rarity': 0, 'trending': 0, 'topselling': 0, 'sold': 0, 'order_by': 0, 'category_id': 0, 'nftType': 0, 'owner_id': 0 }
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    getAuctionNftListData: response.data?.response.filter(item => item.is_active == 1)
                })
            }
        })
            .catch(err => {
                if (err.request) { } if (err.response) {
                    this.setState({
                        getAuctionNftListData: [],
                        falseMsg: err.response.data.msg
                    })
                }
            });
    }

    getTimeOfStartDate(dateTime) {
        var date = new Date(dateTime); // some mock date
        var milliseconds = date.getTime();
        return milliseconds;
    }

    CountdownTimer({ days, hours, minutes, seconds, completed }) {
        if (completed) {
            // Render a completed state
            return "Starting";
        } else {
            // Render a countdowns
            var dayPrint = (days > 0) ? days + 'd' : '';
            return <span>{dayPrint} {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s</span>;
        }
    };

    //========================================  Get drop  ==================================================

    async getDropAPI() {
        await axios({
            method: 'get',
            url: `${config.apiUrl}/getDrop`,
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    getDropListData: response.data?.response[0]
                })
            }
        })
            .catch(err => {
                if (err.request) { } if (err.response) {
                    this.setState({
                        getDropListData: [],
                        falseMsg: err.response.data.msg
                    })
                }
            });
    }



    render() {

        return (

            <>
                <div className="body_bg">
                    <InnerHeader />
                    <section className="product_section">

                        <div className="small-container">
                            <div className="row mt-5">

                                <div className="col-sm-12 mt-0">
                                    <br />
                                    <br />
                                    <h2>Live Auction</h2>
                                    <div className="row row-2 mt-5" >
                                        {this.state.getAuctionNftListData.length === 0 ? <div>{this.state.falseMsg}</div>
                                            :
                                            this.state.getAuctionNftListData.map(item => (

                                                <div className="col-sm-4 col-xs-6 mobile_view_col" style={{
                                                    position: 'relative', padding: "0px 19px!important",
                                                    boxShadow: "0 6px 15px -4px rgb(0 0 0 / 20%)", marginBottom: '25px'
                                                }}>
                                                    {/* {item.owner_id === 0 ?
                                                      '' : <div className="soldOut">
                                                        <img src="images/RESELL.png" />
                                                      </div>
                          
                                                    } */}

                                                    <Link to={`${config.baseUrl}marketplaceDetail/${item.id}`}>
                                                        {/* {item.sell_type == 2 && (item.expiry_date != null || item.expiry_date != '' || item.expiry_date != undefined || item.expiry_date != '0000-00-00 00:00:00') && ((new Date(item.expiry_date) < new Date())) == false ?
                                                        <div className=''>
                          
                                                          <Countdown
                                                            date={this.getTimeOfStartDate(item.expiry_date)}
                                                            renderer={this.CountdownTimer}
                                                          />
                                                        </div> :
                          
                                                        item.sell_type == 2 && ((new Date(item.expiry_date) < new Date()) == true) ?
                                                          <div className=''>Sale Ended</div> : ''
                          
                          
                                                      } */}

                                                        <Link to={item.file_type === 'video' ? '#/' : `${config.baseUrl}marketplaceDetail/${item.id}`} >
                                                            {item.file_type === 'audio' ?
                                                                <img effect="blur" src="https://ipfs.io/ipfs/QmcwrJKCnvNuxKP22TpYptN3hM76jmwL6kt4BbieBgCCba" alt="omg" /> : ''
                                                            }

                                                            {item.file_type === 'image' ?
                                                                <img effect="blur" className="marketplace-image" src={`${config.imageUrl}${item.image}`} alt="omg" /> :
                                                                item.file_type === 'video' ?
                                                                    <Player className="" src={`${config.imageUrl}${item.image}`} /> :
                                                                    <ReactAudioPlayer className="marketplace-image"
                                                                        src={`${config.imageUrl}${item.image}`}

                                                                        controls
                                                                    />
                                                            }

                                                        </Link>
                                                        {/* {item.file_type == 'image' ?
                                                        <img className="marketplace-image" src={`${config.imageUrl}/${item.image}`} alt="" />
                                                        :
                                                        <video className="marketplace-image" src={`${config.imageUrl}/${item.image}`} elevation="0" preload="auto" loop="" playsinline=""></video>
                                                      } */}
                                                        <div className="">
                                                            {/* <div className="col-12 col-md-12"> */}
                                                            <div className='row'>
                                                                <div className='col-sm-5'>

                                                                    {item.name.length > MAX_LENGTH ?
                                                                        (
                                                                            <h4 title={item.name}>
                                                                                {`${item.name.substring(0, MAX_LENGTH)}...`}
                                                                            </h4>
                                                                        ) :
                                                                        <h4 title={item.name}>{item.name}</h4>
                                                                    }


                                                                    {item.item_category_name.length > MAX_LENGTH ?
                                                                        (
                                                                            <h4 style={{ marginTop: "10px" }} title={item.item_category_name}>
                                                                                {`${item.item_category_name.substring(0, MAX_LENGTH)}...`}
                                                                            </h4>
                                                                        ) :
                                                                        <h4 style={{ marginTop: "10px" }} title={item.item_category_name}>{item.item_category_name}</h4>
                                                                    }



                                                                    {/* <p> {parseFloat(item.price).toFixed(6)} ETH
                                                          
                                                            <br /></p> */}
                                                                </div>
                                                                <div className='col-sm-7 text-right'>
                                                                    <p> {parseFloat(item.price1).toFixed(6)} ETH
                                                                        {/* <img className="rarity-icon" src={`${config.baseUrl}` + item.icon} /> */}
                                                                        <br /></p>
                                                                    {item.sell_type == 2 && (item.expiry_date != null || item.expiry_date != '' || item.expiry_date != undefined || item.expiry_date != '0000-00-00 00:00:00') && ((new Date(item.expiry_date) < new Date())) == false ?
                                                                        <div className='market-count'>
                                                                            <div className='clockImg'>    <img src='images/clock.png' /></div>
                                                                            <Countdown

                                                                                date={this.getTimeOfStartDate(item.expiry_date)}
                                                                                renderer={this.CountdownTimer}
                                                                            />
                                                                        </div> :

                                                                        item.sell_type == 2 && ((new Date(item.expiry_date) < new Date()) == true) ?
                                                                            <div className='' style={{ color: 'red' }}>Sale Ended</div> : ''


                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </div>

                                </div>
                                <div dangerouslySetInnerHTML={{ __html: this.state.getDropListData.drop_about }}></div>
                            </div>


                        </div>
                    </section>
                    <Footer1 />
                </div>
            </>
        )
    }
}