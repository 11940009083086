import React, { Component } from 'react';
import Header from './directives/header1'
import Footer1 from './directives/footer1'
import axios from 'axios';
import config from './config/config'
import Cookies from 'js-cookie';
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
const headers = {
    'Content-Type': 'application/json'
};

export default class generatepassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    componentDidMount() {

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async submitForm(e) {
        e.preventDefault()
        // this.setState(initialState)
        const { email } = this.state;
        const data = this.state
        axios.post(`${config.apiUrl}/forgot`, data, { headers }, { email })
            .then(result => {
                if (result.data.success === true) {
                    toast.success(result.data.msg, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    // setTimeout(() => {

                    //     window.location.href = `${config.baseUrl}login`
                    // }, 2000);
                }
                // `${config.baseUrl}login`
                else if (result.data.success === false) {
                    toast.error(result.data.msg, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })

            .catch(err => {
                if (err.request) { } if (err.response) {
                    toast.error(err.response.data.msg, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            });
        // .catch(err => {      
        //     alert('1')
        //     console.error(err.request.response.msg);      
        //     toast.error(err.result?.data?.msg, {
        //     position: toast.POSITION.TOP_CENTER
        //     });

        // })
    }


    render() {
        // const { email,password } = this.state;
        return (

            <>

                <div class="body_bg">
                    <section className="product_section pt-5 pb-5 mb-4">
                        <ToastContainer />
                        <Header />

                        <div className="container">
                            <div className="row  ">
                                {/* <h2>All Products</h2>  */}


                                <div className="col-sm-4">

                                </div>
                                <div className="col-sm-4 mt-5">

                                    <div className="login-wrap mt-5 py-4">
                                        <div>

                                            <h3 className="text-center tb-0" >Forgot Password</h3>
                                        </div>
                                        {/* <div className="img d-flex align-items-center justify-content-center"  ><img src="http://13.126.99.244/mercafor_crypto/img/mainlogo.png"/></div> */}



                                        <form action="#" className="login-form" onSubmit={this.submitForm}>
                                            {/* <ToastContainer/> */}
                                            {/* <div className="form-group">
         <div className="icon d-flex align-items-center justify-content-center"></div>
         <input type="password" className="form-control" placeholder="Current Password"     />
         </div> */}
                                            <p>Enter your email address below. If we have it on file, we will send you a reset email.</p>
                                            <div className="form-group">
                                                <div className="icon d-flex align-items-center justify-content-center"></div>
                                                <input type="email" className="form-control authCss" placeholder="Enter Your Email" onChange={this.onChange} name="email" value={this.state.email} />
                                            </div>


                                            <div className="form-group">

                                                {!this.state.email ?
                                                    <button type="submit" name="Request Resent Link" disabled className="btn form-control btn-primary rounded submit px-3">Request Resent Link</button>
                                                    : <button type="submit" name="Request Resent Link" className="btn form-control btn-primary rounded submit px-3">Request Resent Link</button>}
                                            </div>
                                        </form>
                                        <div className="w-100 text-center mt-4 text">

                                            <a href={`${config.baseUrl}login`}>Let's go to Sign In</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">

                                </div>


                            </div>

                        </div>



                    </section>
                </div>
                <Footer1 />
            </>
        )
    }
}
