import React, { Component } from 'react';
import Footer1 from '../directives/footer1';
import Header1 from '../directives/header1';
import axios from 'axios'
import config from '../config/config';
import moment from 'moment';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
export default class faq extends Component {
    constructor(props) {
        super(props)
        this.state = {
            setFaqData: [],
        }
    }

    async getDetailFaq() {
        await axios.get(`${config.apiUrl}/faqlist`, {},)
            .then(result => {
                if (result.data.success === true) {
                    this.setState({
                        setFaqData: result.data.response,
                    })
                }
                else if (result.data.success === false) {

                }
            })
            .catch(err => {
            })
    }

    componentDidMount() {
        this.getDetailFaq()
    }

    render() {
        return (
            <>
                <Header1 />
                <div className="container">
                    <div className='row mt-5'>
                        <h1 style={{ marginTop: '25px' }}>FAQ's</h1>
                        <div className='col-lg-12' style={{ marginTop: '25px' }}>
                            <Accordion preExpanded={['a']}>
                                {this.state.setFaqData.map(item => (
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                {item.question}

                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                {item.answer}

                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))}



                            </Accordion>
                        </div>
                    </div>
                </div>
                <Footer1 />
            </>
        );
    }
}
