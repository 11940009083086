import React, { Component } from 'react';
import Header2 from '../directives/header2'
// import Footer from '../directives/footer'
// // import axios from 'axios';
import config from '../config/config'
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery';
import axios from 'axios';
import InnerLeftSidebar from '../directives/innerLeftSidebar'
import { Link } from 'react-router-dom';
export default class viewNfttransaction extends Component {

    constructor(props) {
        super(props);

        const { match: { params } } = this.props;
        this.id = params.id

        this.loginData = (!Cookies.get('artfungibleUserloginSuccess')) ? [] : JSON.parse(Cookies.get('artfungibleUserloginSuccess'))
        if (!this.loginData.data) {
            window.location.href = `${config.baseUrl}`
        }
        this.state = {
            orderData: [],
            email: this.loginData.data.user_email,
        };
    }


    componentDidMount() {
        this.getEthToUsd()
        this.getOrderDetailsAPI()
    }


    async getOrderDetailsAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}/getOrderDetails`,
            headers: { "Authorization": this.loginData.message },
            data: { "id": this.id }
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    orderData: response.data?.data
                })
            }
        })
    }

    async getEthToUsd() {
        await axios({
           method: 'get',
           url: `https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT`,
        }).then(response => {
           this.setState({
              ethtousd: response.data.price,
              usdtoeth: 1 / response.data.price
           })
        })
     }

    removeCls() {
        $('.main-container').removeClass('sbar-open');
        $('.navbar-expand-sm').addClass('expand-header');
    }

    render() {

        return (

            <>

                <div className="header-container fixed-top" onClick={this.removeCls.bind(this, 1)}>
                    <Header2 />
                </div>

                <div className="main-container sidebar-closed sbar-open activity" id="container" onClick={this.removeCls.bind(this, 1)}>
                    <div className="overlay"></div>
                    <div className="cs-overlay"></div>
                    <div className="search-overlay"></div>
                    <InnerLeftSidebar />
                    {/* <!--  END SIDEBAR  --> */}
                    {/* <!--  BEGIN CONTENT AREA  --> */}
                    <div id="content" className="main-content">
                        <div className="layout-px-spacing">
                            <div className="row layout-spacing">
                                {/* <!-- Content --> */}
                                <div className=" col-sm-12">
                                    <div className="page-header">
                                        <div className="page-title nftTransaction" >
                                            <center><h3 className>NFT Transaction Details</h3></center>
                                        </div>
                                    </div>
                                    <div className="layout-spacing">
                                        <div className="statbox widget box box-shadow">
                                            <div className="widget-content widget-content-area pt-5 pb-5">
                                                <div className="row">
                                                    <div className="col-sm-3"></div>
                                                    <div className="col-sm-6 login_edit_detail">
                                                        <ul className="list-group task-list-group">

                                                            <li className="list-group-item list-group-item-action">
                                                                <div className="n-chk"><label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between"><span className=" col-sm-6">Product Name:</span><span className=" d-block col-sm-6">{this.state.orderData?.name}</span></label></div>
                                                            </li>

                                                            <li className="list-group-item list-group-item-action">
                                                                <div className="n-chk"><label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between"><span className=" col-sm-6">Owner:</span><span className=" d-block col-sm-6">{this.state.orderData?.owner}</span></label></div>
                                                            </li>

                                                            <li className="list-group-item list-group-item-action">
                                                                <div className="n-chk"><label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between"><span className=" col-sm-6">Price:</span><span className=" d-block col-sm-6">
                                                                {parseFloat(this.state.orderData?.price).toFixed(6)} ETH (${parseFloat(this.state.ethtousd * this.state.orderData?.price).toFixed(2)})</span></label></div>
                                                            </li>
                                                            <li className="list-group-item list-group-item-action">
                                                                <div className="n-chk"><label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between"><span className=" col-sm-6">Token ID:</span><span className=" d-block col-sm-6">{this.state.orderData?.token_id}</span></label></div>
                                                            </li>


                                                            <li className="list-group-item list-group-item-action">
                                                                <div className="n-chk"><label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between"><span className=" col-sm-6">Date:</span><span className=" d-block col-sm-6">{this.state.orderData?.datetime}</span></label></div>
                                                            </li>

                                                            <li className="list-group-item list-group-item-action active">
                                                                <div className="n-chk"><label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between"><span className=" col-sm-6">Product :</span><span className=" d-block col-sm-6">
                                                                    {this.state.orderData?.file_type == 'image' ?
                                                                        <img style={{ width: "150px" }} src={`${config.imageUrl}/${this.state.orderData?.image}`} alt="View" />
                                                                        :
                                                                        <video style={{ width: "150px" }} src={`${config.imageUrl}/${this.state.orderData?.image}`} alt="View" />
                                                                    }
                                                                </span></label></div>
                                                            </li>

                                                            <li className="list-group-item list-group-item-action active">
                                                                <div className="n-chk"><label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between"><span className=" col-sm-6">Status:</span><span className=" d-block col-sm-6">
                                                                    {(this.state.orderData?.status == 1) ? 'Completed' : 'Pending'}
                                                                </span></label></div>
                                                            </li>


                                                            <li className="list-group-item list-group-item-action">
                                                                <div className="n-chk"><label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between"><span className=" col-sm-6">Description:</span><span className=" d-block col-sm-6">{this.state.orderData?.description}</span></label></div>
                                                            </li>

                                                            <li className="list-group-item list-group-item-action">
                                                                <div className="n-chk"><label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between"><span className=" col-sm-6">Blockchain View:</span><span className=" d-block col-sm-6">
                                                                    <a href={`${config.blockchinUrl + this.state.orderData?.transfer_hash}`} rel="noopener noreferrer" target="_blank" className="link">
                                                                        <button className="btn btn-success">Blockchain View</button>
                                                                    </a>
                                                                </span></label></div>
                                                            </li>

                                                        </ul>

                                                    </div>
                                                    <div className="col-sm-3"></div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!--  END CONTENT AREA  --> */}
                </div>

            </>

        )
    }
}