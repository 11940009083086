import React, { Component } from "react";
import Footer1 from "../directives/footer1";
import Header1 from "../directives/header1";
import axios from "axios";
import config from "../config/config";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { Fade } from "react-reveal";
import { getCurrentProvider } from "../components/provider";
import Web3 from "web3";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Countdown, { zeroPad } from "react-countdown";
import { Player } from "video-react";
import moment from "moment";
export default class marketplaceNew extends Component {
  constructor(props) {
    super(props);
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));

    this.state = {
      getListItemAPIData: [],
      seoList: "",
      searchAnything: "",
      getAllData: [],
      newDateData: "",
      getAllDataPhysical: [],
      getListItemAPIDataPhysical:[]
    };

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getDate()).slice(-2);
    const hours = ("0" + currentDate.getHours()).slice(-2);
    const minutes = ("0" + currentDate.getMinutes()).slice(-2);
    const seconds = ("0" + currentDate.getSeconds()).slice(-2);

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    setTimeout(() => {
      this.setState({
        newDateData: formattedDate,
      });
    });
  }

  componentDidMount() {
    this.getListItemAPI();
    this.getListItemAPIPhysical();

    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

    this.getSeoAPI(lastSegment);
  }

  async getSeoAPI(lastSegment) {
    await axios({
      method: "post",
      url: `${config.apiUrl}/getSEO`,
      data: { pageName: lastSegment },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            seoList: response.data.data,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => {});
  }

  async getListItemAPI(item) {
    await axios({
      method: "get",
      url: `${config.apiUrl}listitemUser`,
      headers: { Authorization: this.loginData.Token },
    }).then((response) => {
      if (response.data.success === true) {
        // this.setState({
        //   getListItemAPIData: modifiedData,
        // });

        if (!item) {
          let _getListItemAPIData = [];

          response.data.response.map((item) => {
            const timezoneOffset = new Date().getTimezoneOffset() * -1;
            console.log("item.expiry_date111111111111", item.expiry_date);
            let newDate = new Date(item.expiry_date);
            let stDate = new Date(item.start_date1);

            newDate.setMinutes(newDate.getMinutes() + timezoneOffset);
            item.expiry_date = moment(newDate).format("YYYY-MM-DD HH:mm:SS"); // Add the modified date to the item
            item.start_date1 = moment(stDate).format("YYYY-MM-DD HH:mm:SS"); // Add the modified date to the item

            if (item.firstPurchase === 1) {
              _getListItemAPIData.push(item);
            }
          });

          this.setState({
            getListItemAPIData: _getListItemAPIData,
            getAllData: _getListItemAPIData,
          });
        } else if (item === 1) {
          // alert('12')

          this.setState({
            getListItemAPIData: response.data?.response.filter(
              (item) => item.firstPurchase === 1 && item.sell_type === 1
            ),
            getAllData: response.data?.response.filter(
              (item) => item.firstPurchase === 1 && item.sell_type === 1
            ),
          });
        } else if (item === 2) {
          // alert('123')

          this.setState({
            getListItemAPIData: response.data?.response.filter(
              (item) => item.firstPurchase === 1 && item.sell_type === 2
            ),
            getAllData: response.data?.response.filter(
              (item) => item.firstPurchase === 1 && item.sell_type === 2
            ),
          });
        }
      }
    });
  }
  async getListItemAPIPhysical(item) {
    await axios({
      method: "get",
      url: `${config.apiUrl}listitemUserPhysical`,
      headers: { Authorization: this.loginData.Token },
    }).then((response) => {
      if (response.data.success === true) {
        // this.setState({
        //   getListItemAPIData: modifiedData,
        // });

        if (!item) {
          let _getListItemAPIData = [];

          response.data.response.map((item) => {
            const timezoneOffset = new Date().getTimezoneOffset() * -1;
            let newDate = new Date(item.expiry_date);
            let stDate = new Date(item.start_date1);

            newDate.setMinutes(newDate.getMinutes() + timezoneOffset);
            item.expiry_date = moment(newDate).format("YYYY-MM-DD HH:mm:SS"); // Add the modified date to the item
            item.start_date1 = moment(stDate).format("YYYY-MM-DD HH:mm:SS"); // Add the modified date to the item

            if (item.firstPurchase === 1) {
              _getListItemAPIData.push(item);
            }
          });

          this.setState({
            getListItemAPIDataPhysical: _getListItemAPIData,
            getAllDataPhysical: _getListItemAPIData,
          });
        } else if (item === 1) {
          // alert('12')

          this.setState({
            getListItemAPIDataPhysical: response.data?.response.filter(
              (item) => item.firstPurchase === 1 && item.sell_type === 1
            ),
            getAllDataPhysical: response.data?.response.filter(
              (item) => item.firstPurchase === 1 && item.sell_type === 1
            ),
          });
        } else if (item === 2) {
          // alert('123')

          this.setState({
            getListItemAPIDataPhysical: response.data?.response.filter(
              (item) => item.firstPurchase === 1 && item.sell_type === 2
            ),
            getAllDataPhysical: response.data?.response.filter(
              (item) => item.firstPurchase === 1 && item.sell_type === 2
            ),
          });
        }
      }
    });
  }
  async buyNft(item) {
    try {
      if (this.loginData.length <= 0) {
        alert("Please connect wallet");
        return;
      }

      const providers = await getCurrentProvider();
      const web3 = new Web3(providers);
      let userAddress = this.loginData.data.walletAddress;

      if (item.firstPurchase === 0) {
        //first mint and then transfer
        await this.redeem(
          web3,
          item.owner_address,
          item.price,
          item.signature,
          item.contract,
          userAddress
        );
      } else {
        //directly buy from  marketplace
        await this.buyNftFunc(web3, item.contract, item.tokenID, userAddress);
      }
    } catch (error) {}
  }

  async redeem(
    web3,
    owner_address,
    itemprice,
    signature,
    contract,
    userAddress
  ) {
    try {
      let balance = await web3.eth.getBalance(userAddress);

      let artistContractObject = new web3.eth.Contract(
        config.artistAbi,
        contract
      );
      let price = itemprice * 10 ** 18;
      let getDiscountPrice = await artistContractObject.methods
        .getDiscountPrice(userAddress, price)
        .call();
      getDiscountPrice = parseInt(getDiscountPrice);
      if (balance < getDiscountPrice) {
        toast.error("Insufficient Balance");
        return;
      }

      let contractParameter = [owner_address, price, signature];

      let redeem = await artistContractObject.methods.redeem(
        userAddress,
        contractParameter
      );

      let encoded_tx = redeem.encodeABI();

      let gasPrice = await web3.eth.getGasPrice();

      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: contract,
        from: userAddress,
        data: encoded_tx,
        value: getDiscountPrice,
      });

      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: contract,
        from: userAddress,
        data: encoded_tx,
        value: getDiscountPrice,
      });
    } catch (err) {}
  }

  async buyNftFunc(web3, contract, tokenID, userAddress) {
    try {
      let balance = await web3.eth.getBalance(userAddress);

      let marketplaceContractObject = new web3.eth.Contract(
        config.abiMarketplace,
        config.marketplaceContract
      );

      const tokendetails = await marketplaceContractObject.methods
        .getTokenDetails(contract, tokenID)
        .call();
      let price = parseInt(tokendetails.price);
      if (balance < price) {
        toast.error("Insufficient Balance");
        return;
      }

      let BuyNft = await marketplaceContractObject.methods.BuyNft(
        contract,
        tokenID
      );

      let encoded_tx = BuyNft.encodeABI();

      let gasPrice = await web3.eth.getGasPrice();

      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: config.marketplaceContract,
        from: userAddress,
        data: encoded_tx,
        value: price,
      });

      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: config.marketplaceContract,
        from: userAddress,
        data: encoded_tx,
        value: price,
      });
    } catch (err) {}
  }

  refreshWindow() {
    window.location.reload();
  }

  getTimeOfStartDate(dateTime) {
    var date = new Date(dateTime); // some mock date
    var milliseconds = date.getTime();
    return milliseconds;
  }

  CountdownTimer({ days, hours, minutes, seconds, completed }) {
    if (completed) {
      // Render a completed state
      return "Starting";
    } else {
      // Render a countdowns
      var dayPrint = days > 0 ? days + "d" : "";
      return (
        <span>
          {dayPrint} {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </span>
      );
    }
  }

  PriceHeaderFilter = async (e) => {
    this.setState({ getListItemAPIData: [] });

    if (e.target.value === "Lowtohigh") {
      var lowtohigh = this.state.getListItemAPIData.sort(
        (a, b) =>
          parseFloat(a.price).toFixed(6) - parseFloat(b.price).toFixed(6)
      );
      this.setState({ getListItemAPIData: lowtohigh });
    } else if (e.target.value === "Hightolow") {
      var hightolow = this.state.getListItemAPIData.sort(
        (a, b) =>
          parseFloat(b.price).toFixed(6) - parseFloat(a.price).toFixed(6)
      );
      this.setState({ getListItemAPIData: hightolow });
    } else if (e.target.value === "newesttooldest") {
      var newesttooldest = this.state.getListItemAPIData.sort(
        (a, b) => new Date(b.datetime) - new Date(a.datetime)
      );
      this.setState({ getListItemAPIData: newesttooldest });
    } else if (e.target.value === "mostLiked") {
      var likeCount = this.state.getListItemAPIData
        .slice()
        .sort((a, b) => b.likeCount - a.likeCount);
      this.setState({ getListItemAPIData: likeCount });
    }
  };

  searchAnything = (e) => {
    const { value } = e.target;
    this.setState({ searchAnything: value });
    var regex = new RegExp(value.toUpperCase());
    const matchedData = this.state.getAllData.filter(
      (item) =>
        (item.name == null ? "" : item.name.toUpperCase().match(regex)) ||
        item.description.toUpperCase().match(regex)
    );

    if (matchedData.length > 0) {
      this.setState({ getListItemAPIData: matchedData });
    } else {
      this.setState({ getListItemAPIData: [] });
    }
  };

  render() {
    return (
      <>
        <div className="body_bg">
          <Helmet>
            <title>{this.state.seoList?.title}</title>
            <meta title={this.state.seoList?.pageTitle} content="IE=edge" />
            <meta
              name="description"
              content={this.state.seoList?.description
                ?.replace(/<[^>]*>?/gm, "")
                .substring(0, 170)}
            />
          </Helmet>
          <Header1 />

          {/*---------------------- Marketplace New Section Start -------------------- */}

          <section className="market_content py-lg-5 py-3">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-lg-7 col-md-6 col-12">
                  <h1>Marketplace</h1>
                </div>
                <div className="col-lg-5 col-md-6 col-12">
                  <div className="searchbox">
                    <span>
                      <img
                        src="mercafor_dashboard/assets/img/search.svg"
                        alt="search"
                      />
                    </span>
                    <input
                      className="form-control mr-sm-2"
                      type="search"
                      onChange={(e) => this.searchAnything(e)}
                      value={this.state.searchAnything}
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-5 col-12 pt-4">
                  <div className="filterbox ">
                    <div className="filterhead mb-4">
                      <h5>Filter</h5>
                      <div>
                        <button
                          type="button"
                          onClick={this.refreshWindow.bind(this)}
                          className="btn btn-reset-primary m-0"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    <div className="filter_inner">
                      <div id="accordion">
                        <div className="card mb-3">
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <button
                                className="btn btn-link px-1"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                {/* NFT Type <span><i className="fal fa-chevron-down"></i></span> */}
                                NFT Type{" "}
                                <span>
                                  <img
                                    src="mercafor_dashboard/assets/img/downarrow.svg
                                                                "
                                    style={{ width: "15px", height: "8px" }}
                                    className=""
                                    alt="downarrow"
                                  />
                                </span>
                              </button>
                            </h5>
                          </div>
                          <div
                            id="collapseOne"
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div className="card-body pb-0 px-2">
                              <div>
                                <form action="#">
                                  <div className="mb-2">
                                    <input
                                      type="radio"
                                      id="test1"
                                      name="radio-group"
                                      onClick={this.getListItemAPI.bind(
                                        this,
                                        1
                                      )}
                                    />
                                    <label htmlFor="test1">Fixed price</label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      type="radio"
                                      id="test2"
                                      onClick={this.getListItemAPI.bind(
                                        this,
                                        2
                                      )}
                                      name="radio-group"
                                    />
                                    <label htmlFor="test2">Auction</label>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="card mb-3">
                          <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                              <button
                                className="btn btn-link px-1 collapsed"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                NFT Type{" "}
                                <span>
                                  <img
                                    src="mercafor_dashboard/assets/img/downarrow.svg"
                                    style={{ width: "15px", height: "8px" }}
                                    className=""
                                    alt="downarrow"
                                  />
                                </span>
                              </button>
                            </h5>
                          </div>
                          <div
                            id="collapseTwo"
                            className="collapse show"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                          >
                            <div className="card-body pt-3 pb-0 px-2">
                              <ul className="unstyled centered">
                                <li>
                                  <input
                                    className="styled-checkbox"
                                    id="styled-checkbox-1"
                                    type="checkbox"
                                    defaultValue="value1"
                                  />
                                  <label htmlFor="styled-checkbox-1">
                                    Membership
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="styled-checkbox"
                                    id="styled-checkbox-2"
                                    type="checkbox"
                                    defaultValue="value2"
                                    defaultChecked=""
                                  />
                                  <label htmlFor="styled-checkbox-2">
                                    Digital Artwork
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="styled-checkbox"
                                    id="styled-checkbox-3"
                                    type="checkbox"
                                    defaultValue="value3"
                                    disabled=""
                                  />
                                  <label htmlFor="styled-checkbox-3">
                                    Physical Artwork
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="styled-checkbox"
                                    id="styled-checkbox-4"
                                    type="checkbox"
                                    defaultValue="value4"
                                  />
                                  <label htmlFor="styled-checkbox-4">
                                    Rare Wine
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card mb-3">
                          <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                              <button
                                className="btn btn-link px-1 collapsed"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                Sort{" "}
                                <span>
                                  <img
                                    src="mercafor_dashboard/assets/img/downarrow.svg"
                                    style={{ width: "15px", height: "8px" }}
                                    className=""
                                    alt="downarrow"
                                  />
                                </span>
                              </button>
                            </h5>
                          </div>
                          <div
                            id="collapseThree"
                            className="collapse"
                            aria-labelledby="headingThree"
                            data-parent="#accordion"
                          >
                            <div className="card-body pt-3 pb-0 px-2">
                              <div>
                                <form action="#">
                                  <div className="mb-2">
                                    <input
                                      type="radio"
                                      id="test1"
                                      name="radio-group"
                                      checked
                                    />
                                    <label htmlFor="test1">Fixed price</label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      type="radio"
                                      id="test2"
                                      name="radio-group"
                                    />
                                    <label htmlFor="test2">Auction</label>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-7 col-12 pt-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="main_content">
                        <div className="topprice mb-5">
                          <div className="totalitem">
                            <h4>Total items:</h4>
                            <span className="ml-2">
                              {this.state?.getListItemAPIData?.length +
                                this.state?.getListItemAPIDataPhysical?.length}
                            </span>
                          </div>
                          <div className="sizeby">
                            <span>Sort by:</span>
                            <div className="dropdown">
                              <select
                                onChange={(e) => this.PriceHeaderFilter(e)}
                                className="form-control"
                              >
                                <option value="newesttooldest">All</option>

                                <option value="Lowtohigh">
                                  Price: Low to High
                                </option>
                                <option value="Hightolow">
                                  Price: High to Low
                                </option>
                                <option value="newesttooldest">
                                  Recently Listed
                                </option>
                                <option value="mostLiked">Most Liked</option>
                              </select>

                              {/* <button
                                className="btn btn-lowest-secondary dropdown-toggle m-0"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                All{" "}
                                <span>
                                  <img
                                    src="mercafor_dashboard/assets/img/downarrow.svg"
                                    style={{ width: "12px", height: "6px" }}
                                    className=""
                                    alt="downarrow"
                                  />
                                </span>
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a className="dropdown-item border-0" href="">
                                  Price: Low to High
                                </a>
                                <a className="dropdown-item" href="">
                                  Price: High to Low
                                </a>
                                <a className="dropdown-item" href="">
                                  Recently Listed
                                </a>
                                <a className="dropdown-item" href="">
                                  Most Liked
                                </a>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {this.state?.getListItemAPIData?.length === 0 &&
                    this.state?.getListItemAPIDataPhysical?.length === 0 ? (
                      <div className="col-lg-12 col-md-12 col-12 mb-4">
                        <Fade left>
                          <div className="">
                            <div className="">
                              <div className="text-center">
                                <img src="images/noData.png" />
                                <h2>Sorry, no results found!</h2>
                                <h5> No NFT's Found For Purchase</h5>
                              </div>
                            </div>
                          </div>
                        </Fade>
                      </div>
                    ) : (
                      <>
                        {this.state?.getListItemAPIData?.map((item) => (
                          <div
                            className="col-lg-4 col-md-12 col-12 mb-4"
                            // key={item?.id}
                          >
                            <Fade left>
                              <div className="card feature">
                                {item?.is_sold === 0 ? (
                                  ""
                                ) : (
                                  <div className="soldout_box">
                                    <span>SOLD OUT!</span>
                                  </div>
                                )}
                                <div className="cardblock">
                                  <a
                                    href={`${config.baseUrl}itemactivity/${1}/${
                                      item?.id
                                    }`}
                                  >
                                    {item?.file_type === "image" ? (
                                      <img
                                        className="card-img-top img-fluid"
                                        src={config.imageUrl1 + item.image}
                                        alt="membershipimg"
                                        style={{
                                          height: "350px",
                                          width: "264px",
                                        }}
                                      />
                                    ) : item.file_type === "video" ? (
                                      <Player
                                        className="marketplace-image"
                                        style={{
                                          height: "350px",
                                          width: "264px",
                                        }}
                                        src={`${config.imageUrl1}${item?.image}`}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </a>
                                </div>
                                <div className="card-body">
                                  <p className="card-text d-flex justify-content-between align-items-center">
                                    {item?.name}
                                    <br /> Digital Artwork
                                    <span className="ehttext">
                                      {item?.sell_type === 1
                                        ? item.price
                                        : item.lastBid === 0
                                        ? item.price
                                        : (
                                            item.lastBid +
                                            (item.lastBid *
                                              item.bid_increasing_amount) /
                                              100
                                          ).toFixed(4)}{" "}
                                      {item.artistType == 0
                                        ? config.currencyType
                                        : config.currencyTypeSep}
                                    </span>
                                  </p>
                                  {item?.sell_type === 2 ? (
                                    <div className="timer2">
                                      <div>
                                        Sales End in:&nbsp;
                                        <Countdown
                                          date={this.getTimeOfStartDate(
                                            item.expiry_date
                                          )}
                                          renderer={this.CountdownTimer}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Fade>
                          </div>
                        ))}
                        {this.state?.getListItemAPIDataPhysical?.map((item) => (
                          <div
                            className="col-lg-4 col-md-12 col-12 mb-4"
                            key={item?.id}
                          >
                            <Fade left>
                              <div className="card feature">
                                {item?.is_sold === 0 ? (
                                  ""
                                ) : (
                                  <div className="soldout_box">
                                    <span>SOLD OUT!</span>
                                  </div>
                                )}
                                <div className="cardblock">
                                  <a
                                    href={`${config.baseUrl}itemactivity/${2}/${
                                      item?.id
                                    }`}
                                  >
                                    {item?.file_type === "image" ? (
                                      <img
                                        className="card-img-top img-fluid"
                                        src={config.imageUrl1 + item.image}
                                        alt="membershipimg"
                                        style={{
                                          height: "350px",
                                          width: "264px",
                                        }}
                                      />
                                    ) : item.file_type === "video" ? (
                                      <Player
                                        className="marketplace-image"
                                        style={{
                                          height: "350px",
                                          width: "264px",
                                        }}
                                        src={`${config.imageUrl1}${item?.image}`}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </a>
                                </div>
                                <div className="card-body">
                                  <p className="card-text d-flex justify-content-between align-items-center">
                                    {item?.name}
                                    <br /> Physical Artwork
                                    <span className="ehttext">
                                      {item?.sell_type === 1
                                        ? item.price
                                        : item.lastBid === 0
                                        ? item.price
                                        : (
                                            item.lastBid +
                                            (item.lastBid *
                                              item.bid_increasing_amount) /
                                              100
                                          ).toFixed(4)}{" "}
                                      {item.artistType == 0
                                        ? config.currencyType
                                        : config.currencyTypeSep}
                                    </span>
                                  </p>
                                  {item?.sell_type === 2 ? (
                                    <div className="timer2">
                                      <div>
                                        Sales End in:&nbsp;
                                        <Countdown
                                          date={this.getTimeOfStartDate(
                                            item.expiry_date
                                          )}
                                          renderer={this.CountdownTimer}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Fade>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- Marketplace New  Section End -------------------- */}

          <Footer1 />
        </div>
      </>
    );
  }
}
