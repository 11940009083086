import React, { Component } from 'react';
import InnerHeader from '../directives/header1'
import Footer1 from '../directives/footer1'
import axios from 'axios';
import config from '../config/config'
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import { Player } from 'video-react';
import ReactAudioPlayer from 'react-audio-player';
const MAX_LENGTH = 10;

export default class marketplace extends Component {

  constructor(props) {
    super(props)
    this.category = (!Cookies.get('categoryid')) ? '' : Cookies.get('categoryid')
    this.nft_type = (!Cookies.get('nftType')) ? '' : Cookies.get('nftType')


    if (!this.nft_type) {
      var nftType = '';
    } else {
      var nftType = this.nft_type
    }

    if (!this.category) {
      var categoryid = '0';
    } else {
      var categoryid = this.category
    }

    // const url = window.location.href;     
    // const lastSegment = url.split("/").pop();    

    this.state = {
      // orderby: '8',
      getListMarket: [],
      getCategoryData: [],
      getrarityname: '',
      getRarityData: [],
      trending: '0',
      topselling: '0',
      rarity: '0',
      sold: '0',
      category_id: categoryid,
      getcategoryname: '',
      one: '',
      two: '',
      three: '',
      four: '',
      five: '',
      six: '',
      seven: '',
      eight: '',
      nftType: nftType,
      falseMsg: '',
      owner_id: '',
      orderbyname: 'Unsold'
    }

    this.onChange = this.onChange.bind(this)
    this.selectCategory = this.selectCategory.bind(this)
    this.selectRarity = this.selectRarity.bind(this)
    // this.selectNftType = this.selectNftType.bind(this)

    this.removeCategory = this.removeCategory.bind(this)
    this.removeRarity = this.removeRarity.bind(this)
    this.removeNftType = this.removeNftType.bind(this)

  }

  getTimeOfStartDate(dateTime) {
    var date = new Date(dateTime); // some mock date
    var milliseconds = date.getTime();
    return milliseconds;
  }

  CountdownTimer({ days, hours, minutes, seconds, completed }) {
    if (completed) {
      // Render a completed state
      return "Sale Ended";
    } else {
      // Render a countdowns
      var dayPrint = (days > 0) ? days + 'd' : '';
      return <span>{dayPrint} {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s</span>;
    }
  };


  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })

    if (e.target.name === 'trending' && e.target.checked === true) {
      setTimeout(() => {

        this.setState({
          trending: '1'
        })
        this.getMarketPlaceAPI()
      });
    }
    else if (e.target.name === 'trending' && e.target.checked === false) {
      setTimeout(() => {

        this.setState({
          trending: '0'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'topselling' && e.target.checked === true) {
      setTimeout(() => {

        this.setState({
          topselling: '1'
        })
        this.getMarketPlaceAPI()
      });
    }
    else if (e.target.name === 'topselling' && e.target.checked === false) {
      setTimeout(() => {

        this.setState({
          topselling: '0'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'sold' && e.target.checked === true) {

      setTimeout(() => {

        this.setState({
          sold: '1'
        })
        this.getMarketPlaceAPI()
      });
    }
    else if (e.target.name === 'sold' && e.target.checked === false) {

      setTimeout(() => {

        this.setState({
          sold: '0'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'one') {
      setTimeout(() => {

        this.setState({
          orderby: '1',
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'two') {
      setTimeout(() => {

        this.setState({
          orderby: '2'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'three') {
      setTimeout(() => {

        this.setState({
          orderby: '3',
          orderbyname: 'Lowest price'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'four') {
      setTimeout(() => {

        this.setState({
          orderby: '4',
          orderbyname: 'Hightest price'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'five') {
      setTimeout(() => {

        this.setState({
          orderby: '5',
          orderbyname: 'Newest'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'six') {
      setTimeout(() => {

        this.setState({
          orderby: '6',
          orderbyname: 'Oldest'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'seven') {
      setTimeout(() => {

        this.setState({
          orderby: '7',
          orderbyname: 'Sold'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'eight') {
      setTimeout(() => {

        this.setState({
          orderby: '8',
          orderbyname: 'Unsold'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'priceTypeNft') {
      setTimeout(() => {
        this.setState({
          nftType: 1
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'auctionTypeNft') {
      setTimeout(() => {
        this.setState({
          nftType: 2
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'primary') {
      setTimeout(() => {
        this.setState({
          owner_id: '0'
        })
        this.getMarketPlaceAPI()
      });
    }

    if (e.target.name === 'secondary') {
      setTimeout(() => {
        this.setState({
          owner_id: '1'
        })
        this.getMarketPlaceAPI()
      });
    }
  }

  selectCategory(e) {
    e.preventDefault();
    this.setState({
      category_id: e.target.id
    })
    this.getCategoryNameAPI(e.target.id)
    this.getMarketPlaceAPI(e.target.id, '');
    this.getCategoryAPI();
  }

  selectRarity(e) {
    e.preventDefault();

    var data = JSON.parse(e.target.id);
    this.setState({
      rarity: data.id
    })

    this.setState({
      getrarityname: data
    })
    this.getMarketPlaceAPI('', data.id);
    this.getRarityAPI();
  }

  selectNftType(value) {
    // e.preventDefault();
    // var data = JSON.parse(e.target.id);
    // this.setState({
    //   rarity: data.id
    // })

    // this.setState({
    //   getrarityname: data
    // })
    // this.getMarketPlaceAPI('', data.id);
    // this.getRarityAPI();
  }

  componentDidMount() {
    this.getMarketPlaceAPI()
    this.getMarketPlaceAPIPhysical();
    this.getCategoryAPI()
    this.getRarityAPI();
    this.getEthToUsd()
    if (this.state.category_id > 0) {
      this.getCategoryNameAPI()
    }
  }

  //====================================== Marketplace get  =============================

  async getMarketPlaceAPI(category_id = 0, rarity = 0) {
    category_id = (!category_id) ? this.state.category_id : category_id;
    rarity = (!rarity) ? this.state.rarity : rarity;
    await axios({
      method: 'post',
      url: `${config.apiUrl}/listitem`,
      data: { 'rarity': rarity, 'trending': this.state.trending, 'topselling': this.state.topselling, 'sold': this.state.sold, 'order_by': this.state.orderby, 'category_id': category_id, 'nftType': this.state.nftType, 'owner_id': this.state.owner_id }
    }).then(response => {
      if (response.data.success === true) {
        this.setState({
          getListMarket: response.data?.response.filter((item) => item.is_active == 1)
        })

      }
    }).catch(err => {
      if (err.request) { } if (err.response) {
        this.setState({
          getListMarket: [],
          falseMsg: err.response.data.msg
        })
      }
    });
  }

  async getMarketPlaceAPIPhysical(category_id = 0, rarity = 0) {
    category_id = !category_id ? this.state.category_id : category_id;
    rarity = !rarity ? this.state.rarity : rarity;
    await axios({
      method: "post",
      url: `${config.apiUrl}/listitemPhysical`,
      data: {
        rarity: rarity,
        trending: this.state.trending,
        topselling: this.state.topselling,
        sold: this.state.sold,
        order_by: this.state.orderby,
        category_id: category_id,
        nftType: this.state.nftType,
        owner_id: this.state.owner_id,
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            getListMarketPhysical: response.data?.response.filter(
              (item) => item.is_active == 1
            ),
          });
        }
      })
      .catch((err) => {
        if (err.request) {
        }
        if (err.response) {
          this.setState({
            getListMarketPhysical: [],
            falseMsg: err.response.data.msg,
          });
        }
      });
  }

  async getCategoryAPI() {
    await axios({
      method: 'get',
      url: `${config.apiUrl}/getcategory`,
    }).then(response => {
      if (response.data.success === true) {
        this.setState({
          getCategoryData: response.data?.response
        })
      }
    })
  }

  async getRarityAPI() {
    await axios({
      method: 'get',
      url: `${config.apiUrl}/getrarity`,
    }).then(response => {
      if (response.data.success === true) {
        this.setState({
          getRarityData: response.data?.response
        })
      }
    })
  }


  async getCategoryNameAPI(category_id = 0) {
    Cookies.set('categoryid', category_id);
    category_id = (!category_id) ? this.state.category_id : category_id;
    await axios({
      method: 'post',
      url: `${config.apiUrl}/getCategoryName`,
      data: { 'category_id': category_id }
    }).then(response => {
      if (response.data.success === true) {
        this.setState({
          getcategoryname: response.data?.response
        })
      }
    })
  }

  removeRarity() {
    this.state.rarity = 0;
    this.state.getrarityname = '';
    this.getMarketPlaceAPI();
  }
  removeCategory() {
    Cookies.remove('categoryid');
    this.state.category_id = 0;
    this.state.getcategoryname = '';
    this.getMarketPlaceAPI();
  }
  removeNftType() {
    this.state.nftType = 0;
    this.getMarketPlaceAPI();
    Cookies.remove('nftType')
  }

  removesold() {
    this.state.orderbyname = ''
    this.state.orderby = 0
    this.getMarketPlaceAPI();
  }

  removeSale() {
    this.state.owner_id = ''
    this.getMarketPlaceAPI();

  }

  async getEthToUsd() {
    await axios({
      method: 'get',
      url: `https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH&Apikey=7573b3a4107a89271d7276893d1cfd63850910a324ec5136b287a0226cc906b7`,

    }).then(response => {
      this.setState({
        'UsdtoEth': response.data.ETH
      })
    })
  }


  render() {

    return (

      <>
        <div className="body_bg">
          <InnerHeader />
          <section className="product_section">

            <div className="small-container">
              <div className="row mt-5">
                <div className="col-sm-12">
                  <div className="row pt-5" id="row-width">

                    <div className="col-12 col-md-12 p-0">
                      <ul className="category-btns">
                        {this.state.getrarityname ?
                          <li>{this.state.getrarityname?.name}
                            <span onClick={this.removeRarity.bind(this)} className="close">&times;</span></li>
                          : ''}
                        {this.state.getcategoryname ?
                          <li>{this.state.getcategoryname?.name}
                            <span onClick={this.removeCategory.bind(this)} className="close">&times;</span></li> : ''}

                        {this.state.nftType ?
                          <li>{this.state.nftType == 1 ? 'Price' : 'Auction'}
                            <span onClick={this.removeNftType.bind(this)} className="close">&times;</span></li> : ''}

                        {this.state.orderby > 0 ?
                          <li>{this.state.orderbyname} <span onClick={this.removesold.bind(this)} className="close">&times;</span></li> : ''}
                        {this.state.owner_id ?
                          <li>{this.state.owner_id == '0' ? 'Primary Sale'
                            : this.state.owner_id > '0'
                              ? 'Secondary Sale' : 'Sale'
                          }<span onClick={this.removeSale.bind(this)} className="close">&times;</span></li> : ''}


                      </ul>
                    </div>

                    {/* {this.state.getcategoryname ?
                      <div className="col-12 col-md-3 p-0">
                        <ul className="category-btns">
                          <li>{this.state.getcategoryname?.name}
                            <span onClick={this.removeCategory.bind(this)} className="close">&times;</span></li>
                        </ul>
                      </div>
                      : ''}

                    {this.state.nftType ?
                      <div className="col-12 col-md-3 p-0">
                        <ul className="category-btns">
                          <li>{this.state.nftType == 1 ? 'Price' : 'Auction'}
                            <span onClick={this.removeNftType.bind(this)} className="close">&times;</span></li>
                        </ul>
                      </div>
                      : ''} */}

                    <div className="col-12 col-md-3 p-0" style={{ display: "none" }}>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value={this.state.topselling} name="topselling" onChange={this.onChange} id="flexCheckDefault" />
                        <label className="form-check-label" for="flexCheckDefault">
                          Top Selling
                        </label>
                      </div>
                    </div>

                    <div className="col-12 col-md-3 p-0" style={{ display: "none" }}>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value={this.state.sold} name="sold" onChange={this.onChange} id="flexCheckDefault" />
                        <label className="form-check-label" for="flexCheckDefault">
                          Item Sold
                        </label>
                      </div>
                    </div>

                  </div>

                </div>
                <div className="col-sm-3">
                  <div className=" mt-0">

                    <div className="dropdown">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButtons" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        NFT Type
                      </button>
                      <div className="dropdown-menu box-list-drop" id="dropdown_id" aria-labelledby="dropdownMenuButtons">
                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onChange} value={this.state.nftType} name="priceTypeNft">Fixed Price</a>
                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onChange} value={this.state.nftType} name="auctionTypeNft">Auction</a>
                      </div>
                      <div className="row" style={{ display: 'none' }}>

                        <div className="loader"></div>
                      </div>
                    </div>

                    {/* <div className="dropdown show">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButtons" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Rarity Type
                      </button>
                      <div className="dropdown-menu box-list-drop" id="dropdown_id" aria-labelledby="dropdownMenuButtons">
                        {this.state.getRarityData.map(item => (
                          (this.state.getrarityname.name != item.name) ?
                            <>
                              <li className="dropdown-item" data-toggle="dropdown" style={{ cursor: 'pointer' }} onClick={this.selectRarity} value={item.name} id={JSON.stringify(item)}>
                               
                                <br/>
                                {item.name}
                              </li>
                            </> : ''
                        ))}
                      </div>
                      <div className="row" style={{ display: 'none' }}>

                        <div className="loader"></div>
                      </div>
                    </div> */}


                    <div className="dropdown show">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButtons" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Category
                      </button>
                      <div className="dropdown-menu box-list-drop" id="dropdown_id" aria-labelledby="dropdownMenuButtons">
                        {this.state.getCategoryData.map(item => (
                          (this.state.getcategoryname.name != item.name) ?
                            <input type="button" className="dropdown-item dropdown-toggle" data-toggle="dropdown" style={{ cursor: 'pointer' }} onClick={this.selectCategory} value={item.name} id={item.id} />
                            : ''
                        ))}
                      </div>
                      <div className="row" style={{ display: 'none' }}>

                        <div className="loader"></div>
                      </div>
                    </div>


                    <div className="dropdown">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButtons" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Sort
                      </button>
                      <div className="dropdown-menu box-list-drop" id="dropdown_id" aria-labelledby="dropdownMenuButtons">
                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onChange} value={this.state.three} name="three">Lowest price</a>
                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onChange} value={this.state.four} name="four">Highest price</a>
                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onChange} value={this.state.five} name="five">Newest</a>
                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onChange} value={this.state.six} name="six">Oldest</a>
                      </div>
                      <div className="row" style={{ display: 'none' }}>

                        <div className="loader"></div>
                      </div>
                    </div>


                    <div className="dropdown">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButtons" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Sold / Unsold
                      </button>
                      <div className="dropdown-menu box-list-drop" id="dropdown_id" aria-labelledby="dropdownMenuButtons">
                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onChange} value={this.state.seven} name="seven">Sold</a>
                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onChange} value={this.state.eight} name="eight">Unsold</a>
                      </div>
                      <div className="row" style={{ display: 'none' }}>

                        <div className="loader"></div>
                      </div>
                    </div>

                    <div className="dropdown">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButtons" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Sale
                      </button>
                      <div className="dropdown-menu box-list-drop" id="dropdown_id" aria-labelledby="dropdownMenuButtons">
                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onChange} value={this.state.primary} name="primary">Primary Sale</a>
                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onChange} value={this.state.secondary} name="secondary">Secondary Sale</a>
                      </div>
                      <div className="row" style={{ display: 'none' }}>

                        <div className="loader"></div>
                      </div>
                    </div>

                  </div>

                </div>
                <div className="col-sm-9 mt-0">
                  <div className="row mt-5" >
                    {this.state.getListMarket.length === 0 ? <div>{this.state.falseMsg}</div>
                      :
                      this.state.getListMarket.map(item => (

                        <div className="col-sm-4 col-xs-6 mobile_view_col" style={{
                          position: 'relative', padding: "0px 19px!important",
                          boxShadow: "0 6px 15px -4px rgb(0 0 0 / 20%)", marginBottom: '25px'
                        }}>
                          {/* {item.owner_id === 0 ?
                            '' : <div className="soldOut">
                              <img src="images/RESELL.png" />
                            </div>

                          } */}

                          <Link to={`${config.baseUrl}marketplaceDetail/${item.id}`}>
                            {/* {item.sell_type == 2 && (item.expiry_date != null || item.expiry_date != '' || item.expiry_date != undefined || item.expiry_date != '0000-00-00 00:00:00') && ((new Date(item.expiry_date) < new Date())) == false ?
                              <div className=''>

                                <Countdown
                                  date={this.getTimeOfStartDate(item.expiry_date)}
                                  renderer={this.CountdownTimer}
                                />
                              </div> :

                              item.sell_type == 2 && ((new Date(item.expiry_date) < new Date()) == true) ?
                                <div className=''>Sale Ended</div> : ''


                            } */}

                            <Link to={item.file_type === 'video' ? '#/' : `${config.baseUrl}marketplaceDetail/${item.id}`} >
                              {item.file_type === 'audio' ?
                                <img effect="blur" src="https://ipfs.io/ipfs/QmcwrJKCnvNuxKP22TpYptN3hM76jmwL6kt4BbieBgCCba" alt="omg" /> : ''
                              }

                              {item.file_type === 'image' ?
                                <img effect="blur" className="marketplace-image" src={`${config.imageUrl}${item.image}`} alt="omg" /> :
                                item.file_type === 'video' ?
                                  <Player className="" src={`${config.imageUrl}${item.image}`} /> :
                                  <ReactAudioPlayer className="marketplace-image"
                                    src={`${config.imageUrl}${item.image}`}

                                    controls
                                  />
                              }

                            </Link>
                            {/* {item.file_type == 'image' ?
                              <img className="marketplace-image" src={`${config.imageUrl}/${item.image}`} alt="" />
                              :
                              <video className="marketplace-image" src={`${config.imageUrl}/${item.image}`} elevation="0" preload="auto" loop="" playsinline=""></video>
                            } */}
                            <div className="">
                              {/* <div className="col-12 col-md-12"> */}
                              <div className='row'>
                                <div className='col-sm-5'>
                                  {item.name.length > MAX_LENGTH ?
                                    (
                                      <h4 title={item.name}>
                                        {`${item.name.substring(0, MAX_LENGTH)}...`}
                                      </h4>
                                    ) :
                                    <h4 title={item.name}>{item.name}</h4>
                                  }


                                  {item.item_category_name.length > MAX_LENGTH ?
                                    (
                                      <h4 style={{ marginTop: "10px" }} title={item.item_category_name}>
                                        {`${item.item_category_name.substring(0, MAX_LENGTH)}...`}
                                      </h4>
                                    ) :
                                    <h4 style={{ marginTop: "10px" }} title={item.item_category_name}>{item.item_category_name}</h4>
                                  }



                                  {/* <p> {parseFloat(item.price).toFixed(6)} ETH
                                
                                  <br /></p> */}
                                </div>
                                <div className='col-sm-7 text-right'>
                                  <p>     {item?.price > item?.maxbid ? parseFloat(item?.price).toFixed(6) : parseFloat(item?.maxbid).toFixed(6)} ETH


                                    {/* <img className="rarity-icon" src={`${config.baseUrl}` + item.icon} /> */}
                                    <br /></p>
                                  {item.sell_type == 2 && (item.expiry_date != null || item.expiry_date != '' || item.expiry_date != undefined || item.expiry_date != '0000-00-00 00:00:00') && ((new Date(item.expiry_date) < new Date())) == false ?
                                    <div className='market-count'>
                                      <div className='clockImg'>    <img src='images/clock.png' /></div>
                                      <Countdown

                                        date={this.getTimeOfStartDate(item.expiry_date)}
                                        renderer={this.CountdownTimer}
                                      />
                                    </div> :

                                    item.sell_type == 2 && ((new Date(item.expiry_date) < new Date()) == true) ?
                                      <div className='' style={{ color: 'red' }}>Sale Ended</div> : ''


                                  }

                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                          </Link>
                        </div>
                      ))
                    }

                    
{this.state.getListMarketPhysical.length === 0 ? (
                      <div>{this.state.falseMsg}</div>
                    ) : (
                      this.state.getListMarketPhysical.map((item) => (
                        <div
                          className="col-sm-4 col-xs-6 mobile_view_col"
                          style={{
                            position: "relative",
                            padding: "0px 19px!important",
                            boxShadow: "0 6px 15px -4px rgb(0 0 0 / 20%)",
                            marginBottom: "25px",
                          }}
                        >
                          {/* {item.owner_id === 0 ?
                            '' : <div className="soldOut">
                              <img src="images/RESELL.png" />
                            </div>

                          } */}

                          <Link
                            to={`${config.baseUrl}marketplaceDetail/${item.id}`}
                          >
                            {/* {item.sell_type == 2 && (item.expiry_date != null || item.expiry_date != '' || item.expiry_date != undefined || item.expiry_date != '0000-00-00 00:00:00') && ((new Date(item.expiry_date) < new Date())) == false ?
                              <div className=''>

                                <Countdown
                                  date={this.getTimeOfStartDate(item.expiry_date)}
                                  renderer={this.CountdownTimer}
                                />
                              </div> :

                              item.sell_type == 2 && ((new Date(item.expiry_date) < new Date()) == true) ?
                                <div className=''>Sale Ended</div> : ''


                            } */}

                            <Link
                              to={
                                item.file_type === "video"
                                  ? "#/"
                                  : `${config.baseUrl}marketplaceDetail/${item.id}`
                              }
                            >
                              {item.file_type === "audio" ? (
                                <img
                                  effect="blur"
                                  src="https://ipfs.io/ipfs/QmcwrJKCnvNuxKP22TpYptN3hM76jmwL6kt4BbieBgCCba"
                                  alt="omg"
                                />
                              ) : (
                                ""
                              )}

                              {item.file_type === "image" ? (
                                <img
                                  effect="blur"
                                  className="marketplace-image"
                                  src={`${config.imageUrl}${item.image}`}
                                  alt="omg"
                                />
                              ) : item.file_type === "video" ? (
                                <Player
                                  className=""
                                  src={`${config.imageUrl}${item.image}`}
                                />
                              ) : (
                                <ReactAudioPlayer
                                  className="marketplace-image"
                                  src={`${config.imageUrl}${item.image}`}
                                  controls
                                />
                              )}
                            </Link>
                            {/* {item.file_type == 'image' ?
                              <img className="marketplace-image" src={`${config.imageUrl}/${item.image}`} alt="" />
                              :
                              <video className="marketplace-image" src={`${config.imageUrl}/${item.image}`} elevation="0" preload="auto" loop="" playsinline=""></video>
                            } */}
                            <div className="">
                              {/* <div className="col-12 col-md-12"> */}
                              <div className="row">
                                <div className="col-sm-5">
                                  {item.name.length > MAX_LENGTH ? (
                                    <h4 title={item.name}>
                                      {`${item.name.substring(
                                        0,
                                        MAX_LENGTH
                                      )}...`}
                                    </h4>
                                  ) : (
                                    <h4 title={item.name}>{item.name}</h4>
                                  )}

                                  {item.item_category_name.length >
                                  MAX_LENGTH ? (
                                    <h4
                                      style={{ marginTop: "10px" }}
                                      title={item.item_category_name}
                                    >
                                      {`${item.item_category_name.substring(
                                        0,
                                        MAX_LENGTH
                                      )}...`}
                                    </h4>
                                  ) : (
                                    <h4
                                      style={{ marginTop: "10px" }}
                                      title={item.item_category_name}
                                    >
                                      {item.item_category_name}
                                    </h4>
                                  )}

                                  {/* <p> {parseFloat(item.price).toFixed(6)} ETH
                                
                                  <br /></p> */}
                                </div>
                                <div className="col-sm-7 text-right">
                                  <p>
                                    {" "}
                                    {item?.price > item?.maxbid
                                      ? parseFloat(item?.price).toFixed(6)
                                      : parseFloat(item?.maxbid).toFixed(
                                          6
                                        )}{" "}
                                    ETH
                                    {/* <img className="rarity-icon" src={`${config.baseUrl}` + item.icon} /> */}
                                    <br />
                                  </p>
                                  {item.sell_type == 2 &&
                                  (item.expiry_date != null ||
                                    item.expiry_date != "" ||
                                    item.expiry_date != undefined ||
                                    item.expiry_date !=
                                      "0000-00-00 00:00:00") &&
                                  new Date(item.expiry_date) < new Date() ==
                                    false ? (
                                    <div className="market-count">
                                      <div className="clockImg">
                                        {" "}
                                        <img src="images/clock.png" />
                                      </div>
                                      <Countdown
                                        date={this.getTimeOfStartDate(
                                          item.expiry_date
                                        )}
                                        renderer={this.CountdownTimer}
                                      />
                                    </div>
                                  ) : item.sell_type == 2 &&
                                    new Date(item.expiry_date) < new Date() ==
                                      true ? (
                                    <div className="" style={{ color: "red" }}>
                                      Sale Ended
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                          </Link>
                        </div>
                      ))
                    )}
                  </div>

                </div>
              </div>


            </div>
          </section>
          <Footer1 />
        </div>
      </>
    )
  }
}