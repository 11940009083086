import React, { Component } from 'react';
import InnerHeader from '../directives/innerHeader'
import Footer1 from '../directives/footer1'

export default class Introduction extends Component {

  constructor(props){
    super(props)

  }  


    render(){

      return (

          <>
           <div className="body_bg">
           <InnerHeader/>
  
            <section className=" section_padding" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="kolectPage">
                                <div className="pageBanner">
                                <div className="container">
                                    <div className="content">
                                        <img className="img-responsive logo" src="img/kolect_logo.png" alt="" />
                                        
                                    </div>
                                </div>
                                </div>
                                
                                <div className="contentArea">
                                <div className="container">
                                    <div className="intro text-center">
                                        <h1 className="sectionTitle">What Can I Do With My Kolect (TVK)?</h1>
                                        <p className="sectionTxt">Kolect is the fuel of Mercafantasy, opening up a whole world of
                                            privileges, experiences and opportunities. We take the best of what’s out there and
                                            wrap it into one, amazing social, fan-centric ecosystem. Over time we will be
                                            rolling out features including:
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-6">
                                            <div className="txtHolder clr">
                                            <div className="img-holder"><img src="img/prestige_icon.svg" alt="" /></div>
                                            <div className="txt">
                                                <h5 className="sectionTitle">Prestige Club</h5>
                                                <p className="sectionTxt">Stake TVK to join our exclusive Mercafantasy Prestige
                                                    clubs to earn NFT airdrops, collectible rewards, previews, TVK bonuses
                                                    and extra platform features.
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-6">
                                            <div className="txtHolder clr">
                                            <div className="img-holder"><img src="img/participate_icon.svg" alt="" /></div>
                                            <div className="txt">
                                                <h5 className="sectionTitle">Rewards</h5>
                                                <p className="sectionTxt">The more you do on the platform the more you can get
                                                    rewarded. Complete competitions, quests, trade, govern and curate and
                                                    receive NFT and TVK rewards!
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-6">
                                            <div className="txtHolder clr">
                                            <div className="img-holder"><img src="img/create_icon.svg" alt="" /></div>
                                            <div className="txt">
                                                <h5 className="sectionTitle">Create</h5>
                                                <p className="sectionTxt">Craft your own digital collectibles and fan tokens
                                                    using our Terra Forma tools. Turbocharge your brand or monetise your
                                                    creations and skills on our platform.
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-6">
                                            <div className="txtHolder clr">
                                            <div className="img-holder"><img src="img/farm_icon.svg" alt="" />
                                            </div>
                                            <div className="txt">
                                                <h5 className="sectionTitle">Farm</h5>
                                                <p className="sectionTxt">Use TVK to farm exclusive, tradeable NFTs not
                                                    available anywhere else!
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 col-lg-12 text-center"><a href="#" target="_blank" className="btn custom-btn gradient lg" rel="noopener"><span >learn more</span></a>
                                        </div> */}
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
          <Footer1/>
         </div>
          </>
 )
 }
}
