import React, { Component, Fragment } from 'react';
import Web3 from 'web3';
export const getCurrentProvider = async () => {
    let CurrentProvider = "";
    
      if (!window.ethereum.providers) {
        CurrentProvider = await window.ethereum;
      } else {
        CurrentProvider = await window.ethereum.providers.find((provider) => provider.isMetaMask);
      }
    
    return CurrentProvider;
  }