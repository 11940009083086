import React from 'react';

const Modalone = () => {
    return (
        <>
            <>
                {/* Button trigger modal */}
              
            </>

        </>
    )
}

export default Modalone;
