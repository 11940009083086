import React, { Component } from 'react';
import { Modal } from "react-responsive-modal";

export default class Modalsecond extends Component {


    constructor(props) {
        super(props);
        this.state = {

            setOpen: false,
        };
    }

    async onOpenModal() {
        this.setState({
            setOpen: true,
        });
    }
    async onCloseModal() {
        this.setState({
            setOpen: false,
        });
    }


    render() {
        return (<>
            <>
                {/* Button trigger modal */}
                {/* <button
                    type="button"
                    className="btn btn-buy-primary w-100 mt-3"
                    data-toggle="modal"
                    data-target="#exampleModaltwo"
                >
                    Modal Second
                </button>


                <div
                    className="modal fade"
                    id="exampleModaltwo"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg " role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Modal Second
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='details-content'>
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">

                                                <div className="form-group">
                                                    <label >Example select</label>
                                                    <select className="form-control">
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label for="" className='mb-0'>Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        name="name"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label for="" className='mb-0'>Phone</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        name="name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label for="" className='mb-0'>City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        name="name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label for="" className=''>Example textarea</label>
                                                    <textarea class="form-control" placeholder='Enter Your Address... ' rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer mx-auto border-0">
                                <button type="submit" className="btn btn-buy-primary px-5">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}


                <button
                    type="button"
                    className="btn btn-buy-primary w-100 mt-3"
                    onClick={this.onOpenModal.bind(this)}
                >
                    Modal Second
                </button>

                <Modal
                    open={this.state.setOpen}
                    onClose={this.onCloseModal.bind(this)}
                    center
                >
                    <div className="modal-header mt-4">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Second Modal
                        </h5>
                    </div>

                    <div className="modal-body">
                        <div className='details-content'>
                            <form>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="form-group mb-4" >
                                            <label htmlFor="exampleFormControlSelect1" className='mb-2'>Country/Region</label>
                                            <select className="form-control py-2 m-0" id="exampleFormControlSelect1">
                                                <option>France</option>
                                                <option>Country name</option>
                                                <option>Country name</option>
                                                <option>Country name</option>
                                                <option>Country name</option>
                                            </select>
                                        </div>

                                        <div className="form-group mb-4">
                                            <label className='mb-2'>Full name (first name and surname)</label>
                                            <input
                                                type="text"
                                                className="form-control m-0"
                                                placeholder=""
                                            />
                                        </div>

                                        <div className="form-group mb-4">
                                            <label>Phone Number</label>
                                            <input
                                                type="number"
                                                className="form-control m-0"
                                                placeholder=""
                                            />
                                            <span className='valida-text'>May be used to assist delivery</span>
                                        </div>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Street address"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Apt, suite, unit, company name(optional)"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <label>Postcode</label>
                                            <input
                                                type="text"
                                                className="form-control m-0"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>

                                    <div className='col-6'>
                                        <div className="form-group">
                                            <label>City</label>
                                            <input
                                                type="text"
                                                className="form-control m-0"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="modal-footer border-0 footerdiv">
                        <button type="submit" className="btn btn-buy-primary px-5">
                            Submit
                        </button>
                    </div>

                </Modal>


            </>

        </>
        )
    }
}


