import React, { Component, Fragment } from 'react';
import axios from 'axios';
import config from '../config/config'

import Cookies from 'js-cookie'
import Header2 from '../directives/header2'
// import Footer from './directives/footer'
import $ from 'jquery';
import InnerLeftSidebar from '../directives/innerLeftSidebar'
import { Link } from 'react-router-dom';
// import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer, toast } from 'react-toastify';
import ReactDatatable from '@ashvin27/react-datatable';
import BarLoader from 'react-bar-loader'
import Web3 from 'web3';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Image, { Shimmer } from 'react-shimmer'
import { getCurrentProvider } from '../components/provider'
import { Dialog, Classes } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment'

const headers = {
    'Content-Type': 'application/json'
};

export default class transaction extends Component {

    constructor(props) {
        super(props)
        this.loginData = (!Cookies.get('artfungibleUserloginSuccess')) ? [] : JSON.parse(Cookies.get('artfungibleUserloginSuccess'));

        if (!this.loginData.data) {
            window.location.href = `${config.baseUrl}`
        }

        var sell_type_cookie = (!Cookies.get('sell_type')) ? '' : Cookies.get('sell_type')
        if (!sell_type_cookie) {
            sell_type_cookie = 'price'
        }
        this.updateNFTData = this.updateNFTData.bind(this)

        this.state = {
            listOrderData: [],
            ConnectWalletAddress: '',
            sell_type_cookie: sell_type_cookie,
            getId: '',
            resaleFeeInBNB: 0.00,
            resaleFeeInUSD: 0.00,
            isApproveNFT: false,
            amountError: false,
            saleError: false,
            buyerAddress: '',
            AdminWallet: '',
            approveBtnText: false,
            getUserBidsData: [],
            listOrderDataBids: [],
            bid_list: [],
            sell_type_data: '',
            minDate: '',
            allData: '',
            blockchainType: 1,
            isDialogOpen: false,
            expiry_dateDate: '',
            expiry_dateResale: '',
            dateFormat: ''
        }

        this.columns = [
            {

                key: "image",
                text: "Product image",
                sortable: true,
                cell: record => {
                    return (

                        <div>
                            <Link to={`${config.baseUrl}marketplaceDetail/${record.item_id}`} target="_blank">
                                {record.file_type == 'image' ?
                                    <img src={`${config.imageUrl}${record.image}`} className="profile-img" alt="avatar" />
                                    :
                                    <video src={`${config.imageUrl}${record.image}`} className="profile-img" alt="avatar" />
                                }
                            </Link>
                        </div >
                    );
                }
            },
            {
                key: "name",
                text: "Product Name",
                sortable: true
            },
            {
                key: "price",
                text: "Product Price",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            {parseFloat(record.price).toFixed(6)} ETH (${parseFloat(this.state.ethtousd * record?.price).toFixed(2)})
                        </Fragment>
                    );
                }
            },
            {
                key: "token_id",
                text: "Product ID",
                sortable: true
            },
            {
                key: "status",
                text: "Status",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            {(record.status == 1) ?
                                'Completed' :
                                record.status == 2 ?
                                    'Rejected' :
                                    record.status == 3 ?
                                        'Overbid'
                                        : "Pending"}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                sortable: true,
                cell: record => {

                    return (
                        <div style={{ whiteSpace: 'nowrap', padding: '10px' }}>
                            &nbsp;
                            {record.is_resale === 0 && this.loginData.data.id == record.owner_id ?
                                <a href='javascript:void(0)' rel="noopener noreferrer" className="link">

                                    {record.item_blockchain_confirmation == 0 ?
                                        <button className="btn btn-success" onClick={this.pendingTrxMSg.bind(this)}>Resale</button>
                                        :
                                        <button className="btn btn-success" data-toggle="modal" data-target="#exampleModal" onClick={this.getitemId.bind(this, record)}>Resale</button>
                                    }
                                </a> : ''}
                            {record.is_resale === 1 && this.loginData.data.id == record.owner_id && record.sell_type == 2 ?
                                <a href='javascript:void(0)' rel="noopener noreferrer" className="link">
                                    <button className="btn btn-success" data-toggle="modal" data-target="#exampleModalEdit" onClick={this.getitemId.bind(this, record)}>Edit</button>
                                </a> : ''}
                            <a href={`${config.blockchinUrl + record.transfer_hash}`} rel="noopener noreferrer" target="_blank" className="link">
                                <button className="btn btn-success">Blockchain View</button>
                            </a>
                            {record.sell_type == 1 || record.is_sold == 1 ?
                                <a href={`${config.baseUrl}viewNfttransaction/` + record.id} rel="noopener noreferrer" className="link">
                                    <button className="btn btn-success">View</button>
                                </a>
                                :
                                <a href={`${config.baseUrl}viewBidtransaction/` + record.id} rel="noopener noreferrer" className="link">
                                    <button className="btn btn-success">View</button>
                                </a>
                                // <a href={`${config.baseUrl}vi&& this.state.record.confirmedHash ewBidtransaction/` + record.id} rel="noopener noreferrer" className="link">
                                //     <button className="btn btn-success">View</button>
                                // </a>


                            }
                        </div>
                    );
                }
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false
            }
        }

        //==================================================== View bids =============================

        this.columns1 = [
            {

                key: "image",
                text: "Product image",
                sortable: true,
                cell: record => {
                    return (
                        <div>
                            <Link to={`${config.baseUrl}marketplaceDetail/${record.item_id}`} target="_blank">
                                {record.file_type == 'image' ?
                                    <img src={`${config.imageUrl}${record.image}`} className="profile-img" alt="avatar" />
                                    :
                                    <video src={`${config.imageUrl}${record.image}`} className="profile-img" alt="avatar" />
                                }
                            </Link>
                        </div >
                    );
                }
            },
            {
                key: "name",
                text: "Product Name",
                sortable: true
            },
            {
                key: "price",
                text: "Product Price",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            {parseFloat(record.price).toFixed(6)} ETH (${parseFloat(this.state.ethtousd * record?.price).toFixed(2)})
                        </Fragment>
                    );
                }
            },
            {
                key: "datetime",
                text: "Date",
                sortable: true
            },

            {
                key: "action",
                text: "Action",
                sortable: true,
                cell: record => {
                    return (
                        <div style={{ whiteSpace: 'nowrap', padding: '10px' }}>

                            <button type='submit' className='btn btn-success' onClick={this.viewBidAPI.bind(this, record)} data-toggle="modal" data-target="#viewBid">
                                View Bids
                            </button>


                        </div>
                    );
                }
            },
        ];

        this.config1 = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false
            }
        }

        this.onChange = this.onChange.bind(this)
        this.onChangeResale = this.onChangeResale.bind(this)
        this.DateTimePickerSelect = this.DateTimePickerSelect.bind(this);

    }
    componentDidMount() {
        this.getEthToUsd()
        this.livePriceAPI11()
        this.getAdminWalletDetails()
        this.getBidListAPI()
        setTimeout(() => {
            if (this.state.sell_type_cookie == 'price') {
                this.getOrders('0')
            } else if (this.state.sell_type_cookie == 'auction') {
                this.getMyBids('0')
            }
            else if (this.state.sell_type_cookie == 'bidsMy') {
                this.getMyBids('0')
            }
        }, 500);
        let yourDate = new Date()
        let minDate = yourDate.toISOString().split('T')[0]
        // let abc = new Date()
        this.setState({
            minDate: minDate
        })
    }


    DateTimePickerSelect(e) {
        let d = e.value;
        this.setState({
            [e.name]: d
        })

    }

    async getEthToUsd() {
        await axios({
            method: 'get',
            url: `https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT`,
        }).then(response => {
            this.setState({
                ethtousd: response.data.price,
                usdtoeth: 1 / response.data.price
            })
        })
    }


    onChange = e => {
        if (e.target.name == 'amount') {
            this.setState({
                amountError: false
            })
        }
        if (e.target.name == 'expiry_date') {
            this.setState({
                saleError: false
            })
        }

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async livePriceAPI11() {
        let livePriceAPI = await axios({ url: 'https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH' });
        var usdInEth = livePriceAPI.data.ETH

        this.setState({
            resaleFeeInBNB: (parseFloat(usdInEth) * parseFloat(this.state.resaleFeeInUSD)).toFixed(6)
        })
    }


    async getOrders(type) {

        this.setState({
            sell_type_cookie: 'price'
        })
        await axios({
            method: 'post',
            url: `${config.apiUrl}/getUserOrder`,
            //  headers: { "Authorization": this.loginData.message },
            data: { "filter_type": type, "user_id": this.loginData.data.id }
        }).then(async response => {
            if (response.data.success === true) {
                Cookies.remove('sell_type');
                var mainData = response.data?.data;
                var aa = mainData.filter(item => item.is_resale === 0 && this.loginData.data.id === item.owner_id);

                this.setState({
                    listOrderData: mainData
                })
                for (let i = 0; i < aa.length; i++) {
                    let hash = aa[i].transfer_hash;
                    let res = await axios.get(`${config.apiUrl}/approveTrxConfirm/${hash}`, {},);
                    if (res.data.success == false) {
                        var ind = mainData.findIndex(item => item.transfer_hash == hash);
                        mainData[ind].process = true;
                    }
                }
            }
        })
    }

    //=======================================  Bid details  =====================

    async getBidListAPI() {
        await axios({
            method: 'post',
            url: `${config.apiUrl}/myBidItem`,
            data: { "user_id": this.loginData?.data?.id }
        })
            .then(result => {
                if (result.data.success === true) {
                    this.setState({
                        getUserBidsData: result.data.response,

                    })

                }
                else if (result.data.success === false) {
                }
            }).catch(err => {
            });
    }

    async getMyBids(type) {
        this.setState({
            sell_type_cookie: 'auction'
        })
        await axios({
            method: 'post',
            url: `${config.apiUrl}/getMybidsAPI`,
            data: { "user_id": this.loginData.data.id }
        }).then(response => {
            if (response.data.success === true) {
                Cookies.remove('sell_type')
                this.setState({
                    listOrderData: response.data?.data
                })
            }
        })
    }

    async getBidsonmyitem(type) {
        this.setState({
            sell_type_cookie: 'bidsMy'
        })
        await axios({
            method: 'post',
            url: `${config.apiUrl}/myBidItem`,
            data: { "user_id": this.loginData.data.id }
        }).then(response => {
            if (response.data.success === true) {
                Cookies.remove('sell_type')

                this.setState({
                    listOrderDataBids: response.data?.response,
                })

            }
        })
    }



    removeCls() {
        $('.main-container').removeClass('sbar-open');
    }
    pendingTrxMSg() {
        toast.error(`Transaction are pending, please wait...`, {
            position: toast.POSITION.TOP_CENTER
        });
        return;
    }
    getitemId(id) {
        console.log(id);
        this.setState({
            allData: id,
            getId: id.token_id,
            selectItem: id.item_id,
            buyerAddress: id.buyerAddress,
            sell_type_data: id.sell_type,
            start_dateDate: moment(id.start_date).format('YYYY-MM-DD HH:mm:SS'),
            expiry_date: moment(id.expiry_date).format('YYYY-MM-DD HH:mm:SS'),
            dateFormat: moment(id.expiry_date).subtract({ hours: 5, minutes: 30 }),
        })
    }

    // async approveNFT() {
    //     var tokenID = this.state.getId;
    //     let fromAddress = "0x150bde4355801e95d0aad21a5587dc2e6e6785be"; // get buyeraddress from order table
    //     let to_address = "0x7845337716B6BDA8dDad1d0480Dcdd8bdeB1441F"; // get admin address
    //     let contractAddress = config.contractAdd;

    //     var web3 = new Web3(window.ethereum);
    //     var currentNetwork = web3.currentProvider.chainId;

    //     //  mainnet = 0x1
    //     //  testnet = 0x4
    //     if (currentNetwork !== '0x1') {
    //       toast.error(`Please select network !`);
    //       return 0;
    //     }
    //     try {
    //       var chainId = currentNetwork;
    //       const contract = await new web3.eth.Contract(config.abi, contractAddress);
    //       let count = await web3.eth.getTransactionCount(fromAddress);
    //       web3.eth.defaultAccount = fromAddress;
    //       const tx_builder = await contract.methods.approve(to_address, tokenID);
    //       let encoded_tx = tx_builder.encodeABI();
    //       let gasPrice = await web3.eth.getGasPrice();
    // gasPrice = parseInt(gasPrice)+200000000;
    //       let gasLimit = await web3.eth.estimateGas({
    //         from: fromAddress,
    //         nonce: web3.utils.toHex(count),
    //         gasPrice: web3.utils.toHex(gasPrice),
    //         to: contractAddress,
    //         data: encoded_tx,
    //         chainId: chainId,
    //       });

    //       const txData = await web3.eth.sendTransaction({
    //         nonce: web3.utils.toHex(count),
    //         from: fromAddress,
    //         gasPrice: web3.utils.toHex(gasPrice),
    //         gasLimit: web3.utils.toHex(gasLimit),
    //         to: contractAddress,
    //         data: encoded_tx,
    //         value: 0,
    //         chainId: chainId,
    //       });

    //       

    //       if (txData.transactionHash) {
    //         return 1
    //       } else {
    //         toast.error(`Approval failed please try again!!!`);
    //         return 0
    //       }
    //     } catch (e) {
    //       toast.error(e.message);
    //       return 0
    //     }
    //   } 

    async getAdminWalletDetails() {
        await axios({
            method: 'get',
            url: `${config.apiUrl}/getWalletDetails`,
        }).then(response => {
            if (response.data.success === true) {
                this.setState({
                    AdminWallet: response.data?.response
                })

            }
        })
    }

    async approveNFT() {
        if (!this.state.amount) {
            this.setState({
                amountError: true
            });
            return false;
        }
        if (this.state.sell_type_data === 2) {

            if (!this.state.expiry_date) {
                this.setState({
                    saleError: true
                });
                return false;
            }
        }
        var tokenID = this.state.getId;

        let fromAddress = this.state.buyerAddress; // get buyeraddress from order table
        let to_address = this.state.AdminWallet.admin_address; // get admin address
        let contractAddress = config.contractAdd;

        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);

        var currentNetwork = web3.currentProvider.chainId;
        if (currentNetwork !== '0x1') {
            toast.error(`Please select eth testnet network !`, {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }

        try {
            this.setState({
                approveBtnText: true
            })
            var chainId = currentNetwork;

            const contract = await new web3.eth.Contract(config.abi, contractAddress);
            let count = await web3.eth.getTransactionCount(fromAddress);

            web3.eth.defaultAccount = fromAddress;

            const tx_builder = await contract.methods.approve(to_address, tokenID);


            let encoded_tx = await tx_builder.encodeABI();


            let gasPrice = await web3.eth.getGasPrice();
            gasPrice = await parseInt(gasPrice) + parseInt(10000000000);
            let gasLimit = await web3.eth.estimateGas({
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                to: contractAddress,
                data: encoded_tx,
                chainId: chainId,
                value: 0,
            });





            const txData = await web3.eth.sendTransaction({
                nonce: web3.utils.toHex(count),
                from: fromAddress,
                gasPrice: web3.utils.toHex(gasPrice),
                gasLimit: web3.utils.toHex(gasLimit),
                to: contractAddress,
                data: encoded_tx,
                value: 0,
                chainId: chainId,
            });

            if (txData.transactionHash) {
                this.setState({
                    isApproveNFT: true,
                    approveBtnText: false
                })

            } else {
                toast.error(`${txData.message}`);
                this.setState({
                    approveBtnText: true
                })
            }


        } catch (e) {

            toast.error(e.message, {
                position: toast.POSITION.TOP_CENTER
            });

            this.setState({
                approveBtnText: false
            })
        }


    }

    async resalePayment11() {
        if (!this.state.amount) {
            this.setState({
                amountError: true
            });
            return false;
        }

        this.setState({
            resaleBtnText: true
        })

        let fromAddress = "0x150bde4355801e95d0aad21a5587dc2e6e6785be"; // get buyeraddress from order table
        let to_address = "0x7845337716B6BDA8dDad1d0480Dcdd8bdeB1441F"; // get admin address


        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);

        var currentNetwork = web3.currentProvider.chainId;
        if (currentNetwork !== '0x1') {
            toast.error(`Please select eth testnet network !`, {
                position: toast.POSITION.TOP_CENTER
            });
            this.setState({
                resaleBtnText: false
            })
            return false;
        }

        try {

            var chainId = currentNetwork;

            let count = await web3.eth.getTransactionCount(fromAddress);

            web3.eth.defaultAccount = fromAddress;
            let gasPrice = await web3.eth.getGasPrice();
            gasPrice = await parseInt(gasPrice) + parseInt(10000000000);
            let gasLimit = await web3.eth.estimateGas({
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                to: to_address,
                value: web3.utils.toHex(parseInt(parseFloat(this.state.resaleFeeInBNB) * 10 ** 18)),
                chainId: chainId,
            });




            const txData = await web3.eth.sendTransaction({
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                to: to_address,
                value: web3.utils.toHex(parseInt(parseFloat(this.state.resaleFeeInBNB) * 10 ** 18)),
                chainId: chainId,
            });

            if (txData.transactionHash) {
                this.resaleApi();
            } else {
                toast.error(`${txData.message}`);
                this.setState({
                    resaleBtnText: true
                })
            }


        } catch (e) {

            toast.error(e.message, {
                position: toast.POSITION.TOP_CENTER
            });

            this.setState({
                resaleBtnText: false
            })
        }
    }


    async resalePayment() {

        await axios({
            method: 'post',
            url: `${config.apiUrl}/resaleItem`,
            //  headers: { "Authorization": this.loginData.message },
            data: { "id": this.state.selectItem, "user_id": this.loginData.data.id, "amount": this.state.allData.price, "expiry_date": this.state.allData.expiry_date }
        }).then(async response => {
            if (response.data.success === true) {
                await Swal.fire({
                    icon: 'success',
                    title: 'Congratulations',
                    text: response.data.msg
                })
                setTimeout(() => {
                    window.location.reload();
                }, 200);

            } else {
                toast.error(`${response.data.msg}`);
                setTimeout(() => {
                    window.location.reload();
                }, 200);

            }
        })

    }


    async viewBidAPI(id) {

        axios.post(`${config.apiUrl}/getBidDetail`, { 'item_id': id.id, 'owner_id': id.owner_id },)
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        bid_list: response.data.response
                    })
                }

                else if (response.data.success === false) {

                }
            })
            .catch(err => {
                this.setState({
                    bid_list: []
                })
            })
    }

    async BidAcceptAPI(itemData) {
        console.log(itemData);
        let tokenId = itemData.token_id;
        if (window.ethereum) {

            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let web3 = new Web3(window.ethereum);

            var chainId = '0x1';

            try {
                this.setState({
                    processBtn: 1,
                    isDialogOpen: true
                })
                let contractAddress = `${config.marketplaceContract}`
                let from_address = accounts[0];

                const contract = await new web3.eth.Contract(config.abiMarketplace, contractAddress);
                let tx_builder = await contract.methods.acceptBid(tokenId.toString());

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();
                gasPrice = await parseInt(gasPrice) + parseInt(10000000000);
                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    chainId: chainId,
                    data: encoded_tx
                });

                // const txData = await web3.eth.sendTransaction({
                //     gasPrice: web3.utils.toHex(gasPrice),
                //     gas: web3.utils.toHex(gasLimit),
                //     to: contractAddress,
                //     from: from_address,
                //     chainId: chainId,
                //     data: encoded_tx
                // });

                const txData = await web3.currentProvider.request({
                    method: 'eth_sendTransaction',
                    params: [{
                        gasPrice: web3.utils.toHex(gasPrice),
                        gas: web3.utils.toHex(gasLimit),
                        to: contractAddress,
                        from: from_address,
                        chainId: chainId,
                        data: encoded_tx
                    }],
                });

                if (txData) {
                    const token = this.token
                    axios({
                        method: 'post',
                        url: `${config.apiUrl}/bidAccept`,
                        headers: { authorization: token },
                        data: { "user_id": itemData.user_id, "user_name": itemData.user_name, "address": from_address, 'item_id': itemData.item_id, "bid_id": itemData.bid_id, "hash": txData }
                    })
                        .then(async response => {
                            this.setState({
                                processBtn: 0,
                                isDialogOpen: false
                            })
                            if (response.data.success === true) {
                                await Swal.fire({
                                    icon: 'success',
                                    title: 'Congratulations',
                                    text: response.data.msg
                                })
                                window.location.href = `${config.baseUrl}` + "transaction"
                                this.setState({
                                    processBtn: 0,
                                    isDialogOpen: false
                                })
                            }
                            else if (response.data.success === false) {
                                this.setState({
                                    processBtn: 0,
                                    isDialogOpen: false
                                })
                                toast.error(response.data?.msg, {});
                            }
                        })
                        .catch(err => {
                            this.setState({
                                processBtn: 0,
                                isDialogOpen: false
                            })
                            toast.error(err.response.data?.msg, {});
                        })
                    // window.location.reload();
                } else {
                    toast.error('Something went wrong please try again3.');
                    this.setState({
                        processBtn: 0,
                        isDialogOpen: false
                    })
                    return false;
                }

            } catch (err) {

                if (err.message.toString().split('insufficient funds')[1]) {

                    toast.error('Transaction reverted : ' + err.message)

                } else {

                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                    } else {
                        toast.error(err.message);
                    }
                }
                this.setState({
                    processBtn: 0,
                    isDialogOpen: false
                })
                return false;
            }

        } else {
            toast.error('Please Connect to MetaMask.');
            this.setState({
                processBtn: 0,
                isDialogOpen: false
            })
            return false;
        }
    }





    onChangeResale = event => {
        event.preventDefault()
        let value = event.target.value;
        this.setState(prevState => ({
            allData: { ...prevState.allData, [event.target.name]: value }
        }))
    }



    async updateNFT(itemDetails) {

        if (window.ethereum) {
            this.setState({
                spinLoader: '1',
                isDialogOpen: true
            })

            var web3 = '';
            web3 = new Web3(window.ethereum);
            const accounts = await web3.eth.getAccounts();
            let currentNetwork = await web3.currentProvider.chainId;
            web3.eth.defaultAccount = accounts[0];

            // var ETH_mainnet = 0x1;
            // var ETH_testnet = 0x4;

            // let matic_mainnet = 137;
            // let matic_testnet = 80001;

            if (this.state.blockchainType == 1) {
                if (currentNetwork !== '0x1') {
                    toast.error('Please Select Ethereum Network');
                    this.setState({
                        spinLoader: '0',
                        isDialogOpen: false
                    })
                    return false;
                }
                var chainId = '0x1';
            }

            if (this.state.blockchainType == 2) {
                toast.error('In Progress');
                return;
            }

            try {
                let mintFee = 0;
                let SalePrice;
                let start_date = 0;
                let expiry_date = 0;
                let tokenId = this.state.allData.token_id;

                if (this.state.allData.sell_type == 1) {
                    SalePrice = parseInt(parseFloat(this.state.allData?.price) * (10 ** 18)).toString()
                    this.setState({
                        start_date: 0,
                        expiry_date: 0
                    })
                }

                else if (this.state.allData.sell_type == 2) {
                    SalePrice = parseInt(parseFloat(this.state.allData?.price) * (10 ** 18)).toString();
                    start_date = Math.round(new Date(this.state.start_date).getTime() / 1000);
                    expiry_date = Math.round(new Date(this.state.expiry_dateResale).getTime() / 1000);
                }

                console.log(">>>>>>>>", this.state.allData.sell_type, this.state.allData?.price, this.state.start_date, this.state.expiry_date);

                let contractAddress = `${config.marketplaceContract}`
                let from_address = accounts[0];
                const contract = await new web3.eth.Contract(config.abiMarketplace, contractAddress);

                await contract.methods.updateDetails(tokenId.toString(), SalePrice.toString(), this.state.allData.sell_type.toString(), start_date.toString(), expiry_date.toString()).call();

                let tx_builder = await contract.methods.updateDetails(tokenId.toString(), SalePrice.toString(), this.state.allData.sell_type.toString(), start_date.toString(), expiry_date.toString());

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();
                gasPrice = await parseInt(gasPrice) + parseInt(10000000000);

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    value: web3.utils.toHex(mintFee),
                    chainId: chainId,
                    data: encoded_tx
                });

                // const txData = await web3.eth.sendTransaction({
                //     gasPrice: web3.utils.toHex(gasPrice),
                //     gas: web3.utils.toHex(gasLimit),
                //     to: contractAddress,
                //     from: from_address,
                //     value: web3.utils.toHex(mintFee),
                //     chainId: chainId,
                //     data: encoded_tx
                // });
                const txData = await web3.currentProvider.request({
                    method: 'eth_sendTransaction',
                    params: [{
                        gasPrice: web3.utils.toHex(gasPrice),
                        gas: web3.utils.toHex(gasLimit),
                        to: contractAddress,
                        from: from_address,
                        value: web3.utils.toHex(mintFee),
                        chainId: chainId,
                        data: encoded_tx
                    }],
                });

                if (txData) {
                    return txData;
                    // let mintRes = {
                    //   'ImageFileHash': ImageFileHash,
                    //   'MetadataFileHash': metaHash,
                    //   'hash': txData.transactionHash,
                    //   'tokenId': tokenId,
                    //   'from_address': from_address,
                    //   'minting_fee': parseInt(mintFee) / 10 ** 18
                    // }
                    // this.createNftAPI(mintRes);
                } else {
                    toast.error('Something went wrong please try again.3');
                    this.setState({
                        spinLoader: '0',
                        isDialogOpen: false
                    })
                    return false;
                }

            } catch (err) {

                if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                    } else {
                        toast.error(err.message);
                    }
                }

                this.setState({
                    spinLoader: 0,
                    isDialogOpen: false
                })
                return false;
            }
        } else {
            toast.error('Please Connect to MetaMask.');
            this.setState({
                spinLoader: 0,
                isDialogOpen: false
            })
            return false;
        }

    }

    async approveNFT(itemDetails) {
        let tokenId = this.state.allData.token_id;
        if (window.ethereum) {
            let web3 = new Web3(window.ethereum);
            let currentNetwork = web3.currentProvider.chainId;
            // var ETH_mainnet = 0x1;
            // var ETH_testnet = 0x4;

            // let matic_mainnet = 137;
            // let matic_testnet = 80001;

            if (currentNetwork !== '0x1') {
                toast.error('Please Select Ethereum Network');
                this.setState({
                    spinLoader: '0',
                    isPutonSale: 1,
                    isDialogOpen: false
                })
                return false;
            }
            var chainId = '0x1';
            try {

                let contractAddress = `${config.marketplaceContract}`
                const accounts = await web3.eth.getAccounts();
                const from_address = accounts[0];
                const contract = await new web3.eth.Contract(config.abiMarketplace, contractAddress);
                let tx_builder = await contract.methods.approve(config.marketplaceContract, tokenId.toString());

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();
                gasPrice = await parseInt(gasPrice) + parseInt(10000000000);
                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    chainId: chainId,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gas: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    chainId: chainId,
                    data: encoded_tx
                });

                if (txData.transactionHash) {
                    console.log(txData.transactionHash);
                    return true
                } else {
                    toast.error('Something went wrong please try again.1');
                    this.setState({
                        spinLoader: '0',
                        isPutonSale: 1,
                        isDialogOpen: false
                    })
                    return false;
                }

            } catch (error) {
                toast.error('Something went wrong please try again.2');
                this.setState({
                    spinLoader: '0',
                    isPutonSale: 1,
                    isDialogOpen: false
                })
                return false;
            }
        } else {
            toast.error('Please Connect to MetaMask.');
            this.setState({
                spinLoader: '0',
                isPutonSale: 1,
                isDialogOpen: false
            })
            return false;
        }
    }


    async putOnSaleAPI() {
        this.setState({
            spinLoader: '1',
            isPutonSale: 0,
            isDialogOpen: true
        })
        let updateNFTRes = await this.updateNFT();
        if (updateNFTRes) {
            // let approveNFTRes = await this.approveNFT();
            // if (approveNFTRes) {
            const token = this.token
            await axios({
                method: 'post',
                url: `${config.apiUrl}/resaleItem`,
                headers: { authorization: token },
                data: { "id": this.state.selectItem, "user_id": this.loginData.data.id, "amount": this.state.allData.price, "expiry_date": moment(this.state.expiry_dateResale).format('YYYY-MM-DD HH:mm'), "start_date": moment(this.state.start_date).format('YYYY-MM-DD HH:mm'), "sell_type": this.state.allData.sell_type, "hash": updateNFTRes,"increasing_amount":this.state.increasing_amount }
            }).then(async res => {
                this.setState({
                    isDialogOpen: false
                })
                if (res.data.success === true) {


                    await Swal.fire({
                        icon: 'success',
                        title: 'Congratulations',
                        text: res.data.msg
                    })
                    window.location.href = `${config.baseUrl}` + "marketplace"
                } else {
                    toast.error(res.data.msg, {});
                }
            }).catch((error) => {
                toast.error(error.response?.data?.msg, {});
            })
        } else {
            this.setState({
                spinLoader: '0',
                isPutonSale: 1,
                isDialogOpen: false
            })
        }
        // } else {
        //     this.setState({
        //         spinLoader: '0',
        //         isPutonSale: 1,
        //         isDialogOpen: false
        //     })
        // }
    }


    //=============================================  Update only Expiry Date ======================

    async updateNFTData(e) {
        e.preventDefault()
        // alert(this.state.price)
        // alert(this.state.token_id)
        // alert('444')
        // let start_date_string = new Date(this.state.start_dateDate).getTime()/1000
        // let end_date_string = new Date(this.state.expiry_dateDate).getTime()/1000

        // alert(start_date_string)
        // console.log('Datsaaaa', this.state.getId.toString(), this.state.allData.price.toString(), this.state.sell_type_data.toString(), start_date_string.toString(), end_date_string.toString());
        if (window.ethereum) {
            this.setState({
                spinLoader: '1',
                isDialogOpen: true
            })

            var web3 = '';
            web3 = new Web3(window.ethereum);
            const accounts = await web3.eth.getAccounts();
            let currentNetwork = await web3.currentProvider.chainId;
            web3.eth.defaultAccount = accounts[0];

            // var ETH_mainnet = 0x1;
            // var ETH_testnet = 0x4;

            // let matic_mainnet = 137;
            // let matic_testnet = 80001;

            if (this.state.blockchainType == 1) {
                if (currentNetwork !== '0x1') {
                    toast.error('Please select ETH Network!!');
                    this.setState({
                        spinLoader: '0',
                        isDialogOpen: false
                    })
                    return false;
                }
                var chainId = '0x1';
            }

            if (this.state.blockchainType == 2) {
                toast.error('In Progress');
                return;
            }

            try {
                let mintFee = 0;
                let SalePrice;
                let start_date = 0;
                let expiry_date = 0;
                let tokenId = this.state.token_id;

                if (this.state.sell_type_data == 1) {
                    SalePrice = parseInt(parseFloat(this.state.allData.price) * (10 ** 18)).toString()
                    this.setState({
                        start_dateDate: 0,
                        expiry_dateDate: 0
                    })
                }

                else if (this.state.sell_type_data == 2) {
                    SalePrice = parseInt(parseFloat(this.state.allData.price) * (10 ** 18)).toString();
                    start_date = Math.round(new Date(this.state.start_dateDate).getTime() / 1000);
                    expiry_date = Math.round(new Date(this.state.expiry_dateDate).getTime() / 1000);
                }

                console.log();
                let contractAddress = `${config.marketplaceContract}`
                let from_address = accounts[0];
                const contract = await new web3.eth.Contract(config.abiMarketplace, contractAddress);
                console.log('Datsaaaa', this.state.getId.toString(), SalePrice.toString(), this.state.sell_type_data.toString(), start_date.toString(), expiry_date.toString());

                await contract.methods.updateDetails(this.state.getId.toString(), SalePrice.toString(), this.state.sell_type_data.toString(), start_date.toString(), expiry_date.toString()).call();


                let tx_builder = await contract.methods.updateDetails(this.state.getId.toString(), SalePrice.toString(), this.state.sell_type_data.toString(), start_date.toString(), expiry_date.toString());

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();
                gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    value: web3.utils.toHex(mintFee),
                    chainId: chainId,
                    data: encoded_tx
                });

                // const txData = await web3.eth.sendTransaction({
                //     gasPrice: web3.utils.toHex(gasPrice),
                //     gas: web3.utils.toHex(gasLimit),
                //     to: contractAddress,
                //     from: from_address,
                //     value: web3.utils.toHex(mintFee),
                //     chainId: chainId,
                //     data: encoded_tx
                // });
                const txData = await web3.currentProvider.request({
                    method: 'eth_sendTransaction',
                    params: [{
                        gasPrice: web3.utils.toHex(gasPrice),
                        gas: web3.utils.toHex(gasLimit),
                        to: contractAddress,
                        from: from_address,
                        value: web3.utils.toHex(mintFee),
                        chainId: chainId,
                        data: encoded_tx
                    }],
                });
                if (txData) {
                    await axios.post(`${config.apiUrl}/updateitemDate`,
                        { 'id': this.state.selectItem, 'expiry_date': moment(this.state.expiry_dateDate).format('YYYY-MM-DD HH:mm:SS'), "hash": txData })
                        .then(async result => {
                            this.setState({
                                spinLoader: '0',
                                isDialogOpen: false
                            })
                            if (result.data.success === true) {
                                await Swal.fire({
                                    icon: 'success',
                                    title: 'Congratulations',
                                    text: result.data.msg
                                })
                                window.location.href = `${config.baseUrl}` + "transaction"

                            }

                            else if (result.data.success === false) {

                            }
                        })

                        .catch(err => {
                        })
                } else {
                    toast.error('Something went wrong please try again.');
                    this.setState({
                        spinLoader: '0',
                        isDialogOpen: false
                    })
                    return false;
                }

            } catch (err) {

                if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                    } else {
                        toast.error(err.message);
                    }
                }

                this.setState({
                    spinLoader: 0,
                    isDialogOpen: false
                })
                return false;
            }
        } else {
            toast.error('Please Connect to MetaMask.');
            this.setState({
                spinLoader: 0,
                isDialogOpen: false
            })
            return false;
        }


    }


    render() {
        // const { first_name,last_name,birth,contact_number} = this.state;
        return (
            <>
                <ToastContainer />
                <div className="header-container fixed-top" onClick={this.removeCls.bind(this, 1)}>
                    <Header2 />
                </div>
                {/* <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  --> */}
                {/* Remove sbar-open */}
                <div className="main-container sidebar-closed whishlist" id="container" onClick={this.removeCls.bind(this, 1)}>

                    <div className="overlay"></div>
                    <div className="cs-overlay"></div>
                    <div className="search-overlay"></div>

                    <InnerLeftSidebar />
                    <Dialog
                        title="Warning"
                        icon="warning-sign"
                        className='transaction-process'
                        style={{
                            color: 'red',
                            zIndex: '1111'
                        }}
                        isOpen={this.state.isDialogOpen}
                        isCloseButtonShown={false}
                    >
                        <div className="text-center">
                            <BarLoader color="#e84747" height="2" />
                            <br />
                            <h4 style={{ color: '#000' }}>Transaction under process...</h4>
                            <p style={{ color: 'red' }}>
                                Please do not refresh page or close tab.
                            </p>
                            <div>
                            </div>
                        </div>
                    </Dialog>

                    {/* <!--  END SIDEBAR  --> */}

                    {/* <!--  BEGIN CONTENT AREA  --> */}
                    <div id="content" className="main-content">
                        <div className="layout-px-spacing">


                            <div className="row layout-spacing">

                                {/* <!-- Content --> */}


                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
                                    <div className="page-header">
                                        <div className="page-title">
                                            <h3>My NFT</h3>
                                        </div>
                                    </div>
                                    <div className="skills layout-spacing ">
                                        <div className="widget-content widget-content-area">
                                            <div className="row mb-4 mt-3">
                                                <div className="col-sm-2 col-12">
                                                    <div className="nav flex-column nav-pills mb-sm-0 mb-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
                                                        <a onClick={this.getOrders.bind(this, '0')} className={this.state.sell_type_cookie == 'price' ? 'nav-link active mb-2' : 'nav-link mb-2'} id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true" >My NFT</a>

                                                        <a onClick={this.getMyBids.bind(this)} className={this.state.sell_type_cookie == 'auction' ? 'nav-link active' : 'nav-link'} id="your-bids-tab" data-toggle="pill" href="#your-bids" role="tab" aria-controls="your-bids" aria-selected="false" >My Bids</a>

                                                        <a onClick={this.getBidsonmyitem.bind(this)} className={this.state.sell_type_cookie == 'bidsMy' ? 'nav-link active' : 'nav-link'} id="your-bids-tab" data-toggle="pill" href="#your-bids" role="tab" aria-controls="your-bids" aria-selected="false" >Bids on my NFTs</a>

                                                    </div>
                                                </div>

                                                <div className="col-sm-10 col-12" >
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div className={this.state.sell_type_cookie == 'bidsMy' ? "tab-pane fade" : "tab-pane fade show active"} role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                            <div className="row" id="cancel-row">

                                                                <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                                                    <div className="table-responsive widget-content widget-content-area br-6 transaction_table">
                                                                        {/* .filter(item=>item.user_id == item.owner_id) */}


                                                                        <ReactDatatable

                                                                            config={this.config}
                                                                            records={this.state.listOrderData}
                                                                            columns={this.columns}
                                                                            onSort={this.onSort}
                                                                        />

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div className={this.state.sell_type_cookie == 'bidsMy' ? "tab-pane fade  show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                            <div className="row" id="cancel-row">

                                                                <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                                                    <div className="table-responsive widget-content widget-content-area br-6 transaction_table">

                                                                        <ReactDatatable

                                                                            config={this.config1}
                                                                            records={this.state.listOrderDataBids}
                                                                            columns={this.columns1}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <!--  END CONTENT AREA  --> */}

                </div>



                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content" style={{ background: '#fff' }}>
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Resale</h5>
                                <button type="button" class="close" style={{ color: '#000' }} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group">
                                        <label for="recipient-name" class="col-form-label">Please Select Sale type</label>
                                        <select className='form-control' value={this.state.allData.sell_type} onChange={this.onChangeResale} name="sell_type">
                                            <option value="1">Fixed Price</option>
                                            <option value="2">Auction</option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label for="recipient-name" class="col-form-label">Please Enter New ETH Price</label>
                                        {/* style={{ borderColor: (this.state.amountError) ? 'red' : '', color: '#333' }} */}
                                        <input type="text" value={this.state.allData.price} name="price" onChange={this.onChangeResale} class="form-control" id="recipient-name" />
                                    </div>
                                    {this.state.allData.sell_type == '2' ?
                                        <>
                                            <label for="recipient-name" class="col-form-label">Sale Starts</label>
                                            <div class="form-group">
                                                <DateTimePicker onChange={e => this.DateTimePickerSelect({ value: e, name: "start_date" })} minDate={new Date()} value={this.state.start_date} name="start_date" class="form-control" />
                                            </div>

                                            <label for="recipient-name" class="col-form-label">Sale Ends</label>
                                            <div class="form-group">
                                                <DateTimePicker onChange={e => this.DateTimePickerSelect({ value: e, name: "expiry_dateResale" })} minDate={this.state.start_date} value={this.state.expiry_dateResale} name="expiry_dateResale" class="form-control" />
                                            </div>

                                            <label for="recipient-name" class="col-form-label">Bid Increasing Amount (in %)</label>
                                            
                                            <input type="text" value={this.state.increasing_amount} name="increasing_amount" onChange={this.onChange} class="form-control" id="recipient-name" />
                                        </> : ''

                                    }


                                    {/* <div className="form-group">
            <label for="recipient-name" class="col-form-label">Resale Fee : {parseFloat(this.state.resaleFeeInBNB).toFixed(4)} ETH <small> ( {parseFloat(this.state.resaleFeeInUSD).toFixed(2)} USD)</small></label>
          </div> */}

                                </form>
                                <div className="form-group">
                                    <ul>
                                        {/* <li>Note : </li> */}
                                        {/* <li>Please first approve your NFT for resale, then click on resale.</li> */}
                                    </ul>
                                </div>
                            </div>

                            <div class="modal-footer">

                                <button type="button" disabled={this.state.allData.sell_type == 2 ? !this.state.start_date || !this.state.expiry_dateResale : ''} style={{ width: '100%', marginBottom: '-10px' }} class="btn btn-success" onClick={this.putOnSaleAPI.bind(this)} >Resale</button>
                                {/* `${this.state.approveBtnText === true ? 'Processing...' : 'Approve NFT'}`} */}
                                {/* disabled={this.state.isApproveNFT || this.state.approveBtnText} */}
                                {/* {(this.state.approveBtnText || this.state.resaleBtnText) ?

                                    <p style={{ width: '100%' }}><BarLoader color="#e84747" height="2" style={{ marginTop: '-21px' }} /></p>
                                    : ''}

                                <button type="button" style={{ width: '100%', marginBottom: '-10px' }} class="btn btn-success" onClick={this.approveNFT.bind(this)} disabled={this.state.isApproveNFT || this.state.approveBtnText}>{`${this.state.approveBtnText === true ? 'Processing...' : 'Approve NFT'}`}</button>
                                <br />
                                <button type="button" disabled={!this.state.isApproveNFT || this.state.resaleBtnText} style={{ width: '100%' }} onClick={this.resalePayment.bind(this)} class="btn btn-success" >{`${this.state.resaleBtnText ? 'Processing...' : 'Resale'}`}</button> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div class="modal fade" id="viewBid" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle">Item Bid Details</h5>
                                <button type="button" class="close" style={{ color: '#000', marginTop: '-25px' }} data-dismiss="modal" aria-label="Close">
                                    <span style={{ color: '#000' }} aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="table-responsive">
                                <table class="table bidtable">
                                    <thead>
                                        <tr>

                                            <th>UserName</th>
                                            <th>Profile Pic</th>
                                            <th>Item Name</th>
                                            <th>Bid Price</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {this.state.bid_list.length === 0 ?
                                            <tr >
                                                <td style={{ color: '#000' }} colspan="6" className="text-center"><p>No data found!!</p></td></tr> :
                                            this.state.bid_list.map(item => (
                                                <tr>

                                                    <td style={{ color: '#000' }}>{item.user_name ? item.user_name : item.email.substring(0, 8)}</td>
                                                    <td style={{ color: '#000' }} >
                                                        {!item.profile_pic || item.profile_pic === 'null' || item.profile_pic === 'undefined'
                                                            ?
                                                            <img src='img/noimage.png' className="product-img" />

                                                            :
                                                            <img src={`${config.imageUrl1}/${item.profile_pic}`} className="product-img" />}

                                                    </td>
                                                    <td style={{ color: '#000' }}>{item.item_name}</td>
                                                    <td style={{ color: '#000' }}>{item.bid_price}ETH</td>

                                                    <td style={{ color: '#000' }}>
                                                        {item.status === 0 ?
                                                            <button type='submit' id={'acceptId' + item.bid_id} onClick={this.BidAcceptAPI.bind(this, item)} className="btn btn-primary">Accept</button> : <p style={{ color: 'red', textAlign: 'center' }}>Overbid</p>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>

                                </table>
                            </div>
                            <div class="modal-footer">


                            </div>
                        </div>
                    </div>
                </div>

                {/* //==============================  Edit popup ================================================== */}

                <div class="modal fade" id="exampleModalEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content" style={{ background: '#fff' }}>
                            <form onSubmit={this.updateNFTData.bind(this)}>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Edit Expiry Date</h5>
                                    <button type="button" class="close" style={{ color: '#000' }} data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">

                                    {this.state.sell_type_data === 2 ?
                                        <div class="form-group">
                                            <label for="recipient-name" style={{ width: '100%' }} class="col-form-label">Sale Ends:&nbsp;&nbsp;<strong>{(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(this.state.dateFormat))}</strong></label>

                                            <DateTimePicker onChange={e => this.DateTimePickerSelect({ value: e, name: "expiry_dateDate" })} name="expiry_dateDate" value={this.state.expiry_dateDate} minDate={new Date()} id="bid_expiration_date" className="form-control" />
                                        </div> : ''

                                    }





                                </div>

                                <div class="modal-footer">


                                    <button type="submit" disabled={!this.state.expiry_dateDate} class="btn btn-success" >Update Date</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}   