import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const Popup = (data) => {
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [msg, setMsg] = useState(false);

  useEffect(() => {}, []);

  let isPopupError = "";
  if (data.isPopupError === true) {
    isPopupError = true;
  } else {
    isPopupError = false;
  }

  let isPopupSuccess = "";
  if (data.isPopupSuccess === true) {
    isPopupSuccess = true;
  } else {
    isPopupSuccess = false;
  }

  setTimeout(() => {
    setErrorPopup(isPopupError);
    setSuccessPopup(isPopupSuccess);
    setMsg(data.msg);
  }, 100);

  return (
    <>
      <Modal show={errorPopup} className="d-toaster-modal" size="md">
        <Modal.Body>
          <div className="d-flex pt-2 pb-2">
            <div className="me-3" style={{ marginTop: "2px" }}>
              <img src="images/danger.png" alt="Danger" width="18px" />
            </div>
            <div className="ml-3">
              {/* <h5 className="mb-0" style={{ marginLeft: "5px" }}>Error</h5> */}
              <h5 className="mb-0">Error</h5>
              <p className="text-muted mb-0">{msg}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={successPopup} className="s-toaster-modal" size="md">
        <Modal.Body>
          <div className="d-flex pt-2 pb-2">
            <div className="me-3" style={{ marginTop: "2px" }}>
              <img src="images/success.png" alt="Success" width="18px" />
            </div>
            <div>
              <h5 className="mb-0" style={{ marginLeft: "5px" }}>
                Success
              </h5>
              <p className="text-muted mb-0">{msg}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Popup;
