import React, { Component } from 'react';
import Footer1 from '../directives/footer1';
import Header1 from '../directives/header1';
import axios from 'axios'
import config from '../config/config';
import moment from 'moment';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
const MAX_LENGTH = 55;
const MAX_LENGTH1 = 250;


export default class blog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            setBlogData: [],
            setfrontNews: 0
        }
    }

    async getBlogs() {
        await axios.get(`${config.apiUrl}/getBlogList`, {},)
            .then(result => {
                if (result.data.success === true) {
                    var ss = result.data.response.findIndex(ii => ii.is_active == 0);
                    this.setState({
                        setBlogData: result.data.response,
                        setfrontNews: ss
                    })
                    //  setBlogData(result.data.response)
                    // var ss = result.data.response.findIndex(ii=>ii.is_active==1);
                    // setfrontNews(ss);
                }
                else if (result.data.success === false) {

                }
            })

            .catch(err => {
            })
    }


    componentDidMount() {
        this.getBlogs()
    }
    render() {

        return (

            <>

                <Header1 />

                <div id="">


                    <div className="container">


                        <div className="row  mt-5">
                            <div className="col-sm-12">
                                <h1 className="mb-3" style={{ marginTop: '25px' }}>
                                    Blog
                                </h1>
                            </div>
                            <br />

                            <div className="col-md-7">


                                <div id="container-mix" className="row _post-container_ ">
                                    <div className=" col-sm-12 ">
                                        <div className="">
                                            <Link to={this.state.setBlogData.length > 0 ? `${config.baseUrl}blogDetail/${this.state.setBlogData[this.state.setfrontNews].blog_id}` : '' }>
                                                <img src={this.state.setBlogData.length > 0 ? config.imageUrl1 + '/' + this.state.setBlogData[this.state.setfrontNews].image : ''} width="100%" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <small className="drop-subheading">{moment(this.state.setBlogData.length > 0 ? this.state.setBlogData[this.state.setfrontNews].date : new Date()).format('LL')}</small>
                                <br />
                                <h1 className="mb-1 nft_world">
                                    {this.state.setBlogData.length > 0 ? this.state.setBlogData[this.state.setfrontNews].title : ''}
                                </h1>
                                <p>{this.state.setBlogData.length > 0 ? this.state.setBlogData[this.state.setfrontNews].description : ''}</p>
                            </div>

                            <div className="col-sm-12 mt-3">
                                <div id="container-mix" className="row _post-container_ fail">
                                    {this.state.setBlogData.map((item) => {
                                        return (
                                            (item.is_active == 1) ? '' :
                                                <div className=" col-sm-4">
                                                    <div class="recent-card p-0">

                                                        <Link to={`${config.baseUrl}blogDetail/${item.blog_id}`} className="recent-list ">
                                                            <img className="marketNftcss" src={config.imageUrl1 + '/' + item.image} height='250' width='238' alt="omg" />
                                                        </Link>
                                                        <div className='p-3'>
                                                            <Link to={`${config.baseUrl}blogDetail/${item.blog_id}`} title={item.title} className="head-title">
                                                                {item.title.length > MAX_LENGTH ?
                                                                    (
                                                                        <>
                                                                            {`${item.title.substring(0, MAX_LENGTH)}...`}
                                                                        </>
                                                                    ) :
                                                                    <>{item.title}</>
                                                                }



                                                            </Link>
                                                            <div className="author-post">
                                                                <img src={config.imageUrl1 + '/' + item.user_profile} alt="" className="ava-author" />

                                                                <div className="entry-date pull-right">
                                                                    {moment(item.date).format('LL')}
                                                                </div>
                                                            </div>
                                                            <p className="entry-content">
                                                                {item.description.length > MAX_LENGTH1 ?
                                                                    (
                                                                        <>
                                                                            {`${item.description.substring(0, MAX_LENGTH1)}...`}
                                                                        </>
                                                                    ) :
                                                                    <>{item.description}</>
                                                                }

                                                                <br />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>)
                                    })}

                                </div>

                            </div>
                        </div>



                    </div>



                </div>
                <Footer1 />
            </>
        );
    }
}
