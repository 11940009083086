import React, { Component } from 'react';
import axios from 'axios';
import config from '../config/config'
import Cookies from 'js-cookie'
const headers = {
   'Content-Type': 'application/json'
};

export default class InnerLeftSidebar extends Component {

   constructor(props) {
      super(props);
      this.loginData = (!Cookies.get('artfungibleUserloginSuccess'))?[]:JSON.parse(Cookies.get('artfungibleUserloginSuccess'))
      var parts = window.location.href.split('/');
      var lastSegment = parts.pop() || parts.pop();
      this.state = {
         'urlSeg' : lastSegment
      }
      
   }


   componentDidMount() {

   }


   

   render() {

      return (
        <div className="sidebar-wrapper sidebar-theme">
                  <nav id="sidebar">
                     <ul className="navbar-nav theme-brand flex-row  text-center" style={{ position: "relative", left: "00px" }}>
                        <li className="nav-item theme-logo" >
                           <a href={`${config.baseUrl}`}>
                              <img src="mercafor_dashboard/assets/img/af-logo.png" className="navbar-logo" alt="logo" />
                           </a>
                        </li>
                        <li className="nav-item theme-text">
                           <a href={`${config.baseUrl}`} className="nav-link"> Art Fungible </a>
                        </li>
                     </ul>
                     <ul className="list-unstyled menu-categories" id="accordionExample">
                        <li className={ this.state?.urlSeg == 'userprofile'? 'menu active' : 'menu' }>
                           <a href={`${config.baseUrl}userprofile`} aria-expanded="true" className="dropdown-toggle">
                              <div className="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home">
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                 </svg>
                                 <span>Profile</span>
                              </div>
                           </a>
                        </li>
                        <li className={ this.state?.urlSeg == 'activity'? 'menu active' : 'menu' }>
                           <a href={`${config.baseUrl}activity`} aria-expanded="false" className="dropdown-toggle">
                              <div className="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-message-square">
                                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                 </svg>
                                 <span>Activity</span>
                              </div>
                           </a>
                        </li>

                        <li className={ this.state?.urlSeg == 'whishlist'? 'menu active' : 'menu' }>
                           <a href={`${config.baseUrl}whishlist`} aria-expanded="false" className="dropdown-toggle">
                              <div className="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-list">
                                    <line x1="8" y1="6" x2="21" y2="6"></line>
                                    <line x1="8" y1="12" x2="21" y2="12"></line>
                                    <line x1="8" y1="18" x2="21" y2="18"></line>
                                    <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                    <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                    <line x1="3" y1="18" x2="3.01" y2="18"></line>
                                 </svg>
                                 <span>WishList</span>
                              </div>
                           </a>
                        </li>
                        <li className={ this.state?.urlSeg == 'transaction'? 'menu active' : 'menu' }>
                           <a  href={`${config.baseUrl}transaction`} aria-expanded="false" className="dropdown-toggle">
                              <div className="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-layout">
                                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                    <line x1="3" y1="9" x2="21" y2="9"></line>
                                    <line x1="9" y1="21" x2="9" y2="9"></line>
                                 </svg>
                                 <span>My NFT</span>
                              </div>
                           </a>
                        </li>
                        <li className={ this.state?.urlSeg == 'refundManagement'? 'menu active' : 'menu' }>
                           <a  href={`${config.baseUrl}refundManagement`} aria-expanded="false" className="dropdown-toggle">
                              <div className="">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-list">
                                    <line x1="8" y1="6" x2="21" y2="6"></line>
                                    <line x1="8" y1="12" x2="21" y2="12"></line>
                                    <line x1="8" y1="18" x2="21" y2="18"></line>
                                    <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                    <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                    <line x1="3" y1="18" x2="3.01" y2="18"></line>
                                 </svg>
                                 <span>Refund Management</span>
                              </div>
                           </a>
                        </li>                        
                     </ul>
                  </nav>
               </div>

      )
   }
}
