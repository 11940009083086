import React, { Component } from 'react';
import Header2 from '../directives/header2'
// import Footer from '../directives/footer'
// // import axios from 'axios';
import config from '../config/config'
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import axios from 'axios';
import InnerLeftSidebar from '../directives/innerLeftSidebar'
import { Link } from 'react-router-dom';
import $ from 'jquery'; 

export default class activity extends Component {

   constructor(props) {
      super(props);
      this.loginData = (!Cookies.get('artfungibleUserloginSuccess'))?[]:JSON.parse(Cookies.get('artfungibleUserloginSuccess'))
      if(!this.loginData.data){
         window.location.href=`${config.baseUrl}`
      }
      this.state = {
         getProfilePicAPIData:'',
         activityList:[],
         email: this.loginData.data.user_email,
         getUser : []
      };
   }


   componentDidMount() {
      this.getProfilePicAPI();
      this.getUserActivityAPI()
      this.getUserData()
   }

   async getUserData() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/getuserprofile`,
         data: { "email": this.loginData.data.user_email }
      })
         .then(result => {
            if (result.data.success === true) {
               this.setState({
                  getUser: result.data.response
               })
            }
         }).catch(err => { });
   }

   async getProfilePicAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/getProfilePic`,
         headers: { "Authorization": this.loginData.message },
         data: { "email": this.state.email }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               getProfilePicAPIData: response.data?.response
            })
         }
      })
   }
   async getUserActivityAPI(){
      await axios({
         method: 'post',
         url: `${config.apiUrl}/getUserActivity`,
         headers: { "Authorization": this.loginData.message },
         data: { "user_id": this.loginData.data?.id }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               activityList: response.data?.response
            })
         }
      })
   }

   removeCls(){
      $('.main-container').removeClass('sbar-open');
   }   

    render(){

      return (

          <>
            
            <div className="header-container fixed-top" onClick={this.removeCls.bind(this, 1)}>
            <Header2 />
            </div>
      {/* remove class --sbar-open-- */}
      <div className="main-container sidebar-closed activity" id="container" onClick={this.removeCls.bind(this, 1)}>
         <div className="overlay"></div>
         <div className="cs-overlay"></div>
         <div className="search-overlay"></div>
         <InnerLeftSidebar/>
         {/* <!--  END SIDEBAR  --> */}
         {/* <!--  BEGIN CONTENT AREA  --> */}
         <div id="content" className="main-content">
            <div className="layout-px-spacing">
               <div className="row layout-spacing">
                  {/* <!-- Content --> */}
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
                     <div className="page-header">
                        <div className="page-title">
                           <h3>Notifications</h3>
                        </div>
                     </div>
                     <div className="skills layout-spacing ">
                        <div className="widget-content widget-content-area">
                           <div className="row mb-4 mt-3">
                              <div className="col-sm-3 col-12">
                                 <div className="nav flex-column nav-pills mb-sm-0 mb-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link active mb-2" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">All</a>
                                    <a className="nav-link mb-2" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Login detail</a>
                                 </div>
                              </div>
                              <div className="col-sm-9 col-12">
                                 <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                       <div className="row">
                                          <div className="col-sm-12 col-md-12 col-lg-5 email-list">
                                             <div className="topPagination clr">
                                                <h3>Alerts List</h3>
                                                {/* <div className="float-left txt num">1 of 1</div>
                                                <div className="float-right">
                                                   <a href="javascript:;" className="icon disabled">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-left">
                                                         <polyline points="15 18 9 12 15 6"></polyline>
                                                      </svg>
                                                   </a>
                                                   <a href="javascript:;" className="icon disabled">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right">
                                                         <polyline points="9 18 15 12 9 6"></polyline>
                                                      </svg>
                                                   </a>
                                                </div> */}
                                             </div>
                                             
                                             <div className="msgscroll" style={{maxHeight:'500px',minHeight:'500px',overflowY: this.state.activityList?.length > 7  ? 'scroll' : '' }}>
                                                <ul>
                                                {(this.state.activityList.length==0)?
                                                <li id="noty45068" className="cls noty-cls unread">
                                                <a href="#javascript">
                                                   <div>No Records Found.</div>
                                                </a>
                                                <br/>
                                             </li>
                                                :
                                                this.state.activityList.map((item,i)=>(
                                                   <li id="noty45068" className="cls noty-cls unread">
                                                      <a href="#javascript">
                                                         <div className='activity_type'>{i+1} . {item.description}</div>
                                                         
                                                         <div className="bottom">
                                                            <small className="time">{item.duration}</small>
                                                         </div>
                                                      </a>
                                                      <br/>
                                                   </li>
                                                ))}                         
                                                   
                                                   
                                                </ul>
                                             </div>
                                          </div>
                                          <div className="col-sm-12 col-md-12 col-lg-7 ">
                                             <div className="mail-content">
                                                <div>
                                                   <div>
                                                      <div className="">
                                                         <div className="clr">
                                                            <div className="logoInbox">
                                                                {/* <img className="" src={(this.state.getProfilePicAPIData.profile_pic === '' || this.state.getProfilePicAPIData.profile_pic === undefined || this.state.getProfilePicAPIData.profile_pic === null) ? "mercafor_dashboard/assets/img/coin-fmx.gif" : `${config.imageUrl1}/${this.state.getProfilePicAPIData.profile_pic}`} />*/}
                                                                <img src="mercafor_dashboard/assets/img/af-logo.png"/>
                                                            </div> 
                                                            <h4 className="title">Hello,  
                                                            {this.state.getUser?.first_name?
                                                               this.state.getUser?.first_name : ""
                                                            }

                                                            {this.state.getUser?.last_name?
                                                               this.state.getUser?.last_name
                                                            : ""}

                                                            </h4>
                                                            <p>Last Activity: {this.state.activityList[0]?.description}.</p>
                                                            <Link className="" to={`${config.baseUrl}transaction`}><button className="btn btn-success">View Item</button></Link>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                   
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                       <div className=" col-sm-12 login_edit_detail">
                                        {/* <!-- Task --> */}
                                              <ul className="list-group task-list-group">
                                                  <li className="list-group-item list-group-item-action">
                                                      <div className="n-chk">
                                                          <label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between">
                                                            
                                                              <span className=" col-sm-6">
                                                                  Name:
                                                                   
                                                              </span>
                                                              <span className=" d-block col-sm-6">
                                                              
                                                              {this.state.getUser?.first_name?
                                                               this.state.getUser?.first_name : ""
                                                            }

                                                            {this.state.getUser?.last_name?
                                                               this.state.getUser?.last_name
                                                            : ""}
                                                              </span>
                                                          </label>
                                                      </div>
                                                  </li>
                                                  <li className="list-group-item list-group-item-action ">
                                                      <div className="n-chk">
                                                          <label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between">
                                                            
                                                              <span className=" col-sm-6">
                                                                  E-mail:
                                                                  
                                                              </span>
                                                              <span className=" d-block col-sm-6">
                                                               
                                                              {this.loginData.data?.user_email}
                                                              </span>
                                                          </label>
                                                      </div>
                                                  </li>
                                                  <li className="list-group-item list-group-item-action">
                                                      <div className="n-chk">
                                                          <label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between">
                                                            
                                                                <span className=" col-sm-6">Mobile Number:</span>
                                                                <span className=" d-block col-sm-6">
                                                                {this.state.getUser?.phone?
                                                                  this.state.getUser?.phone
                                                                :""}
                                                                </span>
                                                          </label>
                                                      </div>
                                                  </li>
                                                  <li className="list-group-item list-group-item-action">
                                                      <div className="n-chk">
                                                          <label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between">
                                                            
                                                                <span className=" col-sm-6">Password:</span>
                                                                <span className=" d-block col-sm-6">
                                                                    ********
                                                                </span>
                                                          </label>
                                                      </div>
                                                  </li>
                                                  <li className="list-group-item list-group-item-action">
                                                      <div className="n-chk">
                                                          <label className="new-control new-checkbox pl-0 checkbox-primary w-100 justify-content-between">
                                                            
                                                                <span className=" col-sm-6">Last Login time:</span>
                                                                <span className=" d-block col-sm-6">
                                                                {(this.loginData.data?.last_login?.replace('.000Z',''))?.replace('T',' ')}
                                                                </span>
                                                          </label>
                                                      </div>
                                                  </li>
                                              </ul>
                                          {/* <!-- <img className="mr-3" src="assets/img/profile-32.jpeg" alt="Generic placeholder image">
                                          <div className="media-body">
                                             Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                                          </div> --> */}
                                       </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                       <p className="dropcap  dc-outline-primary">
                                          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                          proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                       </p>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                       <blockquote className="blockquote">
                                          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                       </blockquote>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-redeemed" role="tabpanel" aria-labelledby="v-pills-redeemed-tab">
                                       <blockquote className="blockquote">
                                          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                       </blockquote>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* <!--  END CONTENT AREA  --> */}
      </div>
      
          </>

      )
    }
}