import React, { Component } from "react";
import Footer1 from "../directives/footer1";
import Header1 from "../directives/header1";
import axios from "axios";
// import config from '../config/config'
import config from "../config/config";
import "react-alice-carousel/lib/alice-carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Slide, Zoom, Bounce, Flip } from "react-reveal";
import Web3 from "web3";
import { Dialog, Classes } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import BarLoader from "react-bar-loader";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Popup from "../popup";
import { Helmet } from "react-helmet";

export default class member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remainingNftForSale: "",
      totalSupply: "",
      maxSupply: "",
      remainingNftForSaleBase: "",
      totalSupplyBase: "",
      maxSupplyBase: "",
      faqList: [],
      isDialogOpen: false,
      isPopupError: false,
      isPopupSuccess: false,
      msg: false,
      seoList: "",
    };
    this.mintMemberShip = this.mintMemberShip.bind(this);
    this.mintMemberShipBase = this.mintMemberShipBase.bind(this);

    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));
  }

  componentDidMount() {
    this.getMemberShipDetail();
    this.getMemberShipDetailBase()
    this.getFaqAPI();
    console.log(this.loginData);
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

    this.getSeoAPI(lastSegment);
    if (!this.loginData) {
      window.location.href = `${config.baseUrl}`;
    }
  }

  async getSeoAPI(lastSegment) {
    await axios({
      method: "post",
      url: `${config.apiUrl}/getSEO`,
      data: { pageName: lastSegment },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            seoList: response.data.data,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => {});
  }

  async getMemberShipDetail() {
    var web3 = new Web3(config.ethRpcUrl);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const contractAddress = config.memberShipContract;
    const contract = await new web3.eth.Contract(
      config.memberShipABI,
      contractAddress
    );

    var from_address = accounts[0];
    web3.eth.defaultAccount = from_address;
    console.log(">>>>>>>>>", from_address);
    let remainingNftForSale = await contract.methods
      .remainingNftForSale()
      .call();
    let totalSupply = await contract.methods.totalSupply().call();
    let maxSupply = await contract.methods.maxSupply().call();
    this.setState({
      remainingNftForSale: remainingNftForSale,
      totalSupply: totalSupply,
      maxSupply: maxSupply,
    });
  }

  async getMemberShipDetailBase() {
    var web3 = new Web3(config.baseRpcUrl);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const contractAddress = config.memberShipContractBase;
    const contract = await new web3.eth.Contract(
      config.memberShipABI,
      contractAddress
    );

    var from_address = accounts[0];
    web3.eth.defaultAccount = from_address;
    console.log(">>>>>>>>>", from_address);
    let remainingNftForSale = await contract.methods
      .remainingNftForSale()
      .call();
    let totalSupply = await contract.methods.totalSupply().call();
    let maxSupply = await contract.methods.maxSupply().call();
    this.setState({
      remainingNftForSaleBase: remainingNftForSale,
      totalSupplyBase: totalSupply,
      maxSupplyBase: maxSupply,
    });
  }

  async getFaqAPI() {
    await axios({
      method: "get",
      url: `${config.apiUrl}faqlistUser`,
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            faqList: response.data.response,
          });
        } else if (response.data.success === false) {
        }
      })

      .catch((err) => {});
  }

  async mintMemberShip() {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(config.chainId) }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: Web3.utils.toHex(config.chainId),
                chainName: "BNB",
                rpcUrls: [`${config.ethRpcUrl}`],
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://base-sepolia.blockscout.com"],
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
        }
      }
    }

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const web3 = new Web3(window.ethereum);
    var chainId = web3.currentProvider.chainId;

    try {
      let contractAddress = `${config.memberShipContract}`;
      let from_address = accounts[0];

      const { data: apiRes } = await axios.get(
        `${config.apiUrl}validate-wallet/${from_address}`
      );
      console.log(apiRes)
      if (!apiRes.status) {
        this.setState({
          isPopupError: true,
          msg: apiRes.msg,
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
        return;
      }

      this.setState({
        isDialogOpen: true,
      });
      const contract = await new web3.eth.Contract(
        config.memberShipABI,
        contractAddress
      );
      let tx_builder = "";
      tx_builder = await contract.methods.mint(apiRes.hexproof);

      let encoded_tx = tx_builder.encodeABI();
      let gasPrice = await web3.eth.getGasPrice();
     // gasPrice = (await parseInt(gasPrice)) //+ parseInt(10000000000);
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: contractAddress,
        from: from_address,
        chainId: chainId,
        value: web3.utils.toHex(),
        data: encoded_tx,
      });

      const txData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: contractAddress,
        from: from_address,
        chainId: chainId,
        value: web3.utils.toHex(),
        data: encoded_tx,
      });
      if (txData) {
        var paymentArr = {
          userAddress: from_address,
          userId: this.loginData.data.id,
          hash: txData.transactionHash,
          // : from_address,
          // paymentType: coin
        };
        this.setState({
          isDialogOpen: false,
        });
        this.setState({
          isPopupSuccess: true,
          msg: "Membership card be available soon",
        });
        setTimeout(() => {
          this.setState({
            isPopupSuccess: false,
          });
          window.location.reload();
        }, 2000);

        // this.updateMemberShip(paymentArr);
      } else {
        this.setState({
          isPopupError: true,
          msg: "Something went wrong please try again.",
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
        this.setState({
          isDialogOpen: false,
        });
        return false;
      }
    } catch (err) {
      console.log(err);
      if (err.message.toString().split("insufficient funds")[1]) {
        this.setState({
          isPopupError: true,
          msg: "Transaction reverted : " + err.message,
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
      } else {
        if (err.toString().split("execution reverted:")[1]) {
          this.setState({
            isPopupError: true,
            msg: "Transaction reverted : User can’t have the second card.",
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        } else {
          this.setState({
            isPopupError: true,
            msg: "No Amount in the Wallet",
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        }
      }
      this.setState({
        isDialogOpen: false,
      });
      return false;
    }
    // } else {
    //   this.setState({
    //     isPopupError: true,
    //     msg: "Please Connect to MetaMask.",
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       isPopupError: false,
    //     });
    //   }, 2000);
    //   this.setState({
    //     isDialogOpen: false,
    //   });
    //   return false;
    // }
  }


  async mintMemberShipBase() {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(config.chainIdSep) }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: Web3.utils.toHex(config.chainIdSep),
                chainName: "Base",
                rpcUrls: [`${config.baseRpcUrl}`],
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://basescan.org/"],
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
        }
      }
    }

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const web3 = new Web3(window.ethereum);
    var chainId = web3.currentProvider.chainId;

    try {
      let contractAddress = config.memberShipContractBase;
    
      let from_address = accounts[0];

      const { data: apiRes } = await axios.get(
        `${config.apiUrl}validate-wallet/${from_address}`
      );
      if (!apiRes.status) {
        this.setState({
          isPopupError: true,
          msg: apiRes.msg,
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
        return;
      }

      this.setState({
        isDialogOpen: true,
      });
      const contract = await new web3.eth.Contract(
        config.memberShipABI,
        contractAddress
      );
      let tx_builder = await contract.methods.mint(apiRes.hexproof);

      let encoded_tx = tx_builder.encodeABI();

      let gasPrice = await web3.eth.getGasPrice();

     // gasPrice = ( parseInt(gasPrice)) //+ parseInt(10000000000);

      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: contractAddress,
        from: from_address,
        chainId: chainId,
        data: encoded_tx,
      });
    
      const txData = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: contractAddress,
        from: from_address,
        chainId: chainId,
        data: encoded_tx,
      });
      if (txData) {
        var paymentArr = {
          userAddress: from_address,
          userId: this.loginData.data.id,
          hash: txData.transactionHash,
          // : from_address,
          // paymentType: coin
        };
        this.setState({
          isDialogOpen: false,
        });
        this.setState({
          isPopupSuccess: true,
          msg: "Membership card be available soon",
        });
        setTimeout(() => {
          this.setState({
            isPopupSuccess: false,
          });
          window.location.reload();
        }, 2000);

        // this.updateMemberShip(paymentArr);
      } else {
        this.setState({
          isPopupError: true,
          msg: "Something went wrong please try again.",
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
        this.setState({
          isDialogOpen: false,
        });
        return false;
      }
    } catch (err) {
    console.log(err)
      if (err.message.toString().split("insufficient funds")[1]) {
        this.setState({
          isPopupError: true,
          msg: "Transaction reverted : " + err.message,
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
      } else {
        if (err.toString().split("execution reverted:")[1]) {
          this.setState({
            isPopupError: true,
            msg: "Transaction reverted : User can’t have the second card.",
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        } else {
          this.setState({
            isPopupError: true,
            msg: "No Amount in the Wallet",
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        }
      }
      this.setState({
        isDialogOpen: false,
      });
      return false;
    }
    // } else {
    //   this.setState({
    //     isPopupError: true,
    //     msg: "Please Connect to MetaMask.",
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       isPopupError: false,
    //     });
    //   }, 2000);
    //   this.setState({
    //     isDialogOpen: false,
    //   });
    //   return false;
    // }
  }

  

  async updateMemberShip(paymentArr) {
    console.log(paymentArr);
    await axios({
      method: "post",
      url: `${config.apiUrl}memberShipCardPurchased`,
      data: paymentArr,
      headers: { Authorization: this.loginData.Token },
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            isDialogOpen: false,
          });
          this.setState({
            isPopupSuccess: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupSuccess: false,
            });
          }, 2000);
        } else if (response.data.success === false) {
        }
      })

      .catch((err) => {});
  }

  render() {
    return (
      <>
        <div className="body_bg">
          <Header1 />
          <Helmet>
            <title>{this.state.seoList?.title}</title>
            <meta title={this.state.seoList?.pageTitle} content="IE=edge" />
            <meta
              name="description"
              content={this.state.seoList?.description
                ?.replace(/<[^>]*>?/gm, "")
                .substring(0, 170)}
            />
          </Helmet>
          <Dialog
            title="Warning"
            icon="warning-sign"
            className="transaction-process"
            style={{
              color: "red",
              zIndex: "1111",
            }}
            isOpen={this.state.isDialogOpen}
            isCloseButtonShown={false}
          >
            <div className="text-center">
              <BarLoader color="#e84747" height="2" />
              <br />
              <h4 style={{ color: "#000" }}>Transaction under process...</h4>
              <p style={{ color: "red" }}>
                Please do not refresh page or close tab.
              </p>
              <div></div>
            </div>
          </Dialog>
          <Popup
            isPopupError={this.state.isPopupError}
            isPopupSuccess={this.state.isPopupSuccess}
            msg={this.state.msg}
          />
          {/*---------------------- Collection Section Start -------------------- */}

          <section className="collection_card pt-lg-5 pt-3">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 mb-4">
                  <Flip left>
                    <div className="card feature">
                      {/* <img
                        className="img-fluid"
                        src="mercafor_dashboard/assets/img/member_cartoon1.webp"
                        alt="member_cartoon1"
                      /> */}
                      <video
                        width="1000"
                        height="500"
                        className="img-fluid"
                        controls
                      >
                        <source
                          src="images/Genisis Membership Card.mp4"
                          type="video/mp4"
                        />
                        <source
                          src="images/Genisis Membership Card.mp4"
                          type="video/ogg"
                        />
                      </video>
                      {/* <video src="images/Genisis Membership Card.mp4"/> */}
                      <div className="card-body">
                        <div className="membertext">
                          <h4 className="">AF Genesis</h4>
                          <p className="my-3">
                            Remaining:{" "}
                            {this.state.remainingNftForSale || "Loading..."} /{" "}
                            {parseInt(this.state.totalSupply) +
                              parseInt(this.state.remainingNftForSale) ||
                              "Loading..."}
                          </p>
                        </div>
                        <div className="">
                          <div className="custombtn listsale">
                            AF Genesis #{" "}
                            {this.state.remainingNftForSale || "Loading..."}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12 mx-auto">
                            <button
                              type="submit"
                              onClick={this.mintMemberShip}
                              className="btn btn-buy-primary w-100 mt-2"
                            >
                              Free Mint
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Flip>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-4">
                  <Flip right>
                    <div className="card feature">
                      {/* <img
                        className="img-fluid"
                        src="mercafor_dashboard/assets/img/member_cartoon2.webp"
                        alt="member_cartoon2"
                      /> */}
                      <video
                        width="1000"
                        height="500"
                        className="img-fluid"
                        controls
                      >
                        <source
                          src="images/Master Membership Card.mp4"
                          type="video/mp4"
                        />
                        <source
                          src="images/Master Membership Card.mp4"
                          type="video/ogg"
                        />
                      </video>
                      <div className="card-body">
                        <div className="membertext">
                          <h4 className="">AF Master</h4>
                          <p className="my-3">
                            Remaining: 0 /{" "}
                            {this.state.maxSupply || "Loading..."}
                          </p>
                        </div>
                        {/* <div className="">
                          <a href className="custombtn soldout">
                            Sold out
                          </a>
                        </div> */}
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12 mx-auto">
                            <button
                              type="button"
                              className="btn btn-buy-primary w-100 mt-2"
                            >
                              Find in market palce
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Flip>
                </div>
              </div>
            </div>
          </section>

          <hr style={{border:"1px solid #000"}}/>
          <div style={{marginLeft:"58px",marginTop:"25px",fontWeight:"bold"}}>Base Eth Membership</div>
          <section className="collection_card pt-2">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 mb-4">
                  <Flip left>
                    <div className="card feature">
                      {/* <img
                        className="img-fluid"
                        src="mercafor_dashboard/assets/img/member_cartoon1.webp"
                        alt="member_cartoon1"
                      /> */}
                      <video
                        width="1000"
                        height="500"
                        className="img-fluid"
                        controls
                      >
                        <source
                          src="images/Genisis Membership Card.mp4"
                          type="video/mp4"
                        />
                        <source
                          src="images/Genisis Membership Card.mp4"
                          type="video/ogg"
                        />
                      </video>
                      {/* <video src="images/Genisis Membership Card.mp4"/> */}
                      <div className="card-body">
                        <div className="membertext">
                          <h4 className="">AF Genesis</h4>
                          <p className="my-3">
                            Remaining:{" "}
                            {this.state.remainingNftForSaleBase || "Loading..."} /{" "}
                            {parseInt(this.state.totalSupplyBase) +
                              parseInt(this.state.remainingNftForSaleBase) ||
                              "Loading..."}
                          </p>
                        </div>
                        <div className="">
                          <div className="custombtn listsale">
                            AF Genesis #{" "}
                            {this.state.remainingNftForSaleBase || "Loading..."}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12 mx-auto">
                            <button
                              type="submit"
                              onClick={this.mintMemberShipBase}
                              className="btn btn-buy-primary w-100 mt-2"
                            >
                              Free Mint
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Flip>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-4">
                  <Flip right>
                    <div className="card feature">
                      {/* <img
                        className="img-fluid"
                        src="mercafor_dashboard/assets/img/member_cartoon2.webp"
                        alt="member_cartoon2"
                      /> */}
                      <video
                        width="1000"
                        height="500"
                        className="img-fluid"
                        controls
                      >
                        <source
                          src="images/Master Membership Card.mp4"
                          type="video/mp4"
                        />
                        <source
                          src="images/Master Membership Card.mp4"
                          type="video/ogg"
                        />
                      </video>
                      <div className="card-body">
                        <div className="membertext">
                          <h4 className="">AF Master</h4>
                          <p className="my-3">
                            Remaining: 0 /{" "}
                            {this.state.maxSupply || "Loading..."}
                          </p>
                        </div>
                        {/* <div className="">
                          <a href className="custombtn soldout">
                            Sold out
                          </a>
                        </div> */}
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12 mx-auto">
                            <button
                              type="button"
                              className="btn btn-buy-primary w-100 mt-2"
                            >
                              Find in market palce
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Flip>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- Collection Section End -------------------- */}

          {/*---------------------- Member Section Start -------------------- */}

          {/* <section className="member pt-lg-5">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <h2 className="tophead">Membership Benefits</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-6 col-12">
                  <Zoom>
                    <div className="">
                      <img
                        src="mercafor_dashboard/assets/img/memberimg.webp"
                        className="collec_jollykoh"
                        alt="memberimg"
                      />
                    </div>
                  </Zoom>
                </div>
                <div className="col-lg-7 col-md-6 col-12 d-flex align-items-center pl-lg-5">
                  <Slide right>
                    <div className="substext">
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Quisque purus
                        vulputate tortor lacus ultrices consequat. Sed maecenas
                        vitae turpis maecenas lacus lectus. Urna donec urna
                        pharetra porttitor pharetra consequat posuere nunc. Nisl
                        eget massa suspendisse morbi nisi. Enim cursus aliquam
                        arcu faucibus ultricies. Felis dolor semper nascetur
                        neque morbi. Scelerisque integer montes nunc fermentum
                        urna vulputate. Elementum et interdum maecenas at lorem
                        eu fermentum id consequat. Volutpat sit cursus lorem
                        nisl ut libero pellentesque proin sem. Nulla augue
                        convallis pellentesque potenti habitasse nulla ut donec.
                        Quis duis aliquet aliquam amet malesuada viverra.
                        Convallis porttitor pulvinar amet aenean integer ut
                        faucibus. Leo lectus nullam quis tempus. Enim
                        pellentesque nec eu arcu aliquam cursus aliquet purus.
                        Nulla condimentum pulvinar vulputate in orci. Faucibus
                        ullamcorper elit gravida cursus mus suspendisse. Neque
                        quisque lectus elementum et lacus purus velit sed. Leo
                        pharetra volutpat aliquet ipsum. Euismod nulla vitae
                        amet tortor tortor. Interdum gravida vulputate nisl urna
                        ipsum sit rhoncus. Magna blandit maecenas eget commodo
                        imperdiet ultrices in.
                      </p>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          </section> */}

          {/*---------------------- Member Section End -------------------- */}

          {/*---------------------- icon_sec Section Start -------------------- */}

          <section className="icon_sec">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <h2 className="tophead">Membership Benefits</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-9 col-md-9 col-12 mx-auto">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="icons_content">
                        <div className="icons_blocks">
                          <div className="">
                            <img
                              src="images/VIP Invitation.svg"
                              className="collec_jollykoh"
                              alt="memberimg"
                            />
                          </div>
                          <div className="icons_text">
                            <h5>
                              VIP invitation <br /> to our exclusive events
                            </h5>
                          </div>
                        </div>

                        <div className="icons_blocks">
                          <div className="">
                            <img
                              src="images/Free entrace.svg"
                              className="collec_jollykoh"
                              alt="memberimg"
                            />
                          </div>
                          <div className="icons_text">
                            <h5>
                              Free entrance for Art <br />
                              Fungible art exhibition
                            </h5>
                          </div>
                        </div>

                        <div className="icons_blocks">
                          <div className="">
                            <img
                              src="images/Airdrops.svg"
                              className="collec_jollykoh"
                              alt="memberimg"
                            />
                          </div>
                          <div className="icons_text">
                            <h5>Airdrops</h5>
                          </div>
                        </div>

                        <div className="icons_blocks">
                          <div className="">
                            <img
                              src="images/Exclusive.svg"
                              className="collec_jollykoh"
                              alt="memberimg"
                            />
                          </div>
                          <div className="icons_text">
                            <h5>Exclusive membership cards</h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12 d-lg-flex justify-content-end">
                      <div className="icons_content">
                        <div className="icons_blocks">
                          <div className="">
                            <img
                              src="images/Special Discount.svg"
                              className="collec_jollykoh"
                              alt="memberimg"
                            />
                          </div>
                          <div className="icons_text">
                            <h5>
                              Special discounts for <br /> selected collections
                            </h5>
                          </div>
                        </div>

                        <div className="icons_blocks">
                          <div className="">
                            <img
                              src="images/White listing.svg"
                              className="collec_jollykoh"
                              alt="memberimg"
                            />
                          </div>
                          <div className="icons_text">
                            <h5>White Listing privilege</h5>
                          </div>
                        </div>

                        <div className="icons_blocks">
                          <div className="">
                            <img
                              src="images/Preview new.svg"
                              className="collec_jollykoh"
                              alt="memberimg"
                            />
                          </div>
                          <div className="icons_text">
                            <h5>Preview new listings</h5>
                          </div>
                        </div>

                        <div className="icons_blocks">
                          <div className="">
                            <img
                              src="images/Researchase.svg"
                              className="collec_jollykoh"
                              alt="memberimg"
                            />
                          </div>
                          <div className="icons_text">
                            <h5>Researches</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- icon_sec Section End -------------------- */}

          {/*---------------------- Member Faq Section Start -------------------- */}

          <section className="faq py-lg-5 pt-3">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <h2 className="tophead">FAQ</h2>
                  <p className="topheadpera">
                    Got a question that isn’t answered here? Join our Discord
                    and ask our team or community of members.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 col-md-8 col-12 mx-auto">
                  <Bounce bottom>
                    <div
                      className="panel-group"
                      id="accordion1"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      {this.state.faqList.map((item, index) => (
                        <div className="panel panel-default mb-3">
                          <div
                            className="panel-heading"
                            role="tab"
                            id={"headingOne" + (index + 1)}
                          >
                            <h4 className="panel-title">
                              <a
                                role="button"
                                data-toggle="collapse"
                                data-parent="#accordion1"
                                href={"#collapseOne" + (index + 1)}
                                aria-expanded="true"
                                aria-controls={"collapseOne" + (index + 1)}
                                style={{
                                  fontFamily: "Garamond",
                                  fontWeight: "400",
                                  fontSize: "30px",
                                }}
                              >
                                {item.question}
                              </a>
                            </h4>
                          </div>
                          <div
                            id={"collapseOne" + (index + 1)}
                            className="panel-collapse collapse in"
                            role="tabpanel"
                            aria-labelledby={"headingOne" + (index + 1)}
                          >
                            <div className="panel-body pl-lg-3">
                              {item.answer}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Bounce>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- Member Faq Section End -------------------- */}

          <Footer1 />
        </div>
      </>
    );
  }
}
