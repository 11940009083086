import React, { Component } from 'react';
import InnerHeader from '../directives/innerHeader'
import Footer from '../directives/footer'
import axios from 'axios';
import config from '../config/config'
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Link} from 'react-router-dom'
export default class cart extends Component {

    constructor(props){
        super(props)
        this.state={
            checkOutActive:'active',
            paymentActive:'',
            confirmationActive:'',
            listCartData:[],
            totalCart:''
        }
        this.loginData = (!Cookies.get('artfungibleUserloginSuccess'))?[]:JSON.parse(Cookies.get('artfungibleUserloginSuccess'))
    }
    
    componentDidMount() {
        this.listCartAPI()
    }

    openCity(id) {
        if(id === 'checkout'){
            this.setState({
                checkOutActive:'active',
                paymentActive:'',
                confirmationActive:'',
            })
        }
        else if(id === 'payment'){
            this.setState({
                checkOutActive:'',
                paymentActive:'active',
                confirmationActive:'',
            })
        }
        else if(id === 'confirmation'){
            this.setState({
                checkOutActive:'',
                paymentActive:'',
                confirmationActive:'active',
            })
        }
    }

            //====================================== Cart list  =============================
    
            async listCartAPI() {
               await axios({
                  method: 'post',
                  url: `${config.apiUrl}/listCart`,
                 //  headers: { "Authorization": this.loginData.message },
                  data: { "user_id":this.loginData.data.id}
               }).then(response => {
                  if (response.data.success === true) {
                     this.setState({
                        totalCart:response.data,
                        listCartData: response.data?.data
                     })
                  }
               }).catch(err => {
                  if (err.request) { } if (err.response) {
                     this.setState({
                        falseMsg:err.response.data.msg
                     })
                     // alert(err.response.data.msg)
                     // toast.error(err.response.data.msg, {
                     //     position: toast.POSITION.TOP_CENTER
                     // });
                 }
             });
            }

                 //==========================================  Delete Post  ================================

   async cartListDelete(id) {

      confirmAlert({
         title: 'Confirm to submit',
         message: 'Are you sure to delete this.',
         buttons: [
            {
               label: 'Yes',
               onClick: () =>
                  axios({
                     method: 'post',
                     url: `${config.apiUrl}/removeCart`,
                     headers: { "Authorization": this.loginData.message },
                     data: { 'cart_id': id }
                  }).then((res) => {
                     // this.componentDidMount()
                     toast.success(res.data.msg, {
                        position: toast.POSITION.TOP_CENTER
                     });
                     window.location.reload()
                     // this.componentDidMount()
                  }).catch((error) => {
                  })
            },
            {
               label: 'No',
            }
         ]
      });
  
   }

    render(){
    return (
          <>

           <div className="body_bg">
           <InnerHeader/>
           <section className="product_section">
         <br/>
         <br/><br/>
         <div className=" checkout-container">
         <ToastContainer />

         {this.state.falseMsg === '' || this.state.falseMsg === undefined? 
          <div className="container">
          <div className="checkout-tabs-title ">
             <ul className="flex-box">
             {/* onClick={this.openCity.bind(this,'confirmation')} */}
                <li className={"tablink " + this.state.checkOutActive} ><a href="javascript:void();">1. CHECKOUT</a></li>
                <li className={"tablink " + this.state.paymentActive} ><a href="javascript:void();">2. PAYMENT</a></li>
                <li className={"tablink " + this.state.confirmationActive} ><a href="javascript:void();">3. CONFIRMATION</a></li>
             </ul>
          </div>
          <div id="checkout" className="checkout tab-pane" style={{display:this.state.checkOutActive === 'active'? 'block':'none'}}>
                <div className="clr">
                   <h1>Shopping Cart</h1>
                </div>
                <div className="cart-listing">
                   <div className="listing-header clr">
                      <div className="details">Details</div>
                      <div className="price">Price</div>
                      <div className="actions">Actions</div>
                   </div>
                   <div className="listing-body clr">
                      <ul>
                         <li className="clr cart-ul">
                            {this.state.listCartData.map(item => (
                               <>
 
                                  <div className="details clr">
                                  {/* <Link to={`${config.baseUrl}marketplaceDetail/${item.cart_id}`}> */}
                                     <div className="img-holder"><img src={`${config.imageUrl}/${item.image}`} alt=""/></div>
                                     {/* </Link> */}
                                     <div className="txt">
                                     {/* <Link to={`${config.baseUrl}marketplaceDetail/${item.cart_id}`}> */}
                                        <h5>{item.name}</h5>
                                        {/* </Link> */}
                                        <div className="id"><label for="">Token Id</label><span>{item.token_id}</span></div>
                                        <div className="id owner"><label for="">Owner:</label><span>{item.owner}</span></div>
                                     </div>
                                  </div>
                                  <div className="price">${item.price}</div>
                                  <div className="actions">
                                     <a href="#javascript" onClick={this.cartListDelete.bind(this,item.cart_id)}>
                                        <span className="icon">
                                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x">
                                              <line x1="18" y1="6" x2="6" y2="18"></line>
                                              <line x1="6" y1="6" x2="18" y2="18"></line>
                                           </svg>
                                        </span>
                                        Remove
                                     </a>
                                  </div>
                               </>
                            ))}
 
                            
                         </li>
                      </ul>
                   </div>
                </div>
                <div className="checkout-total clr">
                    <ul>
                      <li className="grand finalTotalLi clr total-amount"><label for="" style={{color:'#fff',marginRight:'10px'}} className="">Total - </label><span className="value num">${this.state.totalCart?.cart_total}<strong></strong></span></li>
                      </ul>
                      {/* <div className="feeNote">
                      <p><strong>Note:</strong> All values are inclusive of Tax. <a href="/terms-service" className="link" target="_blank">Click here</a> to view details regarding Tax and additional Fee.</p>
                      </div>  */}
                   <div><button type="button" className="custom-btn gradient float-right lg proccedToCheckout" onClick={this.openCity.bind(this,'payment')}><span className="txt">proceed to checkout</span><span className="icon"><i className="" data-feather="arrow-right"></i></span></button></div>
                </div>
          </div>
          <div id="payment" className="payment tab-pane" style={{display:this.state.paymentActive === 'active'? 'block':'none'}}>
             <div className="row">
                <div className="col-md-7 col-lg-7">
                   <div>
                      <div className="paymentMethodNew saved-card clr section">
                         <h2 className="section-title">Payment Method</h2>
                         <div className="section-content clr">
                            <ul>
                               <li className="clr">
                                  <div className="txtHolder">
                                     <label className="custom-radio-holder">
                                        <input type="radio" name="payment_type" value="WALLET" id="payment_wallet" checked=""/><span className="checkmark"></span>
                                        <div className="txt">Pay with Mercafor Crypto Wallet</div>
                                     </label>
                                  </div>
                                  <div className="rightOption"><strong className="num">Current Balance: <span>$0.00</span></strong></div>
                               </li>
                               <li className="clr">
                                  <div>
                                     <label className="custom-radio-holder">
                                        <input type="radio" name="payment_type" id="payment_card" value="CARD"/><span className="checkmark"></span>
                                        <div className="txt">Pay with new Credit Card</div>
                                     </label>
                                  </div>
                               </li>
                            </ul>
                            <form id="payment_methode">
                               <div className="row">
                                  <div className="col-sm-12">
                                     <div className="form-group">
                                         <label for="email">Card Number</label>
                                         <input type="number" className="form-control" id="email" maxlength="12" placeholder="1234 1234 1234 1234"/>
                                       </div>
                                  </div>
                                  <div className="col-sm-6">
                                     <div className="form-group">
                                         <label for="date">Expiration Date</label>
                                         <input type="date" className="form-control" id="date" placeholder="Expiration Date"/>
                                       </div>
 
                                  </div>
                                  <div className="col-sm-6">
                                     <div className="form-group">
                                         <label for="email">CVC</label>
                                         <input type="text" className="form-control" id="CVC" placeholder="CVC"/>
                                       </div>
 
                                  </div>
                                  
                               </div>
                            </form>
                         </div>
                      </div>
                   </div>
 
                   <div className="add-card clr section">
                      <div className="section-content clr">
 
                         <form id="payment-form"><button className="custom-btn gradient md">Pay now</button></form>
                      </div>
                   </div>
                </div>
                <div className="col-md-5 col-lg-5 desk-only">
                   <div className="order-details ">
                      <h2 className="section-title text-black">Order Details</h2>
                      <ul>
                         <li className="clr itemLi"><label for="" className=""><strong>Like father, like son</strong></label><span className="value">$35.00</span></li>
                         <li className="clr grand"><label for="" className="">Card Processing Fee</label><span className="value">0%</span></li>
                         <li className="clr grand finalTotalLi"><label for="" className=""><strong>Total</strong></label><span className="value num">$35.00&nbsp;<strong>(0.0177 ETH)</strong></span></li>
                      </ul>
                   </div>
                   <a href="#javascript" onClick={this.openCity.bind(this,'checkout')} className="go-back-link">
                      <span className="icon">
                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left">
                            <line x1="19" y1="12" x2="5" y2="12"></line>
                            <polyline points="12 19 5 12 12 5"></polyline>
                         </svg>
                      </span>
                      GO BACK
                   </a>
                </div>
             </div>
          </div>
          <div id="confirmation" className="confirmation tab-pane" style={{display:this.state.confirmationActive === 'active'? 'block':'none'}}>
             <div className="row">
                <div className="col-md-3 col-lg-3">
                   
                </div>
                <div className="col-md-6 col-lg-6 text-center">
                   <div className="confirm-payment">
                      <img src="img/check.png" width="80px"/>
                      <h3>Payment Complete</h3>
                      <p>Thank you, payment has been successfull.A confirmation email has been sent <a href="#">matt@example.com</a></p>
                      <button className="btn btn-primary">ok</button>
                   </div>
                </div>
                <div className="col-md-3 col-lg-3">
                   
                </div>
             </div>
          </div>
       </div>
     :
     <div className="row">
        <div className="col-md-12">
     <h1 style={{textAlign:'center',float:'initial'}}>{this.state.falseMsg}</h1>
        </div>
        </div>
      }
           {/* {alert(this.state.falseMsg)} */}
           </div>
      </section>
       </div>
       
      <Footer/>


          </>

    )
}
}