import React, { Component } from 'react';
import axios from 'axios';
import config from './config/config'
import Header from './directives/header1'
import Footer1 from './directives/footer1'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const headers = {
    'Content-Type': 'application/json'
};
const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

export default class register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password2: '',
            is_subscribed: '0',
            passwordMsg: '',
            password2Valid: false,
            eyelogin: true,
            eyelogin1: true,


        };
        const { match: { params } } = this.props;
        this.token = params.token

    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'password') {

            if (strongRegex.test(e.target.value)) {
                this.setState({
                    passwordMsg: ''
                })

            } else if (mediumRegex.test(e.target.value)) {
            }
            else if (e.target.value === '') {
                this.setState({
                    passwordMsg: 'Password is required'
                })

            }
            else {
                console.log(e.target.value);
                this.setState({
                    passwordMsg: 'Password length must contain 8 and at least 1 lowercase, 1 special character , 1 uppercase, 1 Digit'
                })
            }
        }
        else if (e.target.name === 'password2') {
            if (this.state.password !== e.target.value) {
                this.setState({
                    password2Valid: true
                })
            }
            else {
                this.setState({
                    password2Valid: false
                })
            }
        }
    }


    eyeClick(id) {
        if (id === 1) {
            this.setState({
                eyelogin: false
            })
        }
        else if (id === 2) {
            this.setState({
                eyelogin: true
            })
        }

    }

    eyeClick1(id) {
        if (id === 1) {
            this.setState({
                eyelogin1: false
            })
        }
        else if (id === 2) {
            this.setState({
                eyelogin1: true
            })
        }

    }

    componentDidMount() {
        if (this.token) {
            this.verifyAccountAPI()
        }
    }

    async verifyAccountAPI() {
        axios.post(`${config.apiUrl}/verifyAccount/` + this.token, { headers })
            .then(result => {

                if (result.data.success === true) {
                    toast.success(result.data.msg, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                    setTimeout(() => {

                        window.location.href = `${config.baseUrl}login`

                    }, 3500);
                }
                if (result.data.success === false) {
                    toast.error(result.data.msg, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(err => {
                // console.log(err);
                toast.error(err.response.data?.msg, {
                    position: toast.POSITION.TOP_CENTER,
                })
            });
        setTimeout(() => {
            window.location.href = `${config.baseUrl}login`
        }, 3500);
    }


    handleSubmit = event => {
        event.preventDefault();
        if (this.state.is_subscribed === '0') {

            this.setState({
                subscribeMsg: true,
                spinLoader: '0'
            })
        }
        
        else {
            const { first_name, last_name, email, password, password2 } = this.state;


            axios.post(`${config.apiUrl}/register`, { email, password, password2 })
                .then(result => {

                    if (result.data.success === true) {
                        toast.success(result.data.msg, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        setTimeout(() => {

                            // window.location.reload()
                            window.location.href = `${config.baseUrl}login`
                        }, 2000);
                    }
                }).catch(err => {
                    // console.log(err);
                    toast.error(err.response.data?.msg, {
                        position: toast.POSITION.TOP_CENTER, autoClose: 1500

                    }, setTimeout(() => {

                    }, 500));

                })
        }

    }

    clickChange(id) {
        if (id === 0) {

            this.setState({
                is_subscribed: '1',
                subscribeMsg: false,
            })
        }
        else if (id === 1) {


            this.setState({
                is_subscribed: '0',
                subscribeMsg: true,
            })
        }
    }

    render() {
        const { email, password, password2 } = this.state;
        return (
            <>
                <div className="body_bg">
                    <Header />
                    <section className="product_section pt-5 pb-5 mb-4">
                        <div className="container">
                            <div className="row  ">
                                <div className="col-sm-4">

                                </div>
                                <div className="col-sm-4 mt-5">
                                    <div className="login-wrap mt-5 py-4">

                                        <h2 className="text-center mb-4">Sign Up</h2>

                                        <form action="#" className="login-form">
                                            <ToastContainer />

                                            <div className="form-group mb-4">
                                                <div className="icon d-flex align-items-center justify-content-center"></div>
                                                <input type="email" className="form-control authCss" placeholder="Email" name="email" onChange={this.handleChange} autoComplete="none" value={email} />
                                            </div>
                                            <div className="form-group mb-4">
                                                <div className="icon d-flex align-items-center justify-content-center"></div>
                                                <input type={this.state.eyelogin === true ? "password" : 'text'} className="form-control authCss" placeholder="Password" name="password" onChange={this.handleChange} autoComplete="none" value={password} />
                                                {this.state.eyelogin === true ?
                                                    <i className="eyelogin1 far fa-eye" onClick={this.eyeClick.bind(this, 1)}></i> :
                                                    <i className="eyelogin1 fas fa-eye-slash" onClick={this.eyeClick.bind(this, 2)}></i>
                                                }
                                                <div style={{ color: 'red' }}>{this.state.passwordMsg ? this.state.passwordMsg : ''}</div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <div className="icon d-flex align-items-center justify-content-center"></div>
                                                <input type={this.state.eyelogin1 === true ? "password" : 'text'} className="form-control authCss" placeholder="Confirm password" name="password2" onChange={this.handleChange} autoComplete="none" value={password2} />
                                                {this.state.eyelogin1 === true ?
                                                    <i className="eyelogin2 far fa-eye" onClick={this.eyeClick1.bind(this, 1)}></i> :
                                                    <i className="eyelogin2 fas fa-eye-slash" onClick={this.eyeClick1.bind(this, 2)}></i>
                                                }
                                                <div style={{ color: 'red' }}>{this.state.password2Valid === true ? 'Password not match' : ''}</div>
                                            </div>
                                            {/* <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" checked id="exampleCheck1"/>
                                <label className="form-check-label" id="term_text" for="exampleCheck1"> I accept the Terms of Use &amp; Privacy Policy</label>
                            </div> */}

                                            <div className="form-group form-check">
                                                {this.state.is_subscribed === '1'
                                                    ?
                                                    <input className="form-check-input" id="exampleCheck1" style={{ top: '-13px' }} type="checkbox" checked onClick={this.clickChange.bind(this, 1)} name="remember-me" />
                                                    : <input className="form-check-input" id="exampleCheck1" style={{ top: '-13px' }} type="checkbox" onClick={this.clickChange.bind(this, 0)} name="remember-me" />

                                                }

                                                <label className="form-check-label" for="exampleCheck1">I agree with <Link to={`${config.baseUrl}terms_and_condition`} target="_blank">
                                                    Terms and Conditions</Link> and &nbsp;<Link to={`${config.baseUrl}privacy_policy`} target="_blank">Privacy Policy.</Link></label>
                                                {this.state.subscribeMsg === true ? <><p style={{ color: 'red' }}>Please Fill the checkbox</p></> : ''}
                                            </div>

                                            <div className="form-group">
                                                <button type="submit" value="Sign Up" onClick={this.handleSubmit}
                                                    disabled={!email || !password || !password2 || this.state.passwordMsg || this.state.password2Valid} className="btn form-control authCss btn-primary rounded submit px-3">Sign Up</button>
                                            </div>
                                        </form>
                                        <div className="w-100 text-center mt-4 text">
                                            <p className="mb-0">Already have an account?</p>
                                            <a href={`${config.baseUrl}login`}>Log In</a>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-4">

                                </div>


                            </div>

                        </div>
                    </section>
                </div>
                <Footer1 />
            </>
        )
    }
}