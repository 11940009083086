import React, { Component } from 'react';
import config from '../config/config'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Web3 from 'web3';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export default class InnerHeader extends Component {

   constructor(props) {
      super(props)
      window.scrollTo({top: 0, left: 0 }); 
      this.loginData = (!Cookies.get('artfungibleUserloginSuccess')) ? [] : JSON.parse(Cookies.get('artfungibleUserloginSuccess'))
      this.state = {
         listCartData: [],
         ConnectWalletAddress: '',
         getProfilePicAPIData : '',
         searchDataList:[],
      }
      this.onChange = this.onChange.bind(this)
   }
   componentDidMount() {
      this.listCartAPI()
      setTimeout(() => {
         if (window.ethereum) {
            const { ethereum } = window;
            this.setState({
               ConnectWalletAddress: ethereum.selectedAddress
            })
            localStorage.setItem('walletType', 'metamask')
         }
      }, 1000);
      this.getProfilePicAPI()


   }

   async getProfilePicAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/getProfilePic`,
         headers: { "Authorization": this.loginData.message },
         data: { "email": this.loginData.data?.user_email }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               getProfilePicAPIData: response.data?.response
            })
         }
      })
   }   

   async connectMetasmask() {
      if (window.ethereum) {
         await window.ethereum.send('eth_requestAccounts');
         window.web3 = new Web3(window.ethereum);
         const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
         this.setState({
            ConnectWalletAddress: accounts
         })
         localStorage.setItem('walletType', 'metamask')
      }
      else {
         toast.error(`Please install MetaMask to use this dApp!`, {
            position: toast.POSITION.TOP_CENTER
         });
      }
   }


   //====================================== Cart list  =============================

   async listCartAPI() {
      await axios({
         method: 'post',
         url: `${config.apiUrl}/listCart`,
         //  headers: { "Authorization": this.loginData.message },
         data: { "user_id": this.loginData.data?.id }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({
               listCartData: response.data?.data
            })
         }
      })
   }

   logout() {
      Cookies.remove('artfungibleUserloginSuccess')
      setTimeout(() => {

         window.location.reload()
      });
   }

   onChange = e =>{
      this.setState({
          [e.target.name] : e.target.value
       })

       if(e.target.name === 'searchData'){
          this.allSearchAPI(e.target.value)
       }
   }

   async allSearchAPI(id) {
      // e.preventDefault()
      await axios({
         method: 'post',
         url: `${config.apiUrl}/allSearch`,
         headers: { "Authorization": this.loginData?.message },
         data: { "search":  id }
      }).then(response => {
         if (response.data.success === true) {
            this.setState({	
               searchDataList: response.data.response
            })
            // console.log(this.state.searchDataList);
         }
         else if (response.data.success === false) {
         }
      }).catch(err => {
         this.setState({	
            searchDataList: []
            })
            // console.log(this.state.searchDataList);

      })
      }

      loading(id) {
         setTimeout(() => {
             window.location.href = `${config.baseUrl}marketplaceDetail/${id}`
             window.location.reload(true)
         }, 500);
     }

   render() {
      return (

         <>    <ToastContainer />

            <header className="main_menu single_page_menu" style={{ boxShadow: '0 0 15px rgb(240 233 233 / 79%)' }}>
               <div className="container-fluid">
                  <div className="row align-items-center pl-4 pr-4">
                     <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg navbar-light">
                           <a className="navbar-brand" href="#"> <img src="img/coin-fmx.gif" alt="logo" className="Main_logo" /><br/>
                              <span className="main_logo_text">Art Fungible</span></a> 
                           <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                              <span className="menu_icon"><i className="fas fa-bars"></i></span>
                           </button>
                           <div className="collapse navbar-collapse main-menu-item" id="navbarSupportedContent">
                              <ul className="navbar-nav">
                                 <li className="nav-item">
                                    <Link to={`${config.baseUrl}marketplace`} className="nav-link" href="#">Marketplace</Link>
                                 </li>
                                 <li className="nav-item">
                                    <a className="nav-link" href={`${config.baseUrl}introduction`}>Introduction</a>
                                 </li>
                                 <li className="nav-item">
                                    <a className="nav-link" href={`${config.baseUrl}kolect_token`}>Kolect Token</a>
                                 </li>
                              </ul>

                              <ul className="navbar-nav" id="mobile_side_nav">
                              {/* <li className="nav-item dropdown">
                              <a className="nav-link " id="navbarDropdown" data-toggle="dropdown" href="#"><i className="fas fa-search"></i></a>
                              <div className="dropdown-menu search-dropdown" aria-labelledby="navbarDropdown" >
                                 <div className="input-group">
                                    <div className="form-outline">
                                       <input type="search" id="form1" className="form-control"  placeholder="search..." />
                                    </div>
                                 </div>
                              </div>
                           </li> */}
                              {/* <li className="nav-item dropdown">
                              <a className="nav-link" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                             <i className="fas fa-shopping-cart"></i>
                              </a>
                              {/* <!-- <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                 <a className="dropdown-item connect_wallet" href="#"> Connect Wallet</a>
                                
                              </div> --> */}
                              {/* </li>   */}
                            
                              <li className="nav-item">
                                    <div className="col-md-12 search-box-input" >
                                       <form className="input-search" onSubmit={(e => e.preventDefault())}>
                                       <input type="text" autoComplete="off"  className="form-control" value={this.state.searchData} name="searchData" onChange={this.onChange} placeholder="Search"/>
                                       <i class="fa fa-search"></i>
                                       {/* <li>sadasda</li> */}
                                       <ul className="search_ul" style={{display:this.state.searchDataList.length === 0 ?'none':'block',overflowX:'hidden',overflowY: "auto", height: "300px"}}>
                                             {this.state.searchDataList.map(item => {

                                          
                                             return (
                                                
                                                
                                                <Link to={`${config.baseUrl}marketplaceDetail/${item.id}`} onClick={this.loading.bind(this, item.id)}>
                                                <li style={{ cursor: 'pointer', textAlign: 'left', width: '100%',color:'#000',height:'60px' }} title={item.name} >
                                                
                                                <img src={item.image === null || item.image === '' || item.image === undefined 
                                                            ? 'images/team2.jpg' 
                                                            :
                                                            `${config.imageUrl}${item.image}`} style={{ height: '50px', width: '50px', borderRadius: '50%', marginTop : "-13px" }} alt=""/>
                                                      <span data-id={item.id} style={{ marginLeft: "10px", color:"#000" }}>{item.name}</span>

                                                
                                             </li>  
                                             </Link>                                              
                                             
                                             
                                          )

                                             })}
                                       </ul>
                                             {/* </datalist> */}
                                       </form>
                                    </div>                  
                                 </li>                            
                           

                              <li className="nav-item">
                           {(this.state.ConnectWalletAddress)?
                              <a className="nav-link btn-wallet"  target="_blank"  href={`${config.blockchinUrl}address/${this.state.ConnectWalletAddress}`}><button className="connect_wallet ">{this.state.ConnectWalletAddress.toString().substring(0,4)+'...'+this.state.ConnectWalletAddress.toString().substr(this.state.ConnectWalletAddress.length-4)}</button></a>
                              :
                              <a onClick={this.connectMetasmask.bind(this)} className="nav-link btn-wallet"  href="#javascript" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><button className="connect_wallet " href="#javascript"> Connect Wallet</button></a>
                              }
                           </li>
                              {/* <!-- <li className="nav-item">
                              <a className="nav-link" href="#"><i className="fab fa-opencart"></i></a>
                              </li> --> */}
                              {/* <li className="nav-item">
                              <a className="nav-link" href="#"><i className="fas fa-scrubber"></i></a>
                           </li> */}
                              {/* <!-- <li className="nav-item "><a className="nav-link modeToggle clr" href="#header"><span></span></a></li> --> */}
                              <li className="nav-item dropdown">
                                 <a className="nav-link dropdown-toggle" href="blog.html" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {/* <img src="img/gas-mask.png" className="mask-icons" /> */}

                                    <img src={(this.state.getProfilePicAPIData.profile_pic === '' || this.state.getProfilePicAPIData.profile_pic === undefined || this.state.getProfilePicAPIData.profile_pic === null) ? "img/gas-mask.png" : `${config.imageUrl1}/${this.state.getProfilePicAPIData.profile_pic}`} className="mask-icons" />

                                 </a>
                                 <div className="dropdown-menu" id="menuHeader" aria-labelledby="navbarDropdown">
                                    {this.loginData == '' ?
                                       '' :
                                       <>
                                          <Link to={`${config.baseUrl}userprofile`} className="dropdown-item"> Profile</Link>
                                          <Link to={`${config.baseUrl}whishlist`} className="dropdown-item">Wishlist</Link>
                                          <Link to={`${config.baseUrl}transaction`} className="dropdown-item">My NFT</Link>
                                       </>
                                    }
                                    {this.loginData == ''
                                       ?
                                       <Link to={`${config.baseUrl}login`} className="dropdown-item">LOGIN / REGISTER</Link>
                                       : <Link to={`${config.baseUrl}`} onClick={this.logout.bind(this)} className="dropdown-item" >Logout</Link>}
                                 </div>
                              </li>
                           </ul>

                           </div>



                           <ul className="navbar-nav" id="side_nav">
                              {/* <li className="nav-item dropdown">
                              <a className="nav-link " id="navbarDropdown" data-toggle="dropdown" href="#"><i className="fas fa-search"></i></a>
                              <div className="dropdown-menu search-dropdown" aria-labelledby="navbarDropdown" >
                                 <div className="input-group">
                                    <div className="form-outline">
                                       <input type="search" id="form1" className="form-control"  placeholder="search..." />
                                    </div>
                                 </div>
                              </div>
                           </li> */}

                              {/* <li className="nav-item dropdown">
                              <Link to={`${config.baseUrl}cart`} className="nav-link" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                             <i className="fas fa-shopping-cart"><span className="badge badge-count">{this.state.listCartData.length}</span></i>
                              </Link>
                         </li>   */}

<li className="nav-item">
                                    <div className="col-md-12 search-box-input" >
                                       <form className="input-search" onSubmit={(e => e.preventDefault())}>
                                       <input type="text" autoComplete="off"  className="form-control" value={this.state.searchData} name="searchData" onChange={this.onChange} placeholder="Search"/>
                                       <i class="fa fa-search"></i>
                                       {/* <li>sadasda</li> */}
                                       <ul className="search_ul" style={{display:this.state.searchDataList.length === 0 ?'none':'block',overflowX:'hidden',overflowY: "auto", height: "300px"}}>
                                             {this.state.searchDataList.map(item => {

                                          
                                             return (
                                                
                                                
                                                <Link to={`${config.baseUrl}marketplaceDetail/${item.id}`} onClick={this.loading.bind(this, item.id)}>
                                                <li style={{ cursor: 'pointer', textAlign: 'left', width: '100%',color:'#000',height:'60px' }} title={item.name} >
                                                
                                                <img src={item.image === null || item.image === '' || item.image === undefined 
                                                            ? 'images/team2.jpg' 
                                                            :
                                                            `${config.imageUrl}${item.image}`} style={{ height: '50px', width: '50px', borderRadius: '50%', marginTop : "-13px" }} alt=""/>
                                                      <span data-id={item.id} style={{ marginLeft: "10px", color:"#000" }}>{item.name}</span>

                                                
                                             </li>  
                                             </Link>                                              
                                             
                                             
                                          )

                                             })}
                                       </ul>
                                             {/* </datalist> */}
                                       </form>
                                    </div>                  
                                 </li> 

                              <li className="nav-item ">
                                 {(this.state.ConnectWalletAddress) ?
                                    <a className="nav-link btn-wallet" target="_blank" href={`${config.blockchinUrl}address/${this.state.ConnectWalletAddress}`}><button className="connect_wallet">{this.state.ConnectWalletAddress.toString().substring(0, 4) + '...' + this.state.ConnectWalletAddress.toString().substr(this.state.ConnectWalletAddress.length - 4)}</button></a>
                                    :
                                    <a onClick={this.connectMetasmask.bind(this)} className="nav-link btn-wallet" href="#javascript" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><button className="connect_wallet" href="#javascript"> Connect Wallet</button></a>
                                 }
                              </li>
                              {/* <!-- <li className="nav-item">
                              <a className="nav-link" href="#"><i className="fab fa-opencart"></i></a>
                              </li> --> */}
                              {/* <li className="nav-item">
                              <a className="nav-link" href="#"><i className="fas fa-scrubber"></i></a>
                           </li> */}
                              {/* <!-- <li className="nav-item "><a className="nav-link modeToggle clr" href="#header"><span></span></a></li> --> */}
                              <li className="nav-item dropdown">
                                 <a className="nav-link dropdown-toggle" href="blog.html" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {/* <img src="img/gas-mask.png" className="mask-icons" /> */}

                                    <img src={(this.state.getProfilePicAPIData.profile_pic === '' || this.state.getProfilePicAPIData.profile_pic === undefined || this.state.getProfilePicAPIData.profile_pic === null) ? "img/gas-mask.png" : `${config.imageUrl1}/${this.state.getProfilePicAPIData.profile_pic}`} className="mask-icons" />
                                 </a>
                                 <div className="dropdown-menu" id="menuHeader" aria-labelledby="navbarDropdown">
                                    {this.loginData == '' ?
                                       '' :
                                       <>
                                          <Link to={`${config.baseUrl}userprofile`} className="dropdown-item"> Profile</Link>
                                          <Link to={`${config.baseUrl}whishlist`} className="dropdown-item">Wishlist</Link>
                                          <Link to={`${config.baseUrl}transaction`} className="dropdown-item">My NFT</Link>
                                       </>
                                    }

                                    {this.loginData == ''
                                       ?
                                       <Link to={`${config.baseUrl}login`} className="dropdown-item">LOGIN / REGISTER</Link>
                                       : <Link to={`${config.baseUrl}`} onClick={this.logout.bind(this)} className="dropdown-item" >Logout</Link>}
                                 </div>
                              </li>
                           </ul>
                        </nav>
                     </div>
                  </div>
               </div>
            </header>

         </>
      )
   }
}