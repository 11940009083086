import React, { Component } from "react";
import Footer1 from "../directives/footer1";
import Header1 from "../directives/header1";
import Physicalslider from "./physicalslider";
import Modalone from "./modalone";
import Modalsecond from "./modalsecond";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Cookies from "js-cookie";
import axios from "axios";
import "slick-carousel/slick/slick-theme.css";
import config from "../config/config";
import Web3 from "web3";
import Swal from "sweetalert2";
import { Dialog } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import BarLoader from "react-bar-loader";
import { getCurrentProvider } from "../components/provider";
import Popup from "../popup";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
const MAX_LENGTH = 320;

export default class Physicalnftview extends Component {
  constructor(props) {
    super(props);
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));
    const {
      match: { params },
    } = this.props;
    this.itemId = params.id;

    this.setState({
      physicalNftViewData: "",
      isDialogOpen: false,
      isPopupError: false,
      isPopupSuccess: false,
      msg: false,
      shippingAddressData: "",
      multiplePhysicalNftData: [],
      setOpen: false,
      agreed: false,
      type: "",
      activeIndex: 0,
      amount: "",
    });
    this.sliderRef = React.createRef();
    this.handleAfterChange = this.handleAfterChange.bind(this);
  }

  handleAfterChange(index) {
    // Update active index after slide change
    this.setState({ activeIndex: index });
  }

  componentDidMount() {
    this.getPhysicalItemAPI();
    this.getshippingAddressAPI();
    this.multiplePhysicalNftAPI();
  }

  handlePreviewClick = (index) => {
    this.setState({ activeIndex: index });
  };

  // goToSlide = (index) => {
  //   this.slider.slickGoTo(index);
  //   this.setState({ activeIndex: index });
  // };

  async getshippingAddressAPI() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}userShippingAddress`,
        data: { userId: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            shippingAddressData: response.data,
          });
        } else {
          this.setState({
            shippingAddressData: "",
          });
        }
      });
    } catch (err) {
      this.setState({
        physicalNftViewData: "",
      });
    }
  }

  async multiplePhysicalNftAPI() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}multiplePhysicalNft`,
        data: { item_id: this.itemId },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success == true) {
          this.setState({
            multiplePhysicalNftData: response.data.data,
          });
        }
      });
    } catch (err) {}
  }

  handleCheckboxChange = () => {
    this.setState({ agreed: !this.state.agreed });
  };

  async onOpenModal() {
    this.setState({
      setOpen: true,
    });
  }
  async onCloseModal() {
    this.setState({
      setOpen: false,
      // agreed: !this.state.agreed
    });
  }

  async getPhysicalItemAPI() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}physicalnftview`,
        data: { item_id: this.itemId },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            physicalNftViewData: response.data.data,
          });
          if (response.data.data.isSold == 1) {
            window.location.href = `${
              config.baseUrl + "membership/" + this.loginData.data.walletAddress
            }`;
          }
        } else {
          this.setState({
            physicalNftViewData: [],
          });
        }
      });
    } catch (err) {
      this.setState({
        physicalNftViewData: [],
      });
    }
  }

  async amountSendToAdmin(type, amount) {
    if (this.state.shippingAddressData?.shippingAddress == "") {
      window.location.href = `${config.baseUrl}setting`;
    } else {
      this.setState({
        setOpen: true,
        type: type,
        amount: amount,
        agreed: false,
      });
    }
  }

  async termsModal() {
    let adminWallet = this.state.physicalNftViewData.adminWallet;
    let userAddress = this.loginData.data.walletAddress;
    let amount = this.state.amount;
    try {
      const providers = await getCurrentProvider();
      const web3 = new Web3(providers);
      var currentNetwork = web3.currentProvider.chainId;

      if (this.loginData.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Please connect wallet!!",
        });
        return;
      }  const rpcUrl =
      this.state.physicalNftViewData.artistType == 0 ? config.ethRpcUrl : config.baseRpcUrl;
    if (this.state.physicalNftViewData.artistType == 0) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(config.chainId) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(config.chainId),
                  chainName: "BNB",
                  rpcUrls: [`${rpcUrl}`],
                  nativeCurrency: {
                    name: "BNB",
                    symbol: "BNB",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://base-sepolia.blockscout.com"],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
      }
    } else if (this.state.physicalNftViewData.artistType == 1) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(config.chainIdSep) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(config.chainIdSep),
                  chainName: "Base Sepolia",
                  rpcUrls: [`${rpcUrl}`],
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://base-sepolia.blockscout.com"],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
      }
    }

      await axios({
        method: "post",
        url: `${config.apiUrl}physicalnftBuy`,
        data: {
          itemDetailId: this?.state?.physicalNftViewData.id,
          item_id: this.itemId,
          userId: this.loginData.data.id,
          orderStatus:
            this.state.type === "lg"
              ? 1
              : this.state.type === "md"
              ? 2
              : this.state.type === "sm"
              ? 3
              : this.state.type === "ex"
              ? 4
              : "",
          transactionHash: "",
          userAddress: this.loginData.data.walletAddress,
          ethAmount: amount,
        },
        headers: { Authorization: this.loginData.Token },
      }).then(async (response) => {
        if (response.data.success === true) {
          let balance = await web3.eth.getBalance(userAddress);
          this.setState({
            isDialogOpen: true,
          });

          if (balance < amount) {
            this.setState({
              isDialogOpen: false,
            });
            this.setState({
              isPopupError: true,
              msg: "Insufficient Balance",
            });
            setTimeout(() => {
              this.setState({
                isPopupError: false,
              });
            }, 2000);
            return;
          }

          let sendingAmount = amount * 10 ** 18;
          let gasPrice = await web3.eth.getGasPrice();
          let gasLimit = await web3.eth.estimateGas({
            gasPrice: web3.utils.toHex(gasPrice),
            to: adminWallet,
            from: userAddress,
            value: sendingAmount,
          });

          let trx = await web3.eth.sendTransaction({
            gasPrice: web3.utils.toHex(gasPrice),
            gas: web3.utils.toHex(gasLimit),
            to: adminWallet,
            from: userAddress,
            value: sendingAmount,
          });
          if (trx) {
            this.setState({
              isPopupSuccess: true,
              isDialogOpen: false,
              msg: "Purchase successfully",
            });
            setTimeout(() => {
              this.setState({
                isPopupSuccess: false,
              });
              window.location.href = `${
                config.baseUrl +
                "membership/" +
                this.loginData.data.walletAddress
              }`;
            }, 2000);
          }
        } else {
        }
      });
    } catch (error) {
      if (error.message.toString().split("insufficient funds")[1]) {
        this.setState({
          isPopupError: true,
          isDialogOpen: false,
          msg: "Transaction reverted : " + error.message,
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
      } else {
        if (error.toString().split("execution reverted:")[1]) {
          this.setState({
            isPopupError: true,
            msg:
              "Transaction reverted : " +
              error
                .toString()
                .split("execution reverted:")[1]
                .toString()
                .split("{")[0],
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
              isDialogOpen: false,
            });
          }, 2000);

          this.setState({
            isDialogOpen: false,
          });
        } else {
          this.setState({
            isDialogOpen: false,
          });
          this.setState({
            isPopupError: true,
            msg: error.message,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
              isDialogOpen: false,
            });
          }, 2000);
        }
      }
    }
  }

  render() {
    // const { multiplePhysicalNftData } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      slidesToScroll: 1,
      showThumbs: true,
      afterChange: this.handleAfterChange, // Add afterChange event handler
    };
    return (
      <>
        <Header1 />

        <Dialog
          title="Warning"
          icon="warning-sign"
          className="transaction-process"
          style={{
            color: "red",
            zIndex: "1111",
          }}
          isOpen={this.state?.isDialogOpen}
          isCloseButtonShown={false}
        >
          <div className="text-center">
            <BarLoader color="#e84747" height="2" />
            <br />
            <h4 style={{ color: "#000" }}>Transaction under process...</h4>
            <p style={{ color: "red" }}>
              Please do not refresh page or close tab.
            </p>
            <div></div>
          </div>
        </Dialog>
        <Popup
          isPopupError={this.state?.isPopupError}
          isPopupSuccess={this.state?.isPopupSuccess}
          msg={this.state?.msg}
        />
        <section className="physical_sec py-lg-5 py-3">
          <div className="container-fluid px-lg-5">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-12">
                <div className="physical_box p-4">
                  <div className="top_per">
                    {/* number_per */}
                    {/* <div className="">
                      <span>
                        {this?.state?.physicalNftViewData?.printInformation}
                      </span>
                    </div> */}
                    {/* <img src="assets/icons/like.svg" alt='like' className='likeicon' /> */}
                    {/* <i class="far fa-heart"></i> */}
                  </div>
                  <div>
                    <div className="slider_thumbs">
                      <Slider
                        key={
                          this?.state?.activeIndex === undefined
                            ? 0
                            : this?.state?.activeIndex
                        }
                        {...settings}
                        ref={this.sliderRef}
                        // ref={(slider) => (this.slider = slider)}
                        initialSlide={
                          this?.state?.activeIndex === undefined
                            ? 0
                            : this?.state?.activeIndex
                        }
                      >
                        {this?.state?.multiplePhysicalNftData?.map(
                          (item, index) => (
                            <div className="item-slide" key={index}>
                              {item.imageFileType == "image" ? (
                                <a
                                  href={config.imageUrl1 + item.image}
                                  target="_blank"
                                >
                                  <img
                                    src={config.imageUrl1 + item.image}
                                    alt={`Slide ${index + 1}`}
                                  />
                                </a>
                              ) : (
                                <a
                                  href={config.imageUrl1 + item.image}
                                  target="_blank"
                                >
                                  <video
                                    src={config.imageUrl1 + item.image}
                                    alt={`Slide ${index + 1}`}
                                  />
                                </a>
                              )}
                            </div>
                          )
                        )}
                      </Slider>
                    </div>
                    <div
                      className="preview-images"
                      style={{ textAlign: "center" }}
                    >
                      {this?.state?.multiplePhysicalNftData?.map(
                        (item, index) =>
                          item.imageFileType == "image" ? (
                            <img
                              key={index}
                              src={config.imageUrl1 + item.image}
                              alt={`Preview ${index + 1}`}
                              style={{
                                height: "100px",
                                width: "100px",
                                marginLeft: "15px",
                                cursor: "pointer",
                                marginBottom: "60px",
                              }}
                              className={`preview-image ${
                                this.state.activeIndex === index ? "active" : ""
                              }`}
                              onClick={() => this.handlePreviewClick(index)}
                            />
                          ) : (
                            <video
                              key={index}
                              src={config.imageUrl1 + item.image}
                              alt={`Preview ${index + 1}`}
                              style={{
                                height: "100px",
                                width: "100px",
                                marginLeft: "15px",
                                cursor: "pointer",
                              }}
                              className={`preview-image ${
                                this.state.activeIndex === index ? "active" : ""
                              }`}
                              onClick={() => this.handlePreviewClick(index)}
                            />
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-12">
                <div className="shippnig-address">
                  <div className="fromHead mb-4 mt-3">
                    <h3>{this?.state?.physicalNftViewData?.name}</h3>
                    <h6 className="freeShip">Free shipping Worldwide</h6>
                  </div>
                  <ul>
                    {this?.state?.physicalNftViewData?.largeAmount &&
                    this?.state?.physicalNftViewData?.largeWidth &&
                    this?.state?.physicalNftViewData?.largeHeight ? (
                      <li>
                        <div className="ethblock">
                          <div
                            style={{ cursor: "pointer" }}
                            className="transactionDiv mb-2"
                            onClick={this.amountSendToAdmin.bind(
                              this,
                              "lg",
                              this?.state?.physicalNftViewData?.largeAmount
                            )}
                          >
                            <span>
                              {this?.state?.physicalNftViewData?.largeAmount}{" "}
                              {this?.state?.physicalNftViewData.artistType == 0 ? config.currencyType : config.currencyTypeSep}
                            </span>
                          </div>
                          <h6 className="freeShip">
                            {this?.state?.physicalNftViewData?.largeWidth} X{" "}
                            {this?.state?.physicalNftViewData?.largeHeight} cm
                          </h6>
                        </div>
                      </li>
                    ) : (
                      ""
                    )}
                    {this?.state?.physicalNftViewData?.mediumAmount &&
                    this?.state?.physicalNftViewData?.mediumWidth &&
                    this?.state?.physicalNftViewData?.mediumHeight ? (
                      <li>
                        <div className="ethblock">
                          <div
                            style={{ cursor: "pointer" }}
                            className="transactionDiv mb-2"
                            onClick={this.amountSendToAdmin.bind(
                              this,
                              "md",
                              this?.state?.physicalNftViewData?.mediumAmount
                            )}
                          >
                            <span>
                              {this?.state?.physicalNftViewData?.mediumAmount}{" "}
                              {this?.state?.physicalNftViewData.artistType == 0 ? config.currencyType : config.currencyTypeSep}
                            </span>
                          </div>
                          <h6 className="freeShip">
                            {this?.state?.physicalNftViewData?.mediumWidth} X{" "}
                            {this?.state?.physicalNftViewData?.mediumHeight} cm
                          </h6>
                        </div>
                      </li>
                    ) : (
                      ""
                    )}
                    {this?.state?.physicalNftViewData?.smallAmount &&
                    this?.state?.physicalNftViewData?.smallWidth &&
                    this?.state?.physicalNftViewData?.smallHeight ? (
                      <li>
                        <div className="ethblock">
                          <div
                            style={{ cursor: "pointer" }}
                            className="transactionDiv mb-2"
                            onClick={this.amountSendToAdmin.bind(
                              this,
                              "sm",
                              this?.state?.physicalNftViewData?.smallAmount
                            )}
                          >
                            <span>
                              {this?.state?.physicalNftViewData?.smallAmount}{" "}
                              {this?.state?.physicalNftViewData.artistType == 0 ? config.currencyType : config.currencyTypeSep}
                            </span>
                          </div>
                          <h6 className="freeShip">
                            {this?.state?.physicalNftViewData?.smallWidth} X{" "}
                            {this?.state?.physicalNftViewData?.smallHeight} cm
                          </h6>
                        </div>
                      </li>
                    ) : (
                      ""
                    )}
                    <div>
                      {this?.state?.physicalNftViewData?.printInformation
                        ?.length > MAX_LENGTH ? (
                        <h6
                          className="freeShip"
                          title={
                            this?.state?.physicalNftViewData?.printInformation
                          }
                        >
                          {`${this?.state?.physicalNftViewData?.printInformation.substring(
                            0,
                            MAX_LENGTH
                          )}...`}
                        </h6>
                      ) : (
                        <h6
                          className="freeShip"
                          title={
                            this?.state?.physicalNftViewData?.printInformation
                          }
                        >
                          {this?.state?.physicalNftViewData?.printInformation}
                        </h6>
                      )}
                    </div>{" "}
                    <br />
                    {this?.state?.physicalNftViewData?.extraItemName ? (
                      <>
                        <h5>
                          {this?.state?.physicalNftViewData?.extraItemName}
                        </h5>
                        <li>
                          <div className="ethblock">
                            <div
                              style={{ cursor: "pointer" }}
                              className="transactionDiv mb-2"
                              onClick={this.amountSendToAdmin.bind(
                                this,
                                "ex",
                                this?.state?.physicalNftViewData
                                  ?.extraItemAmount
                              )}
                            >
                              <span>
                                {
                                  this?.state?.physicalNftViewData
                                    ?.extraItemAmount
                                }{" "}
                              {this?.state?.physicalNftViewData.artistType == 0 ? config.currencyType : config.currencyTypeSep}
                              </span>
                            </div>
                            <h6 className="freeShip">
                              {this?.state?.physicalNftViewData?.extraItemWidth}{" "}
                              X{" "}
                              {
                                this?.state?.physicalNftViewData
                                  ?.extraItemHeight
                              }{" "}
                              cm
                            </h6>
                          </div>
                        </li>
                        <div>
                          {this?.state?.physicalNftViewData
                            ?.extraItemInformation?.length > MAX_LENGTH ? (
                            <h6
                              className="freeShip"
                              title={
                                this?.state?.physicalNftViewData
                                  ?.extraItemInformation
                              }
                            >
                              {`${this?.state?.physicalNftViewData?.extraItemInformation.substring(
                                0,
                                MAX_LENGTH
                              )}...`}
                            </h6>
                          ) : (
                            <h6
                              className="freeShip"
                              title={
                                this?.state?.physicalNftViewData
                                  ?.extraItemInformation
                              }
                            >
                              {
                                this?.state?.physicalNftViewData
                                  ?.extraItemInformation
                              }
                            </h6>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          open={this.state?.setOpen}
          onClose={this.onCloseModal.bind(this)}
          center
        >
          <div className="modal-header mt-4">
            <h5 className="modal-title" id="exampleModalLabel">
              By continuing you agree to our Terms & Privacy Policy.
            </h5>
          </div>

          <div className="modal-body">
            <div className="genesischeck">
              <input
                className="styled-checkbox"
                id="styled-checkbox-1"
                type="checkbox"
                value={this.state?.agreed}
                onChange={this.handleCheckboxChange}
              />
              {/* <label htmlFor="styled-checkbox-1">I agree with <span>Terms and Conditions</span> and   <span> Privacy Policy </span></label> */}
              <label htmlFor="styled-checkbox-1">
                Agree Terms & Conditions &nbsp; (
                <a href="https://terms.artfungible.io/" target="_blank">
                  Click Here
                </a>{" "}
                &nbsp;to read)
              </label>
            </div>
          </div>

          <div className="modal-footer border-0 footerdiv">
            <button
              type="submit"
              disabled={!this.state?.agreed}
              onClick={this.termsModal.bind(this)}
              className="btn btn-buy-primary px-5"
            >
              Buy{" "}
            </button>
          </div>
        </Modal>

        <Footer1 />
      </>
    );
  }
}
