import React, { Component } from "react";
import config from "../config/config";
import Cookies from "js-cookie";
import axios from "axios";
import Web3 from "web3";
import { getCurrentProvider } from "../components/provider";
import Popup from "../popup";
import Modalone from "../components/modalone";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export default class header1 extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, left: 0 });
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));
    this.state = {
      ConnectWalletAddress: "",
      getProfilePicAPIData: "",
      searchDataList: [],
      artistList: [],
      headerSettingsForUserData: [],
      lastSegment: "",
      setOpen: false,
      accountStore: "",
      agreed: false,
    };
    this.connectMetasmask = this.connectMetasmask.bind(this);
    this.loginCheck = this.loginCheck.bind(this);
  }
  componentDidMount() {
    const { ethereum } = window;
    setTimeout(() => {
      if (window.ethereum) {
        this.setState({
          ConnectWalletAddress: ethereum.selectedAddress,
        });
      }
    }, 1000);
    setInterval(async () => {
      if (ethereum && this.loginData?.success) {
        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        const account = await web3.eth.getAccounts();

        if (this.state.ConnectWalletAddress == null) {
          this.setState({
            ConnectWalletAddress: account[0],
          });
        }

        if (
          account[0]?.toUpperCase() !==
          this.state.ConnectWalletAddress?.toUpperCase()
        ) {
          Cookies.remove("artfungibleUserloginSuccess");
          // window.location.href = `${config.baseUrl}`;
          this.loginAPI(account[0]);
        }
      }
    }, 2000);

    this.getArtistDetailAction();
    this.headerSettingsForUserAction();

    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
    this.setState({
      lastSegment: lastSegment,
    });
  }

  handleCheckboxChange = () => {
    this.setState({ agreed: !this.state.agreed });
  };

  async onOpenModal() {
    this.setState({
      setOpen: true,
    });
  }
  async onCloseModal() {
    this.setState({
      setOpen: false,
    });
  }

  async connectMetasmask() {
    if (window.ethereum) {
      var isMetaMask = await window.ethereum.isMetaMask;
      if (!isMetaMask) {
        this.setState({
          isPopupError: true,
          msg: "Please install Metamask wallet to use this App!",
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);

        return;
      }
      const providers = await getCurrentProvider();
      const web3 = new Web3(providers);
      const accounts = await providers.request({
        method: "eth_requestAccounts",
      });
      const currentChainId = await web3.eth.net.getId();
      console.log(currentChainId)
      if (currentChainId !== config.chainId) {
        try {
          const data = await web3.currentProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(config.chainId) }],
          });
        } catch (switchError) {
          if (switchError.code === 4902) {
            this.setState({
              isPopupError: true,
              msg: "Please connect with BNB network",
            });
            setTimeout(() => {
              this.setState({
                isPopupError: false,
              });
            }, 2000);
            return false;
          }
        }
      }

      this.loginAPI(accounts[0]);
    } else {
      this.setState({
        isPopupError: true,
        msg: "Please install MetaMask to use this dApp!",
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
    }
  }

  logout() {
    Cookies.remove("artfungibleUserloginSuccess");
    setTimeout(() => {
      window.location.href = `${config.baseUrl}`;
    });
  }

  async loginAPI(account) {
    await axios({
      method: "post",
      url: `${config.apiUrl}addressCheck`,
      data: { walletAddress: account },
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          accountStore: account,
        });
        this.loginAPIHit(account);
        // this.setState({
        //   setOpen: true,
        // });
      } else {
        this.loginAPIHit(account);
      }
    });
  }

  async loginAPIHit(account) {
    axios({
      method: "post",
      url: `${config.apiUrl}login`,
      data: { walletAddress: account },
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          isPopupSuccess: true,
          msg: response.data.msg,
        });
        setTimeout(() => {
          this.setState({
            isPopupSuccess: false,
          });
        }, 2000);

        Cookies.set("artfungibleUserloginSuccess", response.data);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  }

  loginCheck() {
    if (this.loginData.length === 0) {
      this.setState({
        isPopupError: true,
        msg: "Connect Wallet First",
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
    } else {
      window.location.href = `${config.baseUrl}member`;
    }
  }

  async getArtistDetailAction() {
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}getUserArtistDetail`,
      }).then((response) => {
        this.setState({
          artistList: response.data.data.filter(
            (item) => item.isFeatured === 1
          ),
        });
      });
    } catch (err) {}
  }

  async headerSettingsForUserAction() {
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}headerSettingsForUser`,
      }).then((response) => {
        this.setState({
          headerSettingsForUserData: response.data.response.filter(
            (item) => item.isActive === 1
          ),
        });
      });
    } catch (err) {}
  }

  render() {
    return (
      <>
        <Popup
          isPopupError={this.state.isPopupError}
          isPopupSuccess={this.state.isPopupSuccess}
          msg={this.state.msg}
        />
        {/* <Modalone /> */}

        <header className="">
          <div className="container-fluid px-lg-5">
            <div className="row d-lg-block d-none">
              <div className="col-12 text-center mb-5">
                <a className="navbar-brand mx-auto" href={`${config.baseUrl}`}>
                  <img
                    src="mercafor_dashboard/assets/img/af-logo.svg"
                    style={{ width: "25%" }}
                    className="navbar-logo"
                    alt="logo"
                  />
                  <span className="ml-3">Art Fungible</span>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <a
                    className="navbar-brand d-lg-none d-block"
                    href={`${config.baseUrl}`}
                  >
                    <img
                      src="mercafor_dashboard/assets/img/af-logo.png"
                      className="navbar-logo"
                      alt="logo"
                    />
                    <span className="ml-3">Art Fungible</span>
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="menu_icon">
                      <i className="fas fa-bars"></i>
                    </span>
                  </button>

                  {/* <a className="navbar-brand" href="#" >
                  <img
                      src="mercafor_dashboard/assets/img/af-logo.png"
                      style={{ width: "25%" }}
                      className="navbar-logo"
                      alt="logo"
                    />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button> */}

                  <div
                    className="collapse navbar-collapse main-menu-item"
                    id="navbarSupportedContent"
                  >
                    <div className="innernav">
                      <ul className="navbar-nav">
                        <li
                          className={`nav-item ${
                            this.state.lastSegment === "member" ? "active" : ""
                          } `}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            className="nav-link pl-lg-0"
                            onClick={this.loginCheck.bind(this)}
                          >
                            Members
                          </span>
                        </li>
                        <li className="nav-item ">
                          <div className="dropdown navdrop">
                            <button className="dropbtn">
                              Artists
                              <span>
                                <img
                                  src="mercafor_dashboard/assets/img/downarrow.svg"
                                  style={{ width: "12px" }}
                                  className=""
                                  alt="downarrow"
                                />
                              </span>
                            </button>
                            <div className="dropdown-content">
                              {this.state.artistList.map((item) => (
                                <a
                                  key={item.id}
                                  href={
                                    `${config.baseUrl}collection/` + item.id
                                  }
                                >
                                  {item.name}
                                </a>
                              ))}
                            </div>
                          </div>
                        </li>
                        <li
                          className={`nav-item ${
                            this.state.lastSegment === "marketplaceNew"
                              ? "active"
                              : ""
                          } `}
                        >
                          <a
                            className="nav-link"
                            href={`${config.baseUrl}marketplaceNew`}
                          >
                            Marketplace
                          </a>
                        </li>
                        {this.state.headerSettingsForUserData.map((item) => (
                          <li className="nav-item" key={item.id}>
                            <a
                              className="nav-link"
                              href={item.url}
                              target="_blank"
                            >
                              {item.sections}
                            </a>
                          </li>
                        ))}
                      </ul>

                      <div
                        className=" pt-lg-0 pt-3 logoutbtn"
                        // style={{ paddingLeft: "230px" }}
                      >
                        {this.loginData.length === 0 ? (
                          <span
                            className="custombtn cursor"
                            // data-toggle="modal"
                            // data-target="#exampleModal"
                            onClick={(e) => this.connectMetasmask()}
                          >
                            Connect Wallet
                          </span>
                        ) : (
                          <>
                            <span className="custombtn cursor">
                              {this.state.ConnectWalletAddress?.substring(
                                0,
                                6
                              ) +
                                "...." +
                                this.state.ConnectWalletAddress?.substring(
                                  this.state.ConnectWalletAddress?.length - 6
                                )}
                            </span>
                            <div className="fprofilemenu">
                              <ul className="navbar-nav">
                                <li
                                  className={`nav-item ${
                                    this.state.lastSegment ===
                                    this.loginData?.data?.walletAddress
                                      ? "active"
                                      : ""
                                  } `}
                                >
                                  <a
                                    className="nav-link"
                                    href={`${config.baseUrl}membership/${this.loginData?.data?.walletAddress}`}
                                  >
                                    Profile
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <span
                                    className="nav-link cursor"
                                    onClick={this.logout}
                                  >
                                    Logout
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <Modal
          open={this.state.setOpen}
          onClose={this.onCloseModal.bind(this)}
          center
        >
          <div className="modal-header mt-4">
            <h5 className="modal-title" id="exampleModalLabel">
              By continuing you agree to our Terms & Conditions.
            </h5>
          </div>

          <div className="modal-body">
            <div className="genesischeck">
              <input
                className="styled-checkbox"
                id="styled-checkbox-1"
                type="checkbox"
                value={this.state.agreed}
                onChange={this.handleCheckboxChange}
              />
              {/* <label htmlFor="styled-checkbox-1">I agree with <span>Terms and Conditions</span> and   <span> Privacy Policy </span></label> */}
              <label htmlFor="styled-checkbox-1">
                Agree Terms & Conditions &nbsp; (
                <a href="https://terms.artfungible.io/" target="_blank">
                  Click Here
                </a>{" "}
                &nbsp;to read)
              </label>
            </div>
          </div>

          <div className="modal-footer border-0 footerdiv">
            <button
              type="submit"
              disabled={!this.state.agreed}
              onClick={this.loginAPIHit.bind(this, this.state.accountStore)}
              className="btn btn-buy-primary px-5"
            >
              Login{" "}
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
