import React, { Component } from 'react';
import Header from './directives/header1'
import Footer1 from './directives/footer1'
import axios from 'axios';
import config from './config/config'
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
const headers = {
  'Content-Type': 'application/json'
};
const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

export default class resetpassword extends Component {

    constructor(props) {
        super(props);
        this.state={
          password : '',
          password2 : '',
          passwordMsg: '',
          password2Valid: false
      };
      const { match: { params } } = this.props;
    this.token = params.token 
    }

    componentDidMount() {

    }

    
  
    handleSubmit = event =>{
      event.preventDefault();
      const { password,password2 } = this.state;
      const data = this.state 

      axios.post(`${config.apiUrl}/resetpassword`, {token:this.token,password:password,password2:password2}, { headers })
          .then(result=>{
          // alert(' ALL field');
          if(result.data.success === true ){
              toast.success(result.data.msg, {
                  position: toast.POSITION.TOP_CENTER
                  });
          setTimeout(() => {
          
         window.location.href = `${config.baseUrl}login`
          }, 2000);
          }
          else if (result.data.success === false) {
          toast.error(result.data.msg, {
          position: toast.POSITION.TOP_CENTER
          });
          
        
          }
          }).catch(err=>{
          toast.error(err.response.data?.msg, {
          position: toast.POSITION.TOP_CENTER
          })
          })
          }
        
    handleChange = e =>{
      this.setState({
          [e.target.name] : e.target.value
       })

       if (e.target.name === 'password') {

        if (strongRegex.test(e.target.value)) {
            this.setState({
                passwordMsg: ''
            })

        } else if (mediumRegex.test(e.target.value)) {
        }
        else if (e.target.value === '') {
            this.setState({
                passwordMsg: 'Password is required'
            })

        }
        else {
            console.log(e.target.value);
            this.setState({
                passwordMsg: 'Password length must contain 8 and at least 1 lowercase, 1 special character , 1 uppercase, 1 Digit'
            })
        }
    }
    else if (e.target.name === 'password2') {
        if (this.state.password !== e.target.value) {
            this.setState({
                password2Valid: true
            })
        }
        else {
            this.setState({
                password2Valid: false
            })
        }
    }
    }   
   
    render() {
        // const { email,password } = this.state;
        return (    

            <>
              <div class="body_bg">
              <section className="product_section pt-5 pb-5 mb-4">
              <Header/>
               <ToastContainer/>
<div className="container">
 <div className="row  ">
    {/* <h2>All Products</h2>  */}
  

      <div className="col-sm-4">
        
      </div>
      <div className="col-sm-4 mt-5">
          
        <div className="login-wrap mt-5 py-4">
            <div>
                
        <h3 className="text-center tb-0" >Reset Password</h3>
        </div>
         {/* <div className="img d-flex align-items-center justify-content-center"  ><img src="http://13.126.99.244/mercafor_crypto/img/mainlogo.png"/></div> */}
         
         
         
         <form className="login-form" onSubmit={this.handleSubmit}>
         {/* <ToastContainer/> */}
         {/* <div className="form-group">
         <div className="icon d-flex align-items-center justify-content-center"></div>
         <input type="password" className="form-control" placeholder="Current Password"     />
         </div> */}
         <div className="form-group">
         <div className="icon d-flex align-items-center justify-content-center"></div>
         <input type="password" id="password" name="password" className="form-control authCss" placeholder="New Password" onChange={this.handleChange} value={this.state.password}    />
         <div style={{ color: 'red' }}>{this.state.passwordMsg ? this.state.passwordMsg : ''}</div>
         </div>
         <div className="form-group">
         <div className="icon d-flex align-items-center justify-content-center"></div>
         <input type="password" id="password" name="password2" className="form-control authCss" placeholder="Re-type New Password" onChange={this.handleChange} value={this.state.password2}    />
         <div style={{ color: 'red' }}>{this.state.password2Valid === true ? 'Password not match' : ''}</div>
         </div>
         
         <div className="form-group">
         {!this.state.password ?
         <button type="submit"  className="btn form-control btn-primary rounded submit px-3" disabled>Update Password</button>
         :<button type="submit"  className="btn form-control btn-primary rounded submit px-3" disabled={ !this.state.password || !this.state.password2 || this.state.passwordMsg || this.state.password2Valid}>Update Password</button>}
         </div>
         </form>
         <div className="w-100 text-center mt-4 text">
       
         <a href={`${config.baseUrl}login`}>Let's go to Sign In</a>
         </div>
         </div>
      </div>
      <div className="col-sm-4">
        
      </div>
     
     
    </div>
   
 </div>
 


</section>
</div>
              <Footer1/>
            </>
        )
    }
}
