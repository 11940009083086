import React, { Component } from 'react';
import Header from './directives/header1'
import Footer1 from './directives/footer1'
import axios from 'axios';
import config from './config/config'
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
// const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";  // for local server
//  const TEST_SITE_KEY = "6LcZhLUbAAAAAMYyWD96wh1lyP8OKeuKOsFRm33W" // for ESP server
//  const TEST_SITE_KEY = "6LdQ8Q8cAAAAAA6VNcDP4fD7YawEYqz48lgdOKjH"   // for live server
//  const TEST_SITE_KEY = "6LdWKYEeAAAAAIGrwdzXvpPCYvAuJot8kpzJHVBY"   // for Org server
const TEST_SITE_KEY = "6LfAQCEfAAAAABEf75JV2CDkIYfl5GzsQ99O-dp6"   // for Client server

 

const headers = {
    'Content-Type': 'application/json'
};

export default class login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: "",
            captcha_code: '',
            failError: '',
            eyelogin: true,
            loading: false
        };
    }


    handleChange1 = value => {
        console.log("Captcha value:", value);
        this.setState({
            captcha_code: '1'
        })
    };


    componentDidMount() {

    }

    handleSubmit = event => {
        event.preventDefault();
        if (this.state.captcha_code === '' && this.state.email !== '' && this.state.password !== '') {
            alert('Please check the captcha form. ')
        }
        else {
            const { email, password } = this.state;
            const data = this.state
            this.setState({
                loading: true
            })
            axios.post(`${config.apiUrl}/login`, data, { headers })
                .then(result => {
                    // alert(' ALL field');
                    if (result.data.success === true) {
                        // toast.success('Login Successfully!', {
                        // position: toast.POSITION.TOP_CENTER
                        // });
                        setTimeout(() => {
                            var productId = (!Cookies.get('productDetailsId')) ? '' : Cookies.get('productDetailsId')
                            if (productId) {
                                Cookies.remove('productDetailsId');
                                window.location.href = `${config.baseUrl}marketplaceDetail/` + productId
                            } else {
                                window.location.href = `${config.baseUrl}userprofile`
                            }

                        }, 2500);

                        Cookies.set('artfungibleUserloginSuccess', result.data);
                        // window.location.href = `${config.baseUrl}`
                    }
                    else if (result.data.success === false) {
                        // toast.error(result.data.msg, {
                        // position: toast.POSITION.TOP_CENTER
                        // });

                        this.setState({
                            email: '',
                            password: '',
                            failError: result.data.msg,
                            loading: false
                        })
                    }
                }).catch(err => {
                    this.setState({
                        failError: err.response?.data?.msg,
                        loading: false
                    })
                    // toast.error(err.response?.data?.msg, {
                    // position: toast.POSITION.TOP_CENTER
                    // })
                })
        }

    }

    handleChange = e => {
        console.log(e);
        this.setState({
            [e.target.name]: e.target.value,
            failError: ''
        })
    }

    eyeClick(id) {
        if (id === 1) {
            this.setState({
                eyelogin: false
            })
        }
        else if (id === 2) {
            this.setState({
                eyelogin: true
            })
        }

    }

    render() {
        const { email, password } = this.state;
        return (

            <>
                <div className="body_bg">
                    <Header />
                    <section className="product_section pt-5 pb-5 mb-4">

                        <div className="container">
                            <div className="row  ">
                                {/* <h2>All Products</h2>  */}


                                <div className="col-sm-4">

                                </div>
                                <div className="col-sm-4 mt-5">
                                    <div className="login-wrap mt-5 py-4">
                                        <div className="img d-flex align-items-center justify-content-center"  ><img src="mercafor_dashboard/assets/img/af-logo.png" alt="logo" className="" /></div>
                                        <h3 className="text-center mb-0">Welcome</h3>
                                        <p className="text-center">Sign in by entering the information below</p>
                                        <form action="#" className="login-form">
                                            <ToastContainer />
                                            <div className="form-group">
                                                <div className="icon d-flex align-items-center justify-content-center"></div>
                                                <input type="text" className="form-control authCss" placeholder="Email" name="email" onChange={this.handleChange} value={email} />
                                            </div>
                                            <div className="form-group">
                                                <div className="icon d-flex align-items-center justify-content-center"></div>
                                                <input type={this.state.eyelogin === true ? "password" : 'text'} className="form-control authCss" placeholder="Password" name="password" onChange={this.handleChange} value={password} />
                                                {this.state.eyelogin === true ?
                                                    <i className="eyelogin far fa-eye" onClick={this.eyeClick.bind(this, 1)}></i> :
                                                    <i className="eyelogin fas fa-eye-slash" onClick={this.eyeClick.bind(this, 2)}></i>
                                                }
                                            </div>
                                            <div className="form-group d-md-flex">
                                                <div className="w-100 ">
                                                    <a className="errorMsg11">{this.state.failError}</a>

                                                    <a style={{ float: 'right' }} href={`${config.baseUrl}generatepassword`}>Forgot Password</a>
                                                </div>
                                            </div>
                                            <ReCAPTCHA
                                                style={{ marginLeft:'20px'}}
                                                theme="dark"
                                                ref={this._reCaptchaRef}
                                                sitekey={TEST_SITE_KEY}
                                                onChange={this.handleChange1}
                                                asyncScriptOnLoad={this.asyncScriptOnLoad}
                                            />

                                            <div className="form-group">

                                                <button type="submit" onClick={this.handleSubmit} disabled={!this.state.email || !this.state.password || this.state.captcha_code === ''} className="btn form-control btn-primary rounded submit px-3">{this.state.loading === false ? 'Sign in' : 'Loading...'}</button>
                                            </div>
                                        </form>
                                        <div className="w-100 text-center mt-4 text">
                                            <p className="mb-0">Don't have an account?</p>
                                            <a href={`${config.baseUrl}register`}>Sign Up</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">

                                </div>


                            </div>

                        </div>


                    </section>
                </div>




                <Footer1 />
            </>
        )
    }

}